import React from 'react'
import PropTypes from 'prop-types'

import { Modal, Fade, Backdrop } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { memo } from 'utils/react'

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    outline: 'none',
  },
}))

const BasicModal = ({ isOpen, onClose, children = {}, className = '', mainClassName = '' }) => {
  const classes = useStyles()

  return (
    <Modal
      className={`flex justify-center items-center ${mainClassName}`}
      open={isOpen}
      onClose={onClose}
      disableBackdropClick
      disableEscapeKeyDown
      BackdropComponent={Backdrop}
    >
      <Fade in={isOpen}>
        <div className={`${classes.paper} ${className}`}>{children}</div>
      </Fade>
    </Modal>
  )
}

BasicModal.propTypes = {
  children: PropTypes.object,
  className: PropTypes.string,
  mainClassName: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default memo(BasicModal)
