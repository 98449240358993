import React from 'react'
import moment from 'moment'

import { shallowEqual, useSelector } from 'react-redux'

import { Paper, Typography } from '@material-ui/core'

import { HomeInformationIcon } from 'components/common/Icons'

import T from 'T'

import { getAmountDue } from 'utils/payment'
import { addDecimal } from 'utils/handleAmount'

import { get } from 'utils/lodash'
import { isDueOnReceiptPaymentTerm } from 'utils/billing'

const BillingSection = () => {
  const { homePagePayments } = useSelector(
    state => ({
      homePagePayments: state.PaymentsReducer.homePagePayments,
    }),
    shallowEqual
  )
  const hasAutoPay = get(homePagePayments, 'autopayInfo.autopay', false)
  const paymentTerm = get(homePagePayments, 'paymentTerm')
  const dueDate = get(homePagePayments, 'dueDate')
  const isDueOnReceipt = isDueOnReceiptPaymentTerm(paymentTerm)
  const unpaidInvoices = get(homePagePayments, 'unpaidInvoices', [])
  const hasUnpaidInvoices = unpaidInvoices.length !== 0
  const amountDue = getAmountDue(unpaidInvoices)
  const formattedDueDate = dueDate ? moment(dueDate).format('MMM DD, YYYY') : ''

  const getPaymentDueMessage = () => {
    if (hasAutoPay) {
      /* Don't show if autopay and payment term is due on receipt */
      return dueDate && !isDueOnReceipt ? `${T.NEXT_PAYMENT_MESSAGE_AUTO} ${formattedDueDate}` : ''
    }
    if (hasUnpaidInvoices && amountDue > 0) {
      return 'You have at least one unpaid invoice. Please make payment.'
    }
    if (isDueOnReceipt) {
      return `${T.NEXT_PAYMENT_MESSAGE} receipt`
    }
    if (dueDate) {
      return `${T.NEXT_PAYMENT_MESSAGE} ${formattedDueDate}`
    }

    return ''
  }

  const paymentDueMessage = getPaymentDueMessage()

  return (
    <Paper elevation={2} className="card-section">
      <div className="flex flex-section">
        <Typography variant="h6">{T.BILLING}</Typography>
        <HomeInformationIcon />
      </div>

      <div className="flex flex-section justify-between">
        <Typography variant="h5">
          {T.NEXT_PAYMENT}
          {hasAutoPay && <span> ({T.AUTO})</span>}
        </Typography>
        <Typography variant="h4" className="billing__amount">
          {`$${addDecimal(amountDue)}`}
        </Typography>
      </div>

      {amountDue > 0 && paymentDueMessage && (
        <div className="flex flex-section">
          <Typography variant="subtitle1">{paymentDueMessage}</Typography>
        </div>
      )}
    </Paper>
  )
}

export default BillingSection
