const PRIMARY = {
  light: '#4a9aff',
  main: '#0073e6',
  dark: '#004082',
  contrastText: '#FFFFFF',
}

// Currently we don't have secondary colors, so added the primary
const SECONDARY = {
  main: '#0073e6',
}

const ERROR = {
  light: '#e76464',
  main: '#dc2626',
  dark: '#9b1718',
}

const COMPLETE = {
  light: '#FFFFFF',
  main: '#16A34A',
  dark: '#a0d6a4',
  contrastText: '#FFFFFF',
}

const WARNING = {
  light: '#ffe082',
  main: '#ffa000',
  dark: '#ff8f00',
}

const INFO = {
  light: '#64b5f6',
  main: '#2196f3',
  dark: '#1976d2',
}

const SUCCESS = {
  light: '#3ecf8e',
  main: '#00875a',
  dark: '#005f3f',
}

const TEXT_PRIMARY = {
  main: '#042040',
}

const TEXT_SECONDARY = {
  main: '#7a869a',
}

const TEXT = {
  primary: TEXT_PRIMARY.main,
  secondary: TEXT_SECONDARY.main,
  disabled: '#abbbcc',
  lightBrown: '#4f566b',
  tableTitle: '#6E788A',
}

const BACKGROUND = {
  default: '#fafafa',
  paper: '#ffffff',
  black: '#000000',
  light: '#F3F4F6',
  tags: '#dfe1e6',
  tab: '#eff2f5',
  disabled: '#F9FAFB',
  success: '#DCFCE7',
  driverBg: '#a4c4b0',
  // We can also use shades 200/300/400 etc
  disabledDark: '#F6F7F8',
  accordion: '#F8FAFC',
  appbar: '#0f0f13',
  header: '#EDF3FA',
}

const BORDER = {
  main: '#4A90E2',
  secondary: '#E5E7EB',
  light: '#dfe1e6',
  lightGrey: '#ccc',
  grey: '#e3e8ee',
  lightBrown: '#7A869A',
  driverBorder: '#ca1111',
  activeBorder: '#4a90e2',
}

const GREY = {
  200: '#E5E7EB',
  300: '#D1D5DB',
}

const DIVIDER = '#e0e0e0'

const COMMON = {
  white: '#ffffff',
  black: '#000000',
}

export {
  PRIMARY,
  SECONDARY,
  ERROR,
  WARNING,
  INFO,
  SUCCESS,
  TEXT,
  TEXT_PRIMARY,
  TEXT_SECONDARY,
  BACKGROUND,
  DIVIDER,
  BORDER,
  GREY,
  COMPLETE,
  COMMON,
}
