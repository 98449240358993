import { CARD_IMAGES, ACH_IMAGE, PAYMENT_METHODS, ACH_ACCOUNT_TYPE } from 'settings/constants/payment'
import { get } from './lodash'
import { calculateSum, formatCentsToDollars } from './price'
import { insertSpaceBeforeCapitalLetter } from './string'

const { CREDIT_CARD, ACH } = PAYMENT_METHODS

export const isValidAccountType = accountType => ACH_ACCOUNT_TYPE.includes(accountType)

export const getPaymentMethodName = (creditCardType, accountType) => {
  if (creditCardType) {
    return CREDIT_CARD
  }

  if (isValidAccountType(accountType)) {
    return ACH
  }

  return ''
}

export const getPaymentMethodImage = type => {
  if (type === ACH) {
    return ACH_IMAGE
  }

  return CARD_IMAGES[type] || CARD_IMAGES.Unknown
}

export const getAmountDue = unpaidInvoices => {
  try {
    if (!Array.isArray(unpaidInvoices)) {
      return 0
    }

    const total = calculateSum(unpaidInvoices.map(data => get(data, 'totalCents', 0) - get(data, 'amountPaidCents', 0)))
    return formatCentsToDollars(total)
  } catch (error) {
    return 0
  }
}

export const getInvoicesThatCanBePaid = (allInvoices, initialCreditBalanceCents, addedAmount) => {
  let selectedInvoices = []

  if (!Array.isArray(allInvoices)) {
    return selectedInvoices
  }

  let totalAddedAmount = (initialCreditBalanceCents || 0) + (addedAmount || 0)

  for (let i = 0; i < allInvoices.length; ++i) {
    const { invoiceNumber, totalCents, amountPaidCents } = allInvoices[i]
    const invoiceAmount = totalCents - amountPaidCents

    if (totalAddedAmount <= 0) {
      break
    }

    selectedInvoices = [...selectedInvoices, invoiceNumber]
    totalAddedAmount -= invoiceAmount
  }

  return selectedInvoices
}

export const getPaymentMethod = paymentMethod =>
  insertSpaceBeforeCapitalLetter(Array.isArray(paymentMethod) ? paymentMethod.join(',') : paymentMethod)
