import moment from 'moment'

const MORNING_END_TIME = 12
const AFTERNOON_END_TIME = 17

const fetchGreeting = () => {
  const NOW = moment()
  let greetTime = 'morning'
  const nowHour = parseInt(NOW.format('HH'))

  if (nowHour >= MORNING_END_TIME && nowHour < AFTERNOON_END_TIME) {
    greetTime = 'afternoon'
  } else if (nowHour >= AFTERNOON_END_TIME && nowHour !== 0) {
    greetTime = 'evening'
  }

  return greetTime
}

export default fetchGreeting
