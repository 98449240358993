import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Constants from 'Constants'

const { PUBLIC_ASSETS_BUCKET_NAME, STORAGE_PATH } = Constants
const BASE_PATH = `${STORAGE_PATH}${PUBLIC_ASSETS_BUCKET_NAME}/`

const DEFAULT_LOGO_PATH = 'haulerhero/business_logo.png'

const BusinessLogo = ({ businessId = '', imageSX = {} }) => {
  const [logoPath, setLogoPath] = useState('')

  const validateImage = (url, callback) => {
    const img = new Image()
    img.src = url

    if (img.complete) {
      callback(true)
    }

    img.onload = () => {
      callback(true)
    }

    img.onerror = () => {
      callback(false)
    }
  }

  useEffect(() => {
    if (!businessId) {
      setLogoPath(DEFAULT_LOGO_PATH)
      return
    }

    const clientLogo = `${businessId}/business_logo.png`
    validateImage(`${BASE_PATH}${clientLogo}`, exists => setLogoPath(exists ? clientLogo : DEFAULT_LOGO_PATH))
  }, [businessId])

  return logoPath ? <img {...imageSX} src={`${BASE_PATH}${logoPath}`} alt="" /> : null
}

BusinessLogo.propTypes = {
  businessId: PropTypes.string,
  imageSX: PropTypes.object,
}

export default BusinessLogo
