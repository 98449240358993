export const actions = {
  GET: {
    CHANGE_PASSWORD: 'GET_CHANGE_PASSWORD',
    PASSWORD_COMPLEXITY: 'GET_PASSWORD_COMPLEXITY',
  },
  PUT: {
    CHANGE_PASSWORD: 'PUT_CHANGE_PASSWORD',
    PASSWORD_COMPLEXITY: 'PUT_PASSWORD_COMPLEXITY',
  },
}

export const putChangePassword = res => ({
  type: actions.PUT.CHANGE_PASSWORD,
  payload: res,
})

export const putPasswordComplexity = res => ({
  type: actions.PUT.PASSWORD_COMPLEXITY,
  payload: res,
})

export const getChangePassword = body => ({
  type: actions.GET.CHANGE_PASSWORD,
  payload: { body },
})

export const getPasswordComplexity = () => ({
  type: actions.GET.PASSWORD_COMPLEXITY,
})
