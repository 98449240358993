import { isRejectedWithValue } from '@reduxjs/toolkit'
import { handleFirebaseLogout } from 'providers/firebaseAuth'
import { ERROR_EXCEPTIONS, ERROR_MESSAGES } from 'settings/error'
import { STATUS_CODE } from 'settings/status'
import { get } from 'utils/lodash'

const { UNAUTHORIZED, BAD_REQUEST } = STATUS_CODE
const { INVALID_USER_EXCEPTION, INVALID_ACCOUNT_EXCEPTION } = ERROR_EXCEPTIONS
const { INVALID_ACCOUNT_MESSAGE } = ERROR_MESSAGES

export const rtkQueryErrorLogger = () => next => action => {
  if (isRejectedWithValue(action)) {
    const statusCode = action?.payload?.status
    const exceptionType = get(action, 'payload.data.exceptionType')
    const errorMsg = get(action, 'payload.data.message')

    if (statusCode === UNAUTHORIZED) {
      handleFirebaseLogout()
    }

    if (
      [INVALID_USER_EXCEPTION, INVALID_ACCOUNT_EXCEPTION].includes(exceptionType) ||
      (statusCode === BAD_REQUEST && errorMsg === INVALID_ACCOUNT_MESSAGE)
    ) {
      handleFirebaseLogout()
    }
  }

  return next(action)
}
