import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Box } from '@mui/material'
import T from 'T'
import { selectAddress } from 'data/address/addressSelectors'
import LabeledItem from './LabeledItem'

const AddressDetails = ({
  address,
  sx,
  spacing = 1,
  line1Label = T.ADDRESS,
  addressNameLabel = T.ADDRESS_NAME,
  showAddressName = false,
}) => {
  const { addressName, line1, unitNumber, city, state, zipCode } = selectAddress(address)
  const line1Str = `${[line1, unitNumber].filter(Boolean).join(', ')}`
  return (
    <Box sx={sx}>
      <Grid container spacing={spacing}>
        {showAddressName && (
          <Grid item xs={12}>
            <LabeledItem primary={addressNameLabel} secondary={addressName || '-'} />
          </Grid>
        )}
        <Grid item xs={12}>
          <LabeledItem secondaryTypographyProps={{ variant: 'h6', fontWeight: 500 }} primary={line1Label} secondary={line1Str || '-'} />
        </Grid>
        <Grid container item spacing={2} wrap="nowrap">
          <Grid item>
            <LabeledItem primary="City" secondary={city || '-'} />
          </Grid>
          <Grid item>
            <LabeledItem primary="State" secondary={state || '-'} />
          </Grid>
          <Grid item>
            <LabeledItem primary="Zip code" secondary={zipCode || '-'} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

AddressDetails.propTypes = {
  address: PropTypes.object.isRequired,
  sx: PropTypes.object,
  line1Label: PropTypes.string,
  addressNameLabel: PropTypes.string,
  showAddressName: PropTypes.bool,
  spacing: PropTypes.number,
}

export default AddressDetails
