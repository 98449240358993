import React from 'react'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import HHTextField from './HHTextField'

const HHPhoneField = ({ value = '', displayType = 'input', defaultValue = '--', formatResult = false, onValueChange = noop, ...rest }) => {
  return displayType === 'text' && !value ? (
    defaultValue
  ) : (
    <NumberFormat
      value={value}
      displayType={displayType}
      placeholder="(201) 555-0123"
      format="(###) ###-####"
      autoComplete="off"
      customInput={HHTextField}
      onValueChange={event => {
        const { value: newValue, formattedValue } = event
        onValueChange(formatResult ? formattedValue : newValue)
      }}
      {...rest}
    />
  )
}

HHPhoneField.propTypes = {
  value: PropTypes.string,
  displayType: PropTypes.string,
  defaultValue: PropTypes.string,
  formatResult: PropTypes.bool,
  onValueChange: PropTypes.func,
}

export default HHPhoneField
