const API_PREFIX = '/api'
const CDN_PREFIX = '/cdn'

const endpoint = {
  user: {
    appSettings: `${API_PREFIX}/app-settings`,
    info: `${API_PREFIX}/user/info`,
    logoUrl: uri => `${CDN_PREFIX}/logo?uri=${uri}`,
    profile: '/cdn/user/pp',
    payments: `${API_PREFIX}/payment`,
    paymentsAutoPayAdd: `${API_PREFIX}/payment/autopay/add`,
    initiatePassword: `${API_PREFIX}/user/password/initiate`,
    changePassword: `${API_PREFIX}/user/password/change`,
    invoice: `${API_PREFIX}/account/invoice`,
    invoiceInitiate: `${API_PREFIX}/account/invoice/initiate/download`,
    invoiceDownloadStatus: `${API_PREFIX}/account/invoice/download/status`,
    invoiceDownload: `${API_PREFIX}/account/invoice/download`,
    configuredServices: `${API_PREFIX}/account/configured-service`,
    paymentSurcharge: `${API_PREFIX}/portal/payment/surcharge`,
  },
  public: {
    signup: `${API_PREFIX}/signup`,
    initiateSignup: `${API_PREFIX}/initiate-complete-signup`,
    completeSignup: `${API_PREFIX}/complete-signup`,
    forgotPassword: `${API_PREFIX}/portal/reset-password`,
    initiateCompleteResetPwd: `${API_PREFIX}/initiate-complete-reset-password`,
    passwordReset: `${API_PREFIX}/complete-reset-password`,
    passwordResetSendEmail: `${API_PREFIX}/reset-password-send-email`,
  },
}

export default endpoint
