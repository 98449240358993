import { takeEvery, all, call, put } from 'redux-saga/effects'

import { doPost } from 'providers/api'

import { actions, putConfiguredServices } from '../actions/configuredServices'

export const getConfiguredServicesAPI = payload =>
  doPost({ url: `/api/v1/tenants/${payload.businessId}/accounts/${payload.accountId}/configured-service`, showLoader: true })

export function* getConfiguredServicesSaga({ payload }) {
  try {
    const { data: res } = yield call(getConfiguredServicesAPI, payload)
    yield put(putConfiguredServices(res))
  } catch (err) {
    yield put(putConfiguredServices([]))
  }
}

export default function* ConfiguedServices() {
  yield all([takeEvery(actions.GET.CONFIGURED_SERVICES, getConfiguredServicesSaga)])
}
