export const actions = {
  GET: {
    CONFIGURED_SERVICES: 'GET_CONFIGURED_SERVICES',
  },
  PUT: {
    CONFIGURED_SERVICES: 'PUT_CONFIGURED_SERVICES',
  },
}

export const putConfiguredServices = res => ({
  type: actions.PUT.CONFIGURED_SERVICES,
  payload: res,
})

export const getConfiguredServices = payload => ({
  type: actions.GET.CONFIGURED_SERVICES,
  payload,
})
