export const actions = {
  GET: {
    INVOICES_LIST: 'GET_INVOICES_LIST',
    INVOICE_INITIATE_DOWNLOAD: 'GET_INVOICE_INITIATE_DOWNLOAD',
    INVOICE_DOWNLOAD_STATUS: 'GET_INVOICE_DOWNLOAD_STATUS',
    INVOICE_DOWNLOAD: 'GET_INVOICE_DOWNLOAD',
    INVOICE_RESET: 'INVOICE_RESET',
  },
  PUT: {
    INVOICES_LIST: 'PUT_INVOICES_LIST',
    INVOICE_INITIATE_DOWNLOAD: 'PUT_INVOICE_INITIATE_DOWNLOAD',
    INVOICE_DOWNLOAD_STATUS: 'PUT_INVOICE_DOWNLOAD_STATUS',
    INVOICE_DOWNLOAD: 'PUT_INVOICE_DOWNLOAD',
    INVOICE_RESET: 'INVOICE_RESET',
  },
}

export const putInvoicesReset = () => ({
  type: actions.PUT.INVOICE_RESET,
  payload: null,
})

export const putInvoicesList = res => ({
  type: actions.PUT.INVOICES_LIST,
  payload: res,
})

export const putInvoiceInitiateDownload = res => ({
  type: actions.PUT.INVOICE_INITIATE_DOWNLOAD,
  payload: res,
})

export const putInvoiceDownloadStatus = res => ({
  type: actions.PUT.INVOICE_DOWNLOAD_STATUS,
  payload: res,
})

export const putInvoiceDownload = res => ({
  type: actions.PUT.INVOICE_DOWNLOAD,
  payload: res,
})

export const getInvoicesList = (businessId, accountId, body) => ({
  type: actions.GET.INVOICES_LIST,
  payload: { businessId, accountId, body },
})

export const getInvoiceInitiateDownload = (token, body) => ({
  type: actions.GET.INVOICE_INITIATE_DOWNLOAD,
  payload: { token, body },
})

export const getInvoiceDownloadStatus = (token, body) => ({
  type: actions.GET.INVOICE_DOWNLOAD_STATUS,
  payload: { token, body },
})

export const getInvoiceDownload = (token, body) => ({
  type: actions.GET.INVOICE_DOWNLOAD,
  payload: { token, body },
})

export const getInvoicesReset = () => ({
  type: actions.GET.INVOICE_RESET,
  payload: {},
})
