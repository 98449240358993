import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Helmet } from 'react-helmet-async'
import { useLocation, useHistory } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { get } from 'utils/lodash'
import { getPageTitle } from 'utils/seo'
import { useAuthValue } from 'auth/AuthContext'
import { getUserInfo } from 'middleware/actions/login'
import { setSelectedLoginDetails } from 'slices/login/LoginPersistSlice'
import { isSingleAccount, getFirstAccountDetails } from 'data/select-account/selectAccount'

import routes, { isPublicURL } from 'router/routes'

import Header from 'components/header'

import 'react-toastify/dist/ReactToastify.css'
import Loader from './loader'

const MainContainer = ({ children }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const [isValidUser, setIsValidUser] = useState(false)
  const { currentUser, isInitialized } = useAuthValue() || {}
  const showAppBar = useSelector(state => get(state, 'Appbar.showAppBar'), shallowEqual)
  const isLoading = useSelector(state => get(state, 'ResponseReducer.isLoading', false), shallowEqual)
  const selectedLoginDetails = useSelector(state => get(state, 'LoginPersist.selectedLoginDetails', {}), shallowEqual)
  const { businessId: existingBusinessId, accountId: existingAccountId, show } = selectedLoginDetails
  const { pathname } = location
  const isPublicPath = isPublicURL(pathname)

  useEffect(() => {
    if (!currentUser || isPublicPath) {
      setIsValidUser(false)
      return
    }

    dispatch(
      getUserInfo(response => {
        setIsValidUser(true)

        if (!response) {
          history.push(routes.app.error)
          return
        }

        const isTenantAndAccountAlreadySelected = existingBusinessId && existingAccountId
        if (isTenantAndAccountAlreadySelected && !show) {
          return
        }

        const accountsPerBusiness = get(response, 'userInfo.accountsPerBusiness', [])
        if (isSingleAccount({ accountsPerBusiness })) {
          const { businessName, businessId, accountId } = getFirstAccountDetails({ accountsPerBusiness })
          dispatch(setSelectedLoginDetails({ businessName, businessId, accountId, show: false, redirectToInvoicePage: false }))
          return
        }
        history.push(routes.app.selectAccount)
      })
    )
  }, [currentUser, isPublicPath])

  if (!isInitialized) {
    return <Loader />
  }

  return (
    <>
      <Helmet>
        <title>{getPageTitle(pathname)}</title>
      </Helmet>

      {isLoading && <Loader />}

      {(!currentUser || isPublicPath) && children}

      {currentUser && isValidUser && !isPublicPath && (
        <>
          {showAppBar && <Header />}
          <div className="main">{children}</div>
        </>
      )}
      <ToastContainer hideProgressBar={true} />
    </>
  )
}

MainContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MainContainer
