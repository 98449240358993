import React, { Children } from 'react'
import PropTypes from 'prop-types'
import { get } from 'utils/lodash'

import { Menu as MaterialMenu } from '@material-ui/core'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Box, Link, MenuItem, SvgIcon, useTheme } from '@mui/material'
import { setSelectedLoginDetails } from 'slices/login/LoginPersistSlice'
import AccountSelectionDropdown from 'containers/select-account/AccountSelectionDropdown'
import './Menu.scss'
import { isSingleAccount, getAllAccounts } from 'data/select-account/selectAccount'

const Menu = ({ id, anchorEl, open, menuitems, onClose, onClick }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const accountId = useSelector(state => get(state, 'LoginPersist.selectedLoginDetails.accountId'), shallowEqual)
  const accountsPerBusiness = useSelector(state => get(state, 'AuthReducer.userInfo.accountsPerBusiness', []), shallowEqual)
  const OnlyOneAccount = isSingleAccount({ accountsPerBusiness })
  const allAccounts = getAllAccounts({ accountsPerBusiness })

  const renderMenuItem = menuItem => (
    <>
      {menuItem?.icon && (
        <SvgIcon sx={{ width: 57, color: theme.palette.action.active }} fontSize="small">
          {menuItem.icon}
        </SvgIcon>
      )}
      <Box className="menu-items-title" width="100%">
        {menuItem.title}
      </Box>
    </>
  )

  const handleAccountChange = event => {
    const newAccountId = event.target.value
    const currentAccount = allAccounts.find(account => account.id === newAccountId)
    dispatch(
      setSelectedLoginDetails({
        show: false,
        businessName: get(currentAccount, 'businessName'),
        businessId: get(currentAccount, 'businessId'),
        accountId: newAccountId,
        redirectToInvoicePage: false,
      })
    )
  }

  return (
    <MaterialMenu disableEnforceFocus id={id} anchorEl={anchorEl} keepMounted open={open} onClose={onClose}>
      <AccountSelectionDropdown
        readOnly={OnlyOneAccount}
        value={accountId}
        onChange={handleAccountChange}
        sx={{ pointerEvents: OnlyOneAccount ? 'none' : 'inherit', '& fieldset': { border: 'none' } }}
        {...(OnlyOneAccount && { inputProps: { IconComponent: () => null } })}
      />
      {Children.toArray(
        menuitems.map(menuItem =>
          menuItem?.type === 'link' ? (
            <MenuItem className="menu-items" sx={{ p: 0 }}>
              <Link
                sx={{ width: '100%', textDecoration: 'none', color: 'inherit', display: 'flex', py: 0.75, px: 2 }}
                href={menuItem.url}
                target="_blank"
                rel="noopener"
                onClick={onClose}
              >
                {renderMenuItem(menuItem)}
              </Link>
            </MenuItem>
          ) : (
            <MenuItem onClick={event => onClick(event, get(menuItem, 'route', ''))} className="menu-items">
              {renderMenuItem(menuItem)}
            </MenuItem>
          )
        )
      )}
    </MaterialMenu>
  )
}

Menu.defaultProps = {
  id: '',
  anchorEl: {},
  open: false,
  menuitems: [],
}

Menu.propTypes = {
  id: PropTypes.string,
  anchorEl: PropTypes.object,
  open: PropTypes.bool,
  menuitems: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default Menu
