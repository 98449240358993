import React, { useEffect, useRef, useState, useReducer, useMemo, useCallback } from 'react'

import moment from 'moment'
import lowerCase from 'lodash/lowerCase'

import { Grid, MenuItem, Select } from '@material-ui/core'
import { addDays } from 'date-fns'

import { DateRangePicker } from 'react-date-range'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { getInvoicesList } from 'middleware/actions/invoice'
import { addDecimal } from 'utils/handleAmount'
import { ArrowNRightIcon } from 'components/common/Icons'
import { INITIAL_PAGE } from 'utils/pagination'
import Loader from 'components/common/loader'
import Table from 'components/table'

import CommonTableFilters from 'components/common/CommonTableFilters'
import Constants from 'Constants'
import { downloadInvoiceBlob } from 'utils/file'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import 'components/common/date-range-picker.scss'

import { TEXT_FILTERS, NUMBER_FILTERS, DATE_FILTERS, CHECKBOX_FILTERS } from 'settings/constants/filters'

import './invoices.scss'

import { get } from 'utils/lodash'
import { formatCentsToDollars } from 'utils/price'

const { MEDIA_SERVER } = Constants

const invoicesFilter = [
  {
    key: 'all',
    display: 'All Invoices',
  },
  {
    key: 'Unpaid',
    display: 'Unpaid',
  },
  {
    key: 'Paid',
    display: 'Paid',
  },
]

const monthFilter = [
  {
    key: '-1',
    display: 'All time',
  },
  {
    key: '12',
    display: 'Last 12 months',
  },
  {
    key: '3',
    display: 'Last Quarter',
  },
  {
    key: '1',
    display: 'Last Month',
  },
  {
    key: '7',
    display: 'Last Week',
  },
  {
    key: 'custom',
    display: 'Custom',
  },
]

const Invoices = () => {
  const dispatch = useDispatch()
  const wrapperRef = useRef(null)

  const { invoices, userInfo } = useSelector(
    state => ({
      invoices: state.InvoiceReducer.invoices,
      userInfo: state.AuthReducer.userInfo,
    }),
    shallowEqual
  )

  const businessId = useSelector(state => get(state, 'LoginPersist.selectedLoginDetails.businessId'), shallowEqual)
  const accountId = useSelector(state => get(state, 'LoginPersist.selectedLoginDetails.accountId'), shallowEqual)

  const [sortAsc, setSortAsc] = useState(false)
  const [rowHover, setRowHover] = useState(-1)
  const [invoiceType, setInvoiceType] = useState('all')
  const [selectedMonths, setSelectedMonths] = useState('-1')
  const isAllTimeSelected = useMemo(() => selectedMonths === '-1', [selectedMonths])
  const [showDateRange, setShowDateRange] = useState(false)
  const [rangeSelection, setRangeSelection] = useState([
    {
      startDate: addDays(new Date(), -365),
      endDate: new Date(),
      key: 'selection',
    },
  ])
  const [customStartDate, setCustomStartDate] = useState(moment(rangeSelection[0].startDate).format('MMM DD, YYYY'))
  const [customEndDate, setCustomEndDate] = useState(moment(rangeSelection[0].endDate).format('MMM DD, YYYY'))
  const [rowsPerPage] = useState(50)
  const [pageNum] = useState(INITIAL_PAGE)
  const [payload, setPayload] = useState({
    userId: userInfo?.userId,
    requestedPage: parseInt(pageNum),
    requestedPageSize: parseInt(rowsPerPage),
    startDate: isAllTimeSelected ? undefined : moment(customStartDate).format('YYYY-MM-DD'),
    endDate: isAllTimeSelected ? undefined : moment(customEndDate).format('YYYY-MM-DD'),
    sortBy: 'createdAt',
    sortOrder: sortAsc ? 'asc' : 'desc',
  })

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    allSortBy: {},
    allFilters: {},
    isOpenTableFilters: null,
    activeFilter: '',
    isLoading: false,
  })

  const { allSortBy, allFilters, isOpenTableFilters, activeFilter, isLoading } = localState

  useEffect(() => {
    if (businessId && accountId) {
      dispatch(getInvoicesList(businessId, accountId, payload))
    }
  }, [businessId, accountId, payload])

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowDateRange(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  const toggleDateRangeSelector = useCallback(() => {
    if (isAllTimeSelected) return
    setShowDateRange(!showDateRange)
  }, [isAllTimeSelected, showDateRange])

  const getStatusColor = status => {
    switch (status) {
      case 'paid':
        return 'green-btn'

      case 'unpaid':
        return 'yellow-btn'

      case 'pending':
        return 'gray-btn'

      case 'overdue':
        return 'warning-btn'

      default:
        return 'gray-btn'
    }
  }

  const headings = [
    {
      id: 'createdAt',
      filter: false,
      sort: true,
      title: 'Date',
      filterType: 'date',
      subType: 'date',
    },
    {
      id: 'invoiceNumber',
      filter: false,
      sort: true,
      title: 'Invoice #',
      filterType: 'number',
    },
    {
      id: 'dueDate',
      filter: false,
      sort: true,
      title: 'Due Date',
      filterType: 'date',
      subType: 'date',
    },
    {
      id: 'numberOfServices',
      filter: false,
      sort: true,
      title: '# of Services',
      filterType: 'number',
    },
    {
      id: 'status',
      filter: false,
      sort: true,
      title: 'Status',
      subType: 'status',
    },
    {
      id: 'amountPaid',
      filter: false,
      sort: false,
      title: 'Amount paid',
      filterType: 'number',
      subType: 'price',
    },
    {
      id: 'total',
      filter: false,
      sort: true,
      title: 'Amount',
      filterType: 'number',
      subType: 'price',
    },
    {
      id: 'download-icon',
      filter: false,
      sort: false,
      title: '',
    },
  ]

  const handlesSorting = (columnName, type) => {
    if (columnName === payload.sortBy) {
      setPayload({
        ...payload,
        sortOrder: type,
      })
      setSortAsc(!sortAsc)
    } else {
      setPayload({
        ...payload,
        sortBy: columnName,
        sortOrder: type,
      })
    }

    allSortBy[columnName] = { operation: type }

    setLocalState({ allSortBy })
  }

  const getInitialFilterOperation = filterType => {
    if (['string', 'meta'].includes(filterType)) {
      return TEXT_FILTERS
    }

    if (filterType === 'checkbox') {
      return CHECKBOX_FILTERS
    }

    if (filterType === 'date') {
      // for date we only have GREATER_THEN_EQUAL/LESS_THEN_EQUAL
      return DATE_FILTERS
    }

    return NUMBER_FILTERS
  }

  const handleTableFilterChange = (openEvent, filterType, columnName, subType) => {
    const isFilterExist = get(allFilters, `${columnName}.filterType`)
    if (isFilterExist) {
      setLocalState({ isOpenTableFilters: openEvent, activeFilter: columnName })
      return
    }

    setLocalState({
      isOpenTableFilters: openEvent,
      activeFilter: columnName,
      allFilters: {
        ...allFilters,
        [columnName]: {
          filterType,
          operation: getInitialFilterOperation(filterType)[0].key,
          columnValue: '',
          columnName,
          subType,
        },
      },
    })
  }

  const handleFilterChange = (key, value, columnName) =>
    setLocalState({
      allFilters: {
        ...allFilters,
        [columnName]: {
          ...get(allFilters, columnName, {}),
          [key]: value,
        },
      },
    })

  // Don't pass selectedFilterId
  const handleFilterApply = () => {
    payload.searchFilters = Object.values(allFilters)
    setPayload({ payload })
  }

  /*
   * this is for date calculations dropdown filter
   * calculating start and end date based on filter values
   */
  const handleDateCalculations = filterVal => {
    let startDateSelection = customStartDate
    let endDateSelection = customEndDate

    switch (filterVal) {
      case '-1':
        startDateSelection = null
        endDateSelection = null
        break
      case '12':
        startDateSelection = moment().subtract(12, 'months').format('YYYY-MM-DD')
        endDateSelection = moment().format('YYYY-MM-DD')
        break

      case '3':
        startDateSelection = moment().subtract(3, 'months').format('YYYY-MM-DD')
        endDateSelection = moment().format('YYYY-MM-DD')
        break

      case '1':
        startDateSelection = moment().subtract(1, 'months').format('YYYY-MM-DD')
        endDateSelection = moment().format('YYYY-MM-DD')
        break

      case '7':
        startDateSelection = moment().subtract(7, 'days').format('YYYY-MM-DD')
        endDateSelection = moment().format('YYYY-MM-DD')
        break

      case 'custom':
        setShowDateRange(true)
        return

      default:
        return
    }

    setPayload({ ...payload, startDate: startDateSelection, endDate: endDateSelection })
    if (filterVal !== '-1') {
      setCustomEndDate(moment(endDateSelection).format('MMM DD, YYYY'))
      setCustomStartDate(moment(startDateSelection).format('MMM DD, YYYY'))
    }
  }

  const initiateDownload = invoiceNumber => {
    if (!invoiceNumber || !businessId) return
    downloadInvoiceBlob(invoiceNumber, businessId, true)
  }

  const tableData = invoices?.invoices?.map((data, index) => {
    const { invoiceNumber, invoiceDate, status, totalCents, amountPaidCents } = data || {}
    return (
      <tr onClick={() => initiateDownload(invoiceNumber)} onMouseEnter={() => setRowHover(index)} onMouseLeave={() => setRowHover(-1)}>
        <td className="light-text first-col-pad">{invoiceDate ? moment(invoiceDate).format('MM/DD/YYYY') : ''}</td>
        <td>{invoiceNumber}</td>
        <td>{data.dueDate ? moment(data.dueDate).format('MM/DD/YYYY') : ''}</td>
        <td>{data.numberOfServices}</td>
        <td>
          <span className={`flex justify-center alert-btn ${getStatusColor(lowerCase(status))}`}>{status}</span>
        </td>
        <td className="font-600">{`$${addDecimal(formatCentsToDollars(amountPaidCents))}`}</td>
        <td className="font-600">{`$${addDecimal(formatCentsToDollars(totalCents))}`}</td>
        <td className="last-col-arrow">{rowHover === index && <ArrowNRightIcon />}</td>
      </tr>
    )
  })

  // Rows per page change handler
  const onRowsPerPageChange = event => {
    setPayload({
      ...payload,
      requestedPage: 0,
      requestedPageSize: event.target.value,
    })
  }

  const handlePageChange = pageVal => {
    setPayload({
      ...payload,
      requestedPage: pageVal,
      requestedPageSize: payload.requestedPageSize,
    })
  }

  return (
    <div className="filter-wrapper">
      {isLoading && <Loader />}
      <Grid container className="filter-container">
        <Grid item xs={12} sm={4} md={6} lg={4} className="basic-filter-wrapper">
          <Select
            value={invoiceType}
            onChange={evt => {
              const val = evt.target.value
              setInvoiceType(val)
              if (val === 'all' && payload.status) {
                const updatedPayload = { ...payload }
                delete updatedPayload.status
                setPayload(updatedPayload)
              } else {
                setPayload({
                  ...payload,
                  status: val,
                })
              }
            }}
          >
            {invoicesFilter.map(filter => (
              <MenuItem key={filter.key} value={filter.key}>
                {filter.display}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={8} md={6} lg={8}>
          <Grid container className="items-center mobile-content-start justify-end">
            <Grid item className="month-filter-wrapper">
              <Select
                value={selectedMonths}
                onChange={evt => {
                  const val = evt.target.value
                  handleDateCalculations(val)
                  setSelectedMonths(val)
                }}
                onOpen={() => {
                  setShowDateRange(false)
                }}
              >
                {monthFilter.map(filter => (
                  <MenuItem key={filter.key} value={filter.key}>
                    {filter.display}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid
              item
              className="flex calendar-filter-wrapper"
              onClick={toggleDateRangeSelector}
              sx={{ cursor: isAllTimeSelected ? 'pointer' : 'auto' }}
            >
              <img src={`${MEDIA_SERVER}Calender.svg`} />
              <span>{isAllTimeSelected ? '-' : `${customStartDate}-${customEndDate}`}</span>
            </Grid>
            <Grid item className="date-range-wrapper" ref={wrapperRef}>
              {showDateRange && (
                <DateRangePicker
                  onChange={item => {
                    setRangeSelection([item.selection])
                    setCustomStartDate(moment(item.selection.startDate).format('MMM DD, YYYY'))
                    setCustomEndDate(moment(item.selection.endDate).format('MMM DD, YYYY'))
                    setSelectedMonths('custom')
                    setPayload({
                      ...payload,
                      startDate: moment(item.selection.startDate).format('YYYY-MM-DD'),
                      endDate: moment(item.selection.endDate).format('YYYY-MM-DD'),
                    })
                  }}
                  showSelectionPreview
                  moveRangeOnFirstSelection={false}
                  months={2}
                  showDateDisplay={false}
                  showMonthAndYearPickers
                  ranges={rangeSelection}
                  direction="horizontal"
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container className="table-wrapper invoice-table-wrapper" style={{ marginTop: 15, height: 'calc(100vh - 210px)' }}>
        <Table
          columnsList={headings}
          handleSorting={handlesSorting}
          tableData={tableData}
          page={invoices?.currentPage}
          rowsPerPage={invoices?.pageSize}
          onRowsPerPageChange={onRowsPerPageChange}
          totalItemCount={invoices?.totalItems ?? 0}
          totalPageCount={invoices?.totalPages ?? 0}
          onPageChange={handlePageChange}
          allSortBy={allSortBy}
          onTableFilterChange={handleTableFilterChange}
        />
      </Grid>

      <CommonTableFilters
        isOpen={isOpenTableFilters}
        filterType={get(allFilters, `${activeFilter}.filterType`)}
        subType={get(allFilters, `${activeFilter}.subType`)}
        operation={get(allFilters, `${activeFilter}.operation`)}
        columnName={get(allFilters, `${activeFilter}.columnName`)}
        columnValue={get(allFilters, `${activeFilter}.columnValue`)}
        // key we can also take from activeFilter.columnName
        onFilterChange={handleFilterChange}
        onApply={handleFilterApply}
        // reset filter to past state on cancel
        onClose={() => setLocalState({ isOpenTableFilters: null })}
      />
    </div>
  )
}

export default Invoices
