import React from 'react'

import { Switch, Redirect, useLocation } from 'react-router-dom'
import { CompatRoute } from 'react-router-dom-v5-compat'

import { HomePage, InvoicesPage, PaymentsPage, LoginPage, SettingsPage, SelectAccountPage, ErrorPage } from 'pages'

import routes from 'router/routes'

import ProtectedRoute from './ProtectedRoute'

const MainRoute = () => {
  const location = useLocation()
  return (
    <Switch>
      <CompatRoute path={routes.app.login} component={LoginPage} />
      <ProtectedRoute exact component={SelectAccountPage} path={routes.app.selectAccount} />
      <ProtectedRoute exact component={HomePage} path={routes.app.home} />
      <ProtectedRoute exact component={InvoicesPage} path={routes.app.invoices} />
      <ProtectedRoute exact component={PaymentsPage} path={routes.app.payments} />
      <ProtectedRoute exact component={SettingsPage} path={routes.app.settings} />
      <ProtectedRoute exact component={ErrorPage} path={routes.app.error} />
      <Redirect to={{ pathname: routes.app.home, search: location?.search }} />
    </Switch>
  )
}

export default MainRoute
