import { actions } from '../actions/signup'

const {
  PUT: { SIGNUP, EMAIL_VERIFICATION, ADD_PASSWORD },
} = actions

const initialState = {
  addPassword: null,
  emailVerification: null,
  signup: null,
}

const SignupReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_PASSWORD:
      return { ...state, addPassword: action.payload }

    case EMAIL_VERIFICATION:
      return { ...state, emailVerification: action.payload }

    case SIGNUP:
      return { ...state, signup: action.payload }

    default:
      return state
  }
}

export default SignupReducer
