import React, { useEffect, useState, useReducer } from 'react'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
import Pagination from '@material-ui/lab/Pagination'

import { Button, Grid, MenuItem, Select, Tabs, Tab, Box, Divider, List, TablePagination } from '@material-ui/core'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Clock } from '@styled-icons/heroicons-outline/Clock'
import { makeStyles } from '@material-ui/core/styles'

import { getHomePagePayments, getUpdateAutopay, getIsSurchargeApplied } from 'middleware/actions/payments'
import { combineAddressInfo, handleReducer } from 'utils/helper'
import { INITIAL_PAGE, PAGE_SIZE } from 'utils/pagination'
import { PAGINATION } from 'settings/constants/pagination'

import T from 'T'
import CommonSwitch from 'components/common/CommonSwitch'
import Loader from 'components/common/loader'
import HHConfirmDialog from 'components/common/HHConfirmDialog'

import { get } from 'utils/lodash'
import { downloadPaymentReceiptBlob } from 'utils/file'
import { useAuthValue } from 'auth/AuthContext'
import { sortByDateSelector } from 'data/utils/sortByDateSelector'

import {
  AMOUNT_TYPES,
  ENABLE_AUTOPAY_TIMING,
  DEFAULT_AUTOPAY_TIMING,
  PAYMENT_METHODS,
  PAYMENT_METHOD_IMAGES,
} from 'settings/constants/payment'
import { formatDollarsToCents } from 'utils/price'
import { getAmountDue, getInvoicesThatCanBePaid, getPaymentMethod, getPaymentMethodImage } from 'utils/payment'
import capitalize from 'lodash/capitalize'
import { IconButton, ListItem, ListItemText, SvgIcon, Typography, useTheme } from '@mui/material'
import { PencilAlt } from '@styled-icons/heroicons-solid'

import AutopayDetails from './AutoPayDetails'
import MakePayment from './MakePayment'
import TabPanel from './TabPanel'

import PaymentResponse from './PaymentResponse'
import TransactionSurchargeAlert from './TransactionSurchargeAlert'

import './payments.scss'
import CVVConfirmation from './CVVConfirmation'
import PaymentListItem from './PaymentListItem'
import { useLazyGetBillingAddressQuery } from '../../api/billing/billingAddress'
import EditBillingLocationModal from '../../components/billing/EditBillingLocationModal'
import PaymentResponseDialog from './PaymentResponseDialog'
import HHDisplayMoney from '../../components/HHDisplayMoney'

const { CURRENT_AMOUNT_DUE, OTHER_AMOUNT } = AMOUNT_TYPES
const { CHECK, OTHER, ACCOUNT_CREDIT, ACH, CREDIT_CARD } = PAYMENT_METHODS
const { ROWS_PER_PAGE_OPTIONS } = PAGINATION
const NEW_TRANSACTION_TIMEOUT = 6000
const NEW_TRANSACTION_RETRY_TIMEOUT = 3000
const MAX_RETRY = 10
const PG_IFRAME_CONTAINER_ID = 'paymentgateway'

const useStyles = makeStyles({
  surchargeAlert: {
    marginBottom: '1rem',
    width: 410,
  },
})

const Payments = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const classes = useStyles()
  const { homePagePayments, userInfo } = useSelector(
    state => ({
      homePagePayments: state.PaymentsReducer.homePagePayments,
      userInfo: state.AuthReducer.userInfo,
    }),
    shallowEqual
  )

  const authContext = useAuthValue()
  const accessToken = get(authContext, 'currentUser.accessToken')
  const businessId = useSelector(state => get(state, 'LoginPersist.selectedLoginDetails.businessId'), shallowEqual)
  const accountId = useSelector(state => get(state, 'LoginPersist.selectedLoginDetails.accountId'), shallowEqual)
  const [isOpenEditBillingLocationDialog, setIsOpenEditBillingLocationDialog] = useState(false)
  const [paymentResponseDialog, setPaymentResponseDialog] = useState({ isOpen: false, transaction: {} })
  const [retryCount, setRetryCount] = useState(0)
  const [pg, setPg] = useState(null)
  const [paymentMethodsList, setPaymentMethodsList] = useState([])
  const [initLoad, setInitLoad] = useState(false)
  const [savePaymentMethod, setSavePaymentMethod] = useState(true)
  const [showOtherPayments, setShowOtherPayments] = useState(false)
  const [loading, setLoading] = useState(false)
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState({ isOpen: false, card: null })

  const [transactionsData, setTransactionsData] = useState({
    totalItems: 0,
    currentPage: INITIAL_PAGE,
    pageSize: PAGE_SIZE,
    totalPages: 1,
    transactions: [],
  })

  const allUnpaidInvoices = get(homePagePayments, 'unpaidInvoices', [])
  const currentAmountDue = getAmountDue(allUnpaidInvoices)
  const sortedInvoices = sortByDateSelector({ data: allUnpaidInvoices, key: 'invoiceDate', order: 'asc' })
  const customerCreditBalanceCents = get(homePagePayments, 'customerCreditBalanceCents', 0)
  const [getBillingAddress, { data: billingAddressData, isFetching: isGetBillingAddressLoading }] = useLazyGetBillingAddressQuery()
  const initialState = {
    accountType: null,
    bankAccountType: 'Checking',
    cardHolderName: '',
    cardNickName: '',
    isPaymentError: false,
    paymentAutopay: false,
    originalAutoPay: false,
    rowsPerPage: transactionsData?.pageSize ?? PAGE_SIZE,
    tabToShow: 0,
    addAccountTabToShow: 0,
    selectedPaymentMethod: null,
    selectedCard: null,
    requestedPage: INITIAL_PAGE,
    paymentAmountSelect: 'current',
    otherAmount: '',
    paymentModalOpen: false,
    isACHMethodSelected: false,
    confirmationModalOpen: false,
    autopayTiming: 'Immediate',
    isAutoPayCancel: false,
    oldSelectedCard: null,
    oldSelectedTiming: 'Immediate',
    paymentStatus: '',
    cvv: '',
    currentSelection: '',
    expiry: '',
    saveMethodDisabled: true,
    surcharge: false,
  }

  const billingAddress = get(billingAddressData, 'billingAddress', {})

  const onCloseEditBillingLocationDialog = () => {
    setIsOpenEditBillingLocationDialog(false)
  }

  const onSaveEditBillingLocationDialog = () => {
    setIsOpenEditBillingLocationDialog(false)
    getBillingAddress({ businessId, accountId })
  }

  const onOpenEditBillingLocationDialog = () => {
    setIsOpenEditBillingLocationDialog(true)
  }

  const [pState, setState] = useReducer(handleReducer, initialState)
  const { paymentAmountSelect, otherAmount } = pState
  const isOtherPaymentAmount = paymentAmountSelect === OTHER_AMOUNT
  const billingAddressStr = combineAddressInfo(billingAddress) || 'No address saved'

  const getAllTransactions = (pageNum, rowsPerPage) => {
    window.hhPaymentGateway.getTransactions({
      requestedPage: pageNum,
      requestedPageSize: rowsPerPage,
      accountId,
    })
  }

  const onPageChange = pageNum => {
    setState({ requestedPage: pageNum })
    getAllTransactions(pageNum, pState.rowsPerPage)
  }

  const resetTransaction = () => {
    window.hhTransactionId = null
    setRetryCount(0)
  }

  const handleCallbackPG = notif => {
    if (notif.init && !initLoad) {
      resetTransaction()
      window.hhPaymentGateway.getAllPaymentMethods(accountId)
      getAllTransactions(pState.requestedPage, pState.rowsPerPage)
      setInitLoad(true)
    }

    if (notif) {
      // fetch payment methods
      if (notif.expiry) {
        setState({ expiry: pState.expiry })
      }

      if (get(notif, 'valid')) {
        setState({ saveMethodDisabled: false })
      }

      if (notif.paymentMethods) {
        const allMethods = Array.isArray(notif.paymentMethods) ? notif.paymentMethods.filter(value => Object.keys(value).length !== 0) : []
        setPaymentMethodsList(allMethods)
      }

      // fetch previous transactions
      if (notif.transactions) {
        setTransactionsData({ ...notif })

        if (window.hhTransactionId) {
          const newTransaction = get(notif, 'transactions', []).find(transaction => transaction?.batchId === window.hhTransactionId)

          if (!newTransaction) {
            // retry
            setRetryCount(prev => prev + 1)
            setTimeout(() => onPageChange(0), NEW_TRANSACTION_RETRY_TIMEOUT)
            return
          }

          setState({ paymentModalOpen: false })
          setPaymentResponseDialog({ isOpen: true, transaction: newTransaction })
          resetTransaction()
        }
      }

      // payment method is saved / updated
      if (notif.saved) {
        setState({
          cardHolderName: '',
          cardNickName: '',
        })
        window.hhPaymentGateway.getAllPaymentMethods(accountId)
        setState({ tabToShow: 0 })

        setTimeout(() => {
          setLoading(false)
        }, 1000)
      }

      // payment made
      if (notif.transactionStatus) {
        window.hhPaymentGateway.getAllPaymentMethods(accountId)
        window.hhTransactionId = notif?.batchId
        setTimeout(() => onPageChange(0), NEW_TRANSACTION_TIMEOUT)
      }
    }
  }

  const closePaymentModal = () => {
    dispatch(getHomePagePayments({ businessId, accountId }))
    setState({
      paymentModalOpen: false,
      isPaymentError: false,
      bankAccountType: 'Checking',
      cardHolderName: '',
      cardNickName: '',
      paymentAmountSelect: 'current',
      otherAmount: '',
    })

    setShowOtherPayments(false)
  }

  const handleClosePaymentResponseDialog = () => {
    resetTransaction()
    setPaymentResponseDialog({ isOpen: false, transaction: {} })
    closePaymentModal()
  }

  useEffect(() => {
    if (retryCount >= MAX_RETRY) {
      resetTransaction()
      toast.error('Something went wrong')
      closePaymentModal()
    }
  }, [retryCount])

  const closeCVVModal = () => {
    setState({
      confirmationModalOpen: false,
      cvv: '',
    })
  }

  useEffect(() => {
    if (!window?.hhPaymentGateway) return
    setTimeout(() => {
      setPg(window.hhPaymentGateway)
    }, 0)
  }, [window.hhPaymentGateway])

  useEffect(() => {
    if (businessId && accountId) {
      dispatch(getHomePagePayments({ businessId, accountId }))
      getBillingAddress({ businessId, accountId })
      dispatch(
        getIsSurchargeApplied(userInfo.paymentGatewayExt, businessId, data => {
          setState({ surcharge: data })
        })
      )
    }
  }, [businessId, accountId])

  useEffect(() => {
    if (homePagePayments) {
      setState({
        paymentAutopay: homePagePayments?.autopayInfo?.autopay ?? false,
        originalAutoPay: cloneDeep(get(homePagePayments, 'autopayInfo.autopay', false)),
        selectedPaymentMethod: homePagePayments?.autopayInfo?.paymentMethodId ?? null,
        autopayTiming: homePagePayments?.autopayInfo?.autopayTiming ?? 'Immediate',
        isAutoPayCancel: false,
        oldSelectedCard: homePagePayments?.autopayInfo?.paymentMethodId ?? null,
        oldSelectedTiming: homePagePayments?.autopayInfo?.autopayTiming ?? 'Immediate',
      })
    }
  }, [homePagePayments])

  useEffect(() => {
    if (!pg) return

    const addPaymentForm = document.getElementById('tokenForm')
    const pgElement = document.getElementById(PG_IFRAME_CONTAINER_ID)

    if (addPaymentForm || initLoad || pgElement?.innerHTML?.length > 0) return
    pg.init(accessToken, 'portal', PG_IFRAME_CONTAINER_ID, handleCallbackPG, businessId)
  }, [pg])

  useEffect(() => {
    if (paymentMethodsList.length && !pState.selectedPaymentMethod) {
      setState({ selectedPaymentMethod: paymentMethodsList[0].id })
    }
  }, [paymentMethodsList])

  useEffect(() => {
    if (businessId && accountId && initLoad) {
      try {
        const iframeContainer = document.getElementById(PG_IFRAME_CONTAINER_ID)
        const iframeArray = iframeContainer.getElementsByTagName('iframe')
        const iframe = get(iframeArray, 0)
        iframe.remove()
        pg.init(accessToken, 'portal', PG_IFRAME_CONTAINER_ID, handleCallbackPG, businessId)

        resetTransaction()
        window.hhPaymentGateway.getAllPaymentMethods(accountId)
        onPageChange(0)
      } catch (error) {}
    }
  }, [businessId, accountId])

  useEffect(() => {
    if (!initLoad) return

    getAllTransactions(pState.requestedPage, pState.rowsPerPage)
  }, [pState.requestedPage, pState.rowsPerPage])

  const handleAddAccountTabChange = (evt, index) => {
    setState({ addAccountTabToShow: index, cardHolderName: '', cardNickName: '', bankAccountType: T.CHECKING, saveMethodDisabled: true })
    setLoading(true)

    if (index === 0) {
      window.hhPaymentGateway.switchPaymentMode('Card')
    } else {
      window.hhPaymentGateway.switchPaymentMode('ACH')
    }

    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }

  const handleTabChange = (evt, index) => {
    if (index === 0) {
      window.hhPaymentGateway.getAllPaymentMethods(accountId)
      setShowOtherPayments(false)
    }

    setState({ tabToShow: index })
    handleAddAccountTabChange(null, 0)
  }

  const handleAddPayment = (saveMethodToDb = true) => {
    if (pState.addAccountTabToShow === 0) {
      if (!pState.cardHolderName) {
        toast.error('Cardholder name is mandatory')
        return
      }
    } else if (!pState.cardHolderName || !pState.bankAccountType) {
      toast.error('Name on account and Bank account type are mandatory')
      return
    }

    setState({ saveMethodDisabled: true })

    window.hhPaymentGateway.savePaymentMethod(
      pState.cardHolderName,
      pState.cardNickName,
      !!saveMethodToDb,
      pState.addAccountTabToShow === 1 ? pState.bankAccountType : null,
      null,
      accountId
    )
  }

  const getInvoices = () => {
    if (paymentAmountSelect === CURRENT_AMOUNT_DUE) {
      return { invoicesToPay: sortedInvoices.map(invoice => invoice.invoiceNumber), amountCents: formatDollarsToCents(currentAmountDue) }
    }

    if (paymentAmountSelect === OTHER_AMOUNT) {
      // Process oldest to newest
      const invoicesToPay = getInvoicesThatCanBePaid(sortedInvoices, customerCreditBalanceCents, formatDollarsToCents(otherAmount))
      return { invoicesToPay, amountCents: formatDollarsToCents(otherAmount) }
    }

    const selectedInvoice = sortedInvoices.find(invoice => invoice.invoiceNumber === paymentAmountSelect)
    return { invoicesToPay: [selectedInvoice.invoiceNumber], amountCents: selectedInvoice.totalCents - selectedInvoice.amountPaidCents }
  }

  // Refactor this function and handle error/empty field cases properly
  const handleMakePayment = () => {
    if (loading) {
      return
    }
    resetTransaction()
    const { invoicesToPay, amountCents } = getInvoices()
    // We can pay other amount without invoices
    if (!isOtherPaymentAmount && invoicesToPay.length === 0) {
      return
    }

    if (pState.tabToShow === 0 && showOtherPayments && pState.cardHolderName !== '') {
      const payload = {
        accountId,
        savePaymentMethod,
        invoicesToPay,
        amountCents,
      }

      if (pState.addAccountTabToShow === 0) {
        // Credit Card
        payload.cvv = pState.cvv
      } else {
        // ACH
        payload.accountType = pState.bankAccountType
      }

      setState({ paymentModalOpen: true, paymentStatus: 'pending', isACHMethodSelected: pState.addAccountTabToShow === 1 })
      window.hhPaymentGateway.makePayment(payload)
      return
    }

    if (!pState.selectedCard) {
      toast.error('Please select / add a card to make payment')
      return
    }

    const selectedCard = paymentMethodsList.find(item => item.id === pState.selectedCard)

    if (!selectedCard) {
      toast.error('Please select / add a card to make payment')
      return
    }

    const makePaymentPayload = {
      accountId,
      accountToken: selectedCard.accountToken,
      expiry: selectedCard.expiry,
      savePaymentMethod: false,
      amountCents,
    }

    if (get(selectedCard, 'expiry', '') !== '') {
      makePaymentPayload.cvv = pState.cvv
    }

    makePaymentPayload.invoicesToPay = invoicesToPay

    setState({
      paymentModalOpen: true,
      paymentStatus: 'pending',
      isACHMethodSelected: selectedCard?.accountType || !selectedCard?.creditCardType,
    })
    window.hhPaymentGateway.makePayment(makePaymentPayload)
  }

  const onCVVChange = cvv => {
    setState({ cvv })
  }

  const handleAutopayUpdate = () => {
    const card = paymentMethodsList.find(item => item.id === pState.selectedPaymentMethod)

    if (!card && pState.paymentAutopay) {
      toast.error('Please Add a Card First')
      return
    }

    const autoPayTimeValue = ENABLE_AUTOPAY_TIMING ? pState.autopayTiming : DEFAULT_AUTOPAY_TIMING

    const updatePayload = {
      autopay: pState.paymentAutopay,
      autopayTiming: pState.paymentAutopay ? autoPayTimeValue : null,
      paymentMethodId: card?.id ?? null,
    }

    dispatch(
      getUpdateAutopay(businessId, accountId, updatePayload, autopayData =>
        setState({
          isAutoPayCancel: false,
          oldSelectedCard: autopayData?.paymentMethodId,
          oldSelectedTiming: autopayData?.autopayTiming,
          originalAutoPay: autopayData?.autopay,
          selectedPaymentMethod: autopayData?.paymentMethodId,
          autopayTiming: autopayData?.autopayTiming,
        })
      )
    )
  }

  const handleConfirmDeleteDialog = () => {
    setLoading(true)
    const toBeDeletedCardDetails = confirmDeleteDialog.card
    window.hhPaymentGateway.removePaymentMethod({ ...toBeDeletedCardDetails, accountId })
    if (toBeDeletedCardDetails?.id === pState.selectedCard) {
      setState({ selectedCard: null })
    }
    setConfirmDeleteDialog({ isOpen: false, card: null })
  }

  const handleRemovePaymentMethod = card => setConfirmDeleteDialog({ isOpen: true, card })

  const getDate = date => {
    if (date) {
      return moment(date).format('MM/DD/YYYY')
    }

    return 'NA'
  }

  const getCardHolderName = title => {
    return (
      <div className="flex flex-column">
        <label>
          {title}
          <span className="red ml1">*</span>
        </label>
        <input
          type="text"
          name="cardholdername"
          autoComplete="off"
          value={pState.cardHolderName}
          placeholder="John Doe"
          onChange={evt => setState({ cardHolderName: evt.target.value })}
        />
      </div>
    )
  }

  const getMethodNickName = () => {
    return (
      <div className="flex flex-column">
        <label>{T.METHOD_NICKNAME}</label>
        <input
          type="text"
          name="methodnickname"
          autoComplete="off"
          value={pState.cardNickName}
          placeholder="Business Card"
          onChange={evt => setState({ cardNickName: evt.target.value })}
        />
      </div>
    )
  }

  const handleCVVConfirmation = () => {
    setState({ confirmationModalOpen: true })
  }

  const handlePaymentClick = () => {
    const paymentMethod = paymentMethodsList.find(data => data.id === pState.selectedCard)
    if (!showOtherPayments) {
      if (paymentMethod === undefined) return
      if (get(paymentMethod, 'expiry')) {
        handleCVVConfirmation()
        return
      }
    } else if (pState.addAccountTabToShow === 0) {
      handleCVVConfirmation()
      return
    }
    handleMakePayment()
  }

  const handleSetPaymentMethod = cardId => {
    setState({ selectedCard: cardId })
  }

  // Cleanup this component and move duplicate input fields to a separate function
  const addPayment = (
    <div className="add-payment-tab" id="add-payment-tab">
      <Tabs value={pState.addAccountTabToShow} onChange={handleAddAccountTabChange} className="internal-tabs">
        <Tab label="Credit Card" />
        <Tab label="ACH" />
      </Tabs>

      <TabPanel value={pState.addAccountTabToShow} index={0}>
        <Grid container spacing={4} className="add-payment-extra-wrapper flex">
          <Grid item xs={12} sm={6} md={4}>
            {getCardHolderName(T.CARDHOLDER_NAME)}
          </Grid>
          {savePaymentMethod && (
            <Grid item xs={12} sm={6} md={4}>
              {getMethodNickName()}
            </Grid>
          )}
        </Grid>

        <div className="add-payment">
          <Grid container>
            <Grid item xs={12} sm={6} md={6} lg={5}>
              {document.getElementById('paymentgateway') && pState.addAccountTabToShow === 0 && (
                <div
                  className="paymentgateway"
                  dangerouslySetInnerHTML={{
                    __html: document.getElementById('paymentgateway').innerHTML,
                  }}
                />
              )}
            </Grid>
          </Grid>
        </div>
      </TabPanel>

      <TabPanel value={pState.addAccountTabToShow} index={1}>
        <Grid container spacing={4} className="add-payment-extra-wrapper flex">
          <Grid item xs={12} sm={6} md={4} className="ach-container">
            <label>
              {T.ROUTING_N_ACCOUNT_NUMBER}
              <span className="red ml1">*</span>
            </label>

            {document.getElementById('paymentgateway') && pState.addAccountTabToShow === 1 && (
              <div
                dangerouslySetInnerHTML={{
                  __html: document.getElementById('paymentgateway').innerHTML,
                }}
              />
            )}
            <div className="flex">
              <div className="dot" />
              <div className="accounting-number-msg">{T.ACCOUNTING_NUMBER_MSG}</div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className="ach-MuiGrid-item">
            {getCardHolderName(T.NAME_ON_ACCOUNT)}
          </Grid>
          <Grid item xs={12} sm={6} md={4} className="ach-MuiGrid-item">
            <div className="flex flex-column">
              <label>
                {T.BANK_ACCOUNT_TYPE}
                <span className="red ml1">*</span>
              </label>
              <Select
                value={pState.bankAccountType}
                onChange={evt => setState({ bankAccountType: evt.target.value })}
                className="bank-account-type-select"
              >
                <MenuItem value="Checking">
                  <div>{T.CHECKING}</div>
                </MenuItem>
                <MenuItem value="Saving">
                  <div>{T.SAVING}</div>
                </MenuItem>
              </Select>
            </div>
          </Grid>
          {savePaymentMethod && (
            <Grid item xs={12} sm={6} md={4} className="ach-MuiGrid-item">
              {getMethodNickName()}
            </Grid>
          )}
        </Grid>
      </TabPanel>
    </div>
  )

  const showAutoPaySaveAction = () => {
    const { originalAutoPay, paymentAutopay, selectedPaymentMethod, oldSelectedCard } = pState
    if (paymentAutopay) {
      const isPaymentMethodExist = paymentMethodsList.find(method => method.id === selectedPaymentMethod)
      return isPaymentMethodExist && (!originalAutoPay || selectedPaymentMethod !== oldSelectedCard)
    }

    return originalAutoPay !== paymentAutopay
  }

  const transactionsList = get(transactionsData, 'transactions', [])
  const totalItemCount = transactionsData?.totalItems ?? 0
  const page = transactionsData?.currentPage ?? 0
  const totalPageCount = transactionsData?.totalPages
  const rowsPerPage = transactionsData?.pageSize
  const onRowsPerPageChange = evt => {
    setState({ rowsPerPage: evt.target.value, requestedPage: 0 })
  }

  const handleDownloadPaymentReceipt = transaction => {
    const { accountId: receiptAccountId, paymentNumber, accountTransactionHistoryId } = transaction
    if (!businessId || !receiptAccountId || !paymentNumber || !accountTransactionHistoryId) return

    downloadPaymentReceiptBlob(businessId, receiptAccountId, paymentNumber, accountTransactionHistoryId, true)
  }

  return (
    <>
      {(loading || isGetBillingAddressLoading) && <Loader />}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4} md={3} lg={2}>
          <div className="payment-left-wrapper">
            <div className="payment-left-top">
              <span className="payment-left-amount-label">{T.CURRENT_AMOUNT_DUE}</span>
              <h3 className="payment-left-amount">
                <HHDisplayMoney value={currentAmountDue} formatToDollars={false} />
              </h3>
            </div>
            <div className="payment-left-bottom flex flex-column">
              <span className="payment-left-payment-label">{T.NEXT_AMOUNT_DUE}</span>
              <span className="payment-left-date">
                {currentAmountDue > 0 && homePagePayments?.dueDate ? moment(homePagePayments?.dueDate).format(' MMM DD, YYYY') : 'NA'}
              </span>
              <div className="payment-left-autopay-wrapper flex justify-between">
                <CommonSwitch
                  title={T.AUTOPAY}
                  titlePosition="right"
                  onChange={() => {
                    setState({
                      paymentAutopay: !pState.paymentAutopay,
                    })
                  }}
                  isChecked={pState.paymentAutopay}
                />
              </div>
            </div>

            <>
              <div className="auto-pay-column">
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h5" fontWeight={500}>
                          {T.BILLING_ADDRESS}
                        </Typography>
                        <IconButton onClick={onOpenEditBillingLocationDialog}>
                          <SvgIcon fontSize="small" sx={{ color: theme.palette.text.secondary }}>
                            <PencilAlt />
                          </SvgIcon>
                        </IconButton>
                      </Box>
                    }
                    secondary={billingAddressStr}
                    secondaryTypographyProps={{ variant: 'h6', fontWeight: 400, color: 'textPrimary' }}
                  />
                </ListItem>
                {pState.paymentAutopay && (
                  <AutopayDetails
                    paymentDetails={homePagePayments}
                    AutoPayDetails
                    autopayTiming={pState.autopayTiming}
                    selectedPaymentMethod={pState.selectedPaymentMethod}
                    setState={setState}
                    paymentMethodsList={paymentMethodsList}
                    addPaymentOpen={handleTabChange}
                    originalAutoPay={pState.originalAutoPay}
                    handleAutopayUpdate={handleAutopayUpdate}
                    oldTiming={pState.oldSelectedTiming}
                    isAutoPayCancel={pState.isAutoPayCancel}
                  />
                )}
              </div>
            </>

            {showAutoPaySaveAction() && (
              <div className="flex justify-between auto-pay-column-buttons">
                <Button className="simple-default-button" onClick={() => setState({ paymentAutopay: pState.originalAutoPay })}>
                  {T.CANCEL}
                </Button>
                <Button variant="contained" onClick={handleAutopayUpdate}>
                  {T.SAVE}
                </Button>
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={8} md={9} lg={10}>
          <div className="payment-right-top-wrapper">
            <div className="payment-gateway-tabs-container">
              <Tabs value={pState.tabToShow} onChange={handleTabChange}>
                <Tab label={T.MAKE_A_PAYMENT} />
                <Tab label={T.ADD_PAYMENT_METHOD} />
              </Tabs>

              <TabPanel value={pState.tabToShow} index={0}>
                <MakePayment
                  unPaidInvoices={sortedInvoices}
                  amountToPay={currentAmountDue}
                  addPaymentOpen={handleTabChange}
                  addPayment={addPayment}
                  addAccountTabToShow={pState.addAccountTabToShow}
                  paymentMethodsList={paymentMethodsList}
                  paymentAmountSelect={paymentAmountSelect}
                  otherAmount={otherAmount}
                  selectedPaymentMethod={pState.selectedCard}
                  handlePaymentClick={handlePaymentClick}
                  handleRemovePaymentMethod={handleRemovePaymentMethod}
                  handleAddPayment={handleAddPayment}
                  setPaymentMethod={cardId => handleSetPaymentMethod(cardId)}
                  setState={setState}
                  pState={pState}
                  showOtherPayments={showOtherPayments}
                  setShowOtherPayments={setShowOtherPayments}
                  savePaymentMethod={savePaymentMethod}
                  setSavePaymentMethod={setSavePaymentMethod}
                  handleAddAccountTabChange={handleAddAccountTabChange}
                />
              </TabPanel>

              <TabPanel value={pState.tabToShow} index={1}>
                {addPayment}

                {get(pState, 'addAccountTabToShow', 0) === 0 && get(pState, 'surcharge', false) && (
                  <TransactionSurchargeAlert className={`cursor-pointer ${classes.surchargeAlert}`} />
                )}

                <div className="add-payment">
                  <Button
                    variant="contained"
                    className={`payment-button ${pState.saveMethodDisabled ? 'btn-inactive' : ''} ${
                      pState.addAccountTabToShow === 1 ? 'mt3' : ''
                    }`}
                    onClick={handleAddPayment}
                  >
                    {T.SAVE_METHOD}
                  </Button>
                </div>
              </TabPanel>
            </div>
          </div>
          <Grid item xs={12} className="mt-4 payment-history-wrapper">
            <div className="payment-right-top-wrapper">
              <div className="payment-right-top-table">
                <Box marginBottom={2} className="payment-history-label flex">
                  <Clock className="icon-w-16" />
                  <div className="label">{T.PAYMENT_HISTORY}</div>
                </Box>
                <Divider />
                <List disablePadding>
                  {transactionsList.map((transaction, i) => {
                    const transactionStatus = get(transaction, 'transactionStatus')
                    const capitalizeTransactionStatus = capitalize(transactionStatus)
                    const paymentMethod = get(transaction, 'paymentMethod')
                    const accountType = get(transaction, 'accountType')
                    const accountNumber = get(transaction, 'accountNumber')
                    const transactionAmountCents = get(transaction, 'transactionAmountCents')
                    const paymentDate = get(transaction, 'paymentDate')
                    const checkNum = get(transaction, 'checkNum')
                    const date = getDate(paymentDate)
                    const isCheckOther = [CHECK, OTHER].includes(paymentMethod)
                    const isAccountCredit = paymentMethod === ACCOUNT_CREDIT
                    const paymentMethodName = paymentMethod === ACH ? paymentMethod : capitalize(getPaymentMethod(paymentMethod))
                    const paymentMethodImage =
                      paymentMethod === CREDIT_CARD ? getPaymentMethodImage(accountType) : PAYMENT_METHOD_IMAGES[paymentMethod]
                    const isNotLast = transactionsList.length - 1 !== i
                    const checkValue = isCheckOther ? checkNum : accountNumber
                    const paymentNumber = get(transaction, 'paymentNumber')

                    return (
                      <PaymentListItem
                        accountNumber={accountNumber}
                        accountType={accountType}
                        isCheckOther={isCheckOther}
                        capitalizeTransactionStatus={capitalizeTransactionStatus}
                        checkValue={checkValue}
                        date={date}
                        isAccountCredit={isAccountCredit}
                        isNotLast={isNotLast}
                        paymentMethodImage={paymentMethodImage}
                        paymentMethodName={paymentMethodName}
                        transactionAmountCents={transactionAmountCents}
                        paymentNumber={paymentNumber}
                        onClick={() => handleDownloadPaymentReceipt(transaction)}
                      />
                    )
                  })}
                </List>
                {totalItemCount > 0 && (
                  <div className="table-pagination">
                    <Grid container className="table-footer">
                      <Grid item xs={12} md={7} className="left">
                        <TablePagination
                          SelectProps={{
                            MenuProps: { classes: { paper: 'pagination-menu-popover' } },
                          }}
                          className="left-pagination flex"
                          page={page}
                          component="div"
                          count={totalItemCount}
                          rowsPerPage={rowsPerPage}
                          onChangePage={(event, newPage) => onPageChange(newPage)}
                          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                          onChangeRowsPerPage={onRowsPerPageChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={5} className="right flex justify-end">
                        {/* Pagination starts from 1 */}
                        <Pagination
                          count={totalPageCount}
                          page={page + 1}
                          onChange={(event, newPage) => onPageChange(newPage - 1)}
                          className="right-pagination"
                        />
                      </Grid>
                    </Grid>
                  </div>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
        <PaymentResponse
          isACHMethodSelected={pState.isACHMethodSelected}
          paymentStatus={pState.paymentStatus}
          paymentModalOpen={pState.paymentModalOpen}
          closePaymentModal={closePaymentModal}
        />
        <PaymentResponseDialog
          isOpen={paymentResponseDialog?.isOpen}
          transaction={paymentResponseDialog?.transaction}
          businessId={businessId}
          onClose={handleClosePaymentResponseDialog}
        />
        <CVVConfirmation
          cvv={pState.cvv}
          confirmationModalOpen={pState.confirmationModalOpen}
          closeCVVModal={closeCVVModal}
          onCVVChange={onCVVChange}
          handleMakePayment={handleMakePayment}
          isProceedLoading={loading}
        />
        <HHConfirmDialog
          isOpen={confirmDeleteDialog.isOpen}
          confirmTitle="Delete payment method"
          confirmDescription="Are you sure you want to delete this payment method?"
          onConfirm={handleConfirmDeleteDialog}
          onClose={() => setConfirmDeleteDialog({ isOpen: false, card: null })}
        />
        <div id={PG_IFRAME_CONTAINER_ID} className="paymentgateway dn" />
        <EditBillingLocationModal
          customerDetails={{ billingAddress }}
          open={isOpenEditBillingLocationDialog}
          onClose={onCloseEditBillingLocationDialog}
          onSave={onSaveEditBillingLocationDialog}
        />
      </Grid>
    </>
  )
}

export default Payments
