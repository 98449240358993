import React from 'react'
import PropTypes from 'prop-types'

import { Grid, Table as TableView, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@material-ui/core'

import Pagination from '@material-ui/lab/Pagination'
import { ReactComponent as TableSortIconUp } from 'assets/TableSortIconUp.svg'
import { ReactComponent as TableSortIconDown } from 'assets/TableSortIconDown.svg'
import { PAGINATION } from 'settings/constants/pagination'

import Constants from 'Constants'

import { get } from 'utils/lodash'

import './table.scss'

const { MEDIA_SERVER } = Constants

const { INITIAL_PAGE, ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } = PAGINATION

const Table = ({
  columnsList,
  allFilters,
  tableData,
  page,
  onPageChange,
  rowsPerPage,
  onRowsPerPageChange,
  totalItemCount,
  totalPageCount,
  allSortBy,
  handleSorting,
  onTableFilterChange,
}) => {
  const isFilterExist = key =>
    get(allFilters, `${key}.operation`) && (get(allFilters, `${key}.columnValue`) || get(allFilters, `${key}.filterType`) === 'date')
  return (
    <>
      <TableContainer className="table-wrapper">
        <TableView stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columnsList.map((heading, hIndex) => (
                <TableCell key={heading.id} className={hIndex === 0 ? 'first-col-pad' : ''}>
                  <div className="wrapper inline-flex items-center">
                    {heading.filter && (
                      <span className="table-icon">
                        <img
                          className="cursor-pointer"
                          src={`${MEDIA_SERVER}${isFilterExist(heading.id) ? 'TableFilterIconFilled.svg' : 'TableFilterIcon.svg'}`}
                          alt=""
                          onClick={event =>
                            onTableFilterChange(event.currentTarget, heading.filterType, heading.id, get(heading, 'subType', ''))
                          }
                        />
                      </span>
                    )}
                    <span className="th-text">{heading.title}</span>
                    {heading.sort && (
                      <span className="inline-flex flex-column sort-icon">
                        <TableSortIconUp
                          className={get(allSortBy, `${heading.id}.operation`) === 'ASC' ? 'filled' : 'default'}
                          onClick={() => handleSorting(heading.id, 'ASC')}
                        />
                        <TableSortIconDown
                          className={get(allSortBy, `${heading.id}.operation`) === 'DESC' ? 'mt1 filled' : 'mt1 default'}
                          onClick={() => handleSorting(heading.id, 'DESC')}
                        />
                      </span>
                    )}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{tableData}</TableBody>
        </TableView>
      </TableContainer>

      {totalItemCount > 0 && (
        <div className="table-pagination">
          <Grid container className="table-footer">
            <Grid item xs={12} md={7} className="left">
              <TablePagination
                SelectProps={{
                  MenuProps: { classes: { paper: 'pagination-menu-popover' } },
                }}
                className="left-pagination flex"
                page={page}
                component="div"
                count={totalItemCount}
                rowsPerPage={rowsPerPage}
                onChangePage={(event, newPage) => onPageChange(newPage)}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                onChangeRowsPerPage={onRowsPerPageChange}
              />
            </Grid>
            <Grid item xs={12} md={5} className="right flex justify-end">
              {/* Pagination starts from 1 */}
              <Pagination
                count={totalPageCount}
                page={page + 1}
                onChange={(event, newPage) => onPageChange(newPage - 1)}
                className="right-pagination"
              />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  )
}

Table.defaultProps = {
  columnsList: [],
  tableData: [],
  allSortBy: {},
  allFilters: {},
  page: INITIAL_PAGE,
  rowsPerPage: ROWS_PER_PAGE,
  totalItemCount: INITIAL_PAGE,
  totalPageCount: INITIAL_PAGE,
  handleSorting: null,
  onTableFilterChange: null,
}

Table.propTypes = {
  columnsList: PropTypes.array,
  tableData: PropTypes.array,
  allSortBy: PropTypes.object,
  allFilters: PropTypes.object,
  handleSorting: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalItemCount: PropTypes.number,
  totalPageCount: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  onTableFilterChange: PropTypes.func,
}

export default Table
