export const TEXT_FILTERS = [
  { key: 'MATCH_TO', value: 'Contains' },
  { key: 'NOT_MATCH_TO', value: 'Does Not Contain' },
  { key: 'MATCH_START', value: 'Starts With' },
  { key: 'EQUAL_TO', value: 'Equals' },
  { key: 'NOT_EQUAL', value: 'Does Not Equal' },
]

export const NUMBER_FILTERS = [
  { key: 'EQUAL_TO', value: 'Is Equal to' },
  { key: 'NOT_EQUAL', value: 'Is Not Equal to' },
  { key: 'GREATER_THEN', value: 'Is Greater Than' },
  { key: 'LESS_THEN', value: 'Is Less Than' },
]

// Duplicate keys not supported in select , so append value also
export const DATE_FILTERS = [
  { key: 'GREATER_THEN_EQUAL_TODAY', value: 'Today' },
  { key: 'GREATER_THEN_EQUAL_YESTERDAY', value: 'Yesterday' },
  { key: 'GREATER_THEN_EQUAL_THIS_WEEK', value: 'This Week' },
  { key: 'GREATER_THEN_EQUAL_LAST_WEEK', value: 'Last Week' },
  { key: 'GREATER_THEN_EQUAL_PAST_TWO_WEEK', value: 'Past Two Weeks' },
  { key: 'GREATER_THEN_EQUAL_THIS_MONTH', value: 'This Month' },
  { key: 'GREATER_THEN_EQUAL_LAST_MONTH', value: 'Last Month' },
  { key: 'LESS_THEN_EQUAL_BEFORE', value: 'Before' },
  { key: 'GREATER_THEN_EQUAL_AFTER', value: 'After' },
]

export const CHECKBOX_FILTERS = [{ key: 'EQUAL_TO', value: 'Equals' }]
