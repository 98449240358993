import styled from 'styled-components'

import { ArrowNarrowRight, Check, CreditCard, InformationCircle, LockClosed, PencilAlt, Printer, X } from '@styled-icons/heroicons-outline'

import { colors } from 'utils/constants'

export const HomeInformationIcon = styled(InformationCircle)`
  color: ${colors.textLighBlackColor};
  width: 1.75vh;
  height: 1.75vh;
  margin: 2px 0px 0px 3px;
`

export const PrinterIcon = styled(Printer)`
  color: ${colors.iconDarkGreyColor};
  width: 24px;
  height: 24px;
`

export const LockClosedIcon = styled(LockClosed)`
  color: ${colors.textLighBlackColor};
  width: 12px;
  height: 14px;
  margin: 5px 10px 0px 0px;
`

export const XIcon = styled(X)`
  color: ${colors.primaryWhite};
  width: 12px;
  height: 14px;
  margin: 3px 0px 0px 2px;
`

export const CreditCardIcon = styled(CreditCard)`
  color: ${colors.textLighBlackColor};
  width: 24px;
  height: 19px;
  margin: 2px 5px 0px 0px;
`

export const EditIcon = styled(PencilAlt)`
  color: ${colors.textLighBlackColor};
  width: 21px;
  height: 21px;
  margin: 2px 5px 0px 0px;
`

export const ArrowNRightIcon = styled(ArrowNarrowRight)`
  color: ${colors.textLighBlackColor};
  width: 25px;
  height: 18px;
`

export const CheckIcon = styled(Check)`
  color: ${colors.textLighBlackColor};
  width: 18px;
  height: 20px;
`
