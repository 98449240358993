import React, { useState } from 'react'
import moment from 'moment'

import { Calendar } from '@styled-icons/heroicons-outline/Calendar'

import { Button, MenuItem, Radio, Select } from '@material-ui/core'

import { SummaryModal } from 'components/modal'

import T from 'T'
import PaymentMethod from 'components/common/PaymentMethod'

import { get } from 'utils/lodash'
import { ENABLE_AUTOPAY_TIMING } from 'settings/constants/payment'

import AutopayTermsConditions from './AutopayTermsConditions'

const AutoPayDetails = props => {
  const [isOpenSummaryModal, setIsOpenSummaryModal] = useState(false)
  const selectedTiming = props.isAutoPayCancel ? props.oldTiming : props.autopayTiming
  const allPaymentMethods = get(props, 'paymentMethodsList', [])
  const hasPaymentMethods = allPaymentMethods.length > 0
  const selectedPaymentMethod = get(props, 'selectedPaymentMethod')

  const getDate = date => {
    if (date) {
      return moment(date).format('MM/DD/YYYY')
    }

    return 'NA'
  }

  return (
    <>
      <div className="flex flex-column">
        <div className={`flex flex-column payment-detail-recipt`}>
          <label>{T.STORED_PAYMENT_METHOD}</label>
          {!hasPaymentMethods && (
            <Button variant="outlined" onClick={() => props.addPaymentOpen(null, 1)}>
              {T.ADD_PAYMENT_METHOD}
            </Button>
          )}

          {hasPaymentMethods && (
            <Select value={[selectedPaymentMethod]} onChange={evt => props.setState({ selectedPaymentMethod: evt.target.value })}>
              <MenuItem value="" className="card-details">
                Select method
              </MenuItem>
              {props.paymentMethodsList.map(method => (
                <MenuItem key={method.accountToken} value={method.id}>
                  <div className="flex payment-input justify-between items-center">
                    <PaymentMethod method={method} />
                  </div>
                </MenuItem>
              ))}
            </Select>
          )}
        </div>

        <div className={`flex flex-column payment-detail-recipt`}>
          <label>{T.INVOICE_DATE}</label>
          <div className="flex">
            <Calendar className="cal-icon icon-w-16 mr1" />
            <span>{moment(props.paymentDetails?.invoiceDate).format('MM/DD/YYYY')}</span>
          </div>
        </div>

        <div className={`flex flex-column payment-detail-recipt`}>
          <label>{T.DUE_DATE}</label>
          <div className="flex">
            <Calendar className="cal-icon icon-w-16 mr1" />
            <span>{getDate(props.paymentDetails?.dueDate)}</span>
          </div>
        </div>

        <div className={`flex flex-column payment-detail-recipt mb-8`}>
          <label>{T.AUTOPAY_DATE}</label>
          <div className="flex">
            <Calendar className="cal-icon icon-w-16 mr1" />
            <span>
              {get(props, 'paymentDetails.autopayInfo.autopayTiming') === 'Immediate'
                ? getDate(props.paymentDetails?.invoiceDate)
                : getDate(props.paymentDetails?.dueDate)}
            </span>
          </div>
        </div>

        <div className="selected-terms-divider" />

        <div className={`flex flex-column payment-detail-recipt mt-8`}>
          <label>{T.SELECTED_METHOD}</label>
          <span>{T.INVOICE}</span>
        </div>

        <div className={`flex flex-column payment-detail-recipt`}>
          <label>{T.PAYMENT_TERMS}</label>
          <span>{props.paymentDetails?.paymentTerm}</span>
        </div>

        {hasPaymentMethods && ENABLE_AUTOPAY_TIMING && (
          <div className={`flex flex-column payment-detail-recipt`}>
            <label>{T.AUTOPAY_TIMING}</label>
            <span>{selectedTiming}</span>
          </div>
        )}

        {hasPaymentMethods && ENABLE_AUTOPAY_TIMING && (
          <div className={`flex flex-column payment-detail-recipt`}>
            <label>{T.AUTOPAY_TIMING}</label>
            <div className="flex flex-column">
              <div className="autopay-timing-wrapper">
                <Radio
                  name="autopayTiming"
                  checked={selectedTiming === 'Immediate'}
                  onChange={() => props.setState({ autopayTiming: 'Immediate' })}
                />{' '}
                <span>{T.IMMEDIATE}</span>
              </div>

              <div className="autopay-timing-wrapper">
                <Radio
                  name="autopayTiming"
                  checked={selectedTiming === 'Due Date'}
                  onChange={() => props.setState({ autopayTiming: 'Due Date' })}
                />{' '}
                <span>{T.DUE_DATE}</span>
              </div>
            </div>
          </div>
        )}

        {hasPaymentMethods && (
          <div className="flex items-start autopay-tnc-wrapper">
            <span className="autopay-tnc">
              {T.AUTOPAY_ENROLMENT_MSG}
              <a href="javascript:void(0)" onClick={() => setIsOpenSummaryModal(true)}>
                {T.TERMS_N_CONDITIONS}
              </a>
              .
            </span>
          </div>
        )}
      </div>

      <SummaryModal
        isOpen={isOpenSummaryModal}
        summaryContent={<AutopayTermsConditions />}
        onClose={() => {
          setIsOpenSummaryModal(false)
        }}
      />
    </>
  )
}

export default AutoPayDetails
