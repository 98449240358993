import { inRange } from 'lodash'

export const REGEX = {
  IS_EMAIL: /\S+@\S+\.\S{2,}/,
  IS_NUMBER_ONLY: /^[0-9\b]+$/,
  IS_PRICE: /^\d{0,10}(\.\d{0,2})?$/,
  IS_ALPHABET_NUMERIC_AND_SPACE: /^[a-zA-Z0-9][a-zA-Z0-9\s]*$/,
  IS_STRONG_PASSWORD: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/,
}

export const isValidPrice = price => REGEX.IS_PRICE.test(price)

export const isNumberOnly = number => REGEX.IS_NUMBER_ONLY.test(number)

export const isAlphNumericAndSpaceOnly = name => REGEX.IS_ALPHABET_NUMERIC_AND_SPACE.test(name)

export const isValidLongitude = coord => REGEX.IS_VALID_LONGITUDE.test(coord)

export const isValidLatitude = coord => REGEX.IS_VALID_LATITUDE.test(coord)

export const isValidLatitudeRange = num => (num ? inRange(num, -90, 90) : false)

export const isValidLongitudeRange = num => (num ? inRange(num, -180, 180) : false)

export const isStrongPassword = password => REGEX.IS_STRONG_PASSWORD.test(password)
