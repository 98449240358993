import { takeEvery, all, call, put } from 'redux-saga/effects'

import { doGet, doPost } from 'providers/api'

import { actions, putHomePagePayments, putUpdateAutopay } from '../actions/payments'

export const getHomePagePaymentDetails = payload =>
  doGet({ url: `/api/v1/tenants/${payload.businessId}/accounts/${payload.accountId}/payment`, showLoader: true })
export const getUpdateAutopayAPI = ({ body, businessId, accountId }) =>
  doPost({ url: `/api/v1/tenants/${businessId}/accounts/${accountId}/payment/autopay/add`, body, showLoader: true })
export const getIsSurchargeApplied = ({ url, businessId }) =>
  doGet({ url: `${url}/api/portal/tenants/${businessId}/payment/surcharge`, showLoader: false })

export function* getHomePagePaymentsSaga({ payload }) {
  try {
    const { data: res } = yield call(getHomePagePaymentDetails, payload)
    yield put(putHomePagePayments(res))
  } catch (err) {
    yield put(putHomePagePayments(null))
  }
}

export function* getUpdateAutopaySaga({ payload }) {
  const { body, businessId, accountId, onSuccess } = payload
  try {
    const { data: res } = yield call(getUpdateAutopayAPI, { body, businessId, accountId })

    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
    yield put(putUpdateAutopay(res))
  } catch (err) {
    onSuccess([])
    yield put(putUpdateAutopay(null))
  }
}

export function* getIsSurchargeAppliedSaga({ payload }) {
  const { url, businessId, onSuccess } = payload
  try {
    const { data: res } = yield call(getIsSurchargeApplied, { url, businessId })
    if (typeof onSuccess === 'function') {
      onSuccess(res)
    }
  } catch (err) {
    onSuccess(false)
  }
}

export default function* Payments() {
  yield all([
    takeEvery(actions.GET.HOME_PAGE_PAYMENTS, getHomePagePaymentsSaga),
    takeEvery(actions.GET.UPDATE_AUTOPAY, getUpdateAutopaySaga),
    takeEvery(actions.GET.IS_SURCHARGE_APPLIED, getIsSurchargeAppliedSaga),
  ])
}
