import { initializeApp } from 'firebase/app'
import { getAuth, signOut } from 'firebase/auth'

import { firebaseConfig } from 'settings/constants/firebase'

export const app = initializeApp(firebaseConfig)

export const auth = getAuth(app)

export const handleFirebaseLogout = () => signOut(auth)

export const getToken = () => {
  const user = auth?.currentUser
  if (user) {
    return user.getIdToken()
  }

  return Promise.resolve()
}
