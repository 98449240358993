import React from 'react'
import styled from 'styled-components'

import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { CompatRouter } from 'react-router-dom-v5-compat'
import { HelmetProvider } from 'react-helmet-async'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider as ThemeProviderV5 } from '@mui/material/styles'
import { ThemeProvider as ThemeProviderV4, StylesProvider } from '@material-ui/core/styles'
import { CssBaseline, Paper } from '@material-ui/core'
import 'mapbox-gl/dist/mapbox-gl.css'

import { store, sagaMiddleware, persistor } from 'providers/store'
import AuthProvider from 'auth/AuthContext'
import rootSaga from 'middleware/sagas'
import history from 'providers/history'
import haulerHeroTheme from 'theme'

import MainContainer from 'components/common/MainContainer'
import MainRoute from 'router'

import 'styles/common.scss'
import './App.css'
import 'overrides.scss'
import themeV5 from '../../theme/themeV5'

sagaMiddleware.run(rootSaga)

const Root = styled.div`
  display: flex;
`

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const MainContent = styled(Paper)`
  flex: 1;
  display: flex;
  flex-flow: column;
  background-color: #f7f9fc;
  border-radius: 0px;
  box-shadow: none;
`

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <AuthProvider>
          <StylesProvider injectFirst>
            <Root>
              <CssBaseline />
              <AppContent>
                <HelmetProvider>
                  <ThemeProviderV4 theme={haulerHeroTheme}>
                    <ThemeProviderV5 theme={themeV5}>
                      <Router history={history}>
                        <CompatRouter>
                          <MainContainer>
                            <MainContent className="MainContentWrapper">
                              <MainRoute />
                            </MainContent>
                          </MainContainer>
                        </CompatRouter>
                      </Router>
                    </ThemeProviderV5>
                  </ThemeProviderV4>
                </HelmetProvider>
              </AppContent>
            </Root>
          </StylesProvider>
        </AuthProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
