export const actions = {
  GET: {
    APP_SETTINGS: 'GET_APP_SETTINGS',
  },
  PUT: {
    APP_SETTINGS: 'PUT_APP_SETTINGS',
  },
}

export const putAppSettings = res => ({
  type: actions.PUT.APP_SETTINGS,
  payload: res,
})

export const getAppSettings = payload => ({
  type: actions.GET.APP_SETTINGS,
  payload,
})
