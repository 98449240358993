import React, { useEffect, useRef, useReducer } from 'react'
import PropTypes from 'prop-types'

import { Box, Button } from '@mui/material'

import { MAP_DEFAULT_OPTIONS } from 'settings/constants/map'
import { isValidLatitudeRange, isValidLongitudeRange } from 'utils/validations'
import { isMapboxSupported, createMap, createMarker, easeToMap, dragEndMarkerResult, flyToMap } from 'utils/map'

import Constants from 'Constants'
import MapPlaceholder from 'components/common/address/MapPlaceholder'
import mapboxgl from '!mapbox-gl'

const { ZOOM, STREET_VIEW, SATELLITE_VIEW, MAP_VIEW_URL } = MAP_DEFAULT_OPTIONS
const { MEDIA_SERVER, MAPBOX_TOKEN } = Constants

const MapView = ({
  defaultMapView = STREET_VIEW,
  longitude = '',
  longitudeForMapHistory,
  latitude = '',
  latitudeForMapHistory,
  onChange,
  onReset,
}) => {
  const mapContainer = useRef(null)
  const map = useRef(null)
  const marker = useRef(null)

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    isMapInitialized: false,
    isMapChanged: false,
    mapZoom: ZOOM,
    isStreetView: defaultMapView === STREET_VIEW,
  })
  const { isMapInitialized, isMapChanged, mapZoom, isStreetView } = localState
  const isValidLatLong = isValidLatitudeRange(latitude) && isValidLongitudeRange(longitude)

  const initiatemarker = (long, lat, fly = false) => {
    if (marker.current) {
      // Remove any existing marker
      marker.current.remove()
    }

    setTimeout(() => {
      marker.current = createMarker(mapboxgl, map, long, lat)
      if (marker.current) {
        marker.current.on('dragend', () => {
          const result = dragEndMarkerResult(map, marker)
          setLocalState({ ...result })
          onChange({ ...result })
        })
        if (fly) {
          flyToMap(map, long, lat)
        } else {
          easeToMap(map, long, lat)
        }
      }
    }, 100)
  }

  const initiateMap = (long, lat) => {
    createMap(mapboxgl, mapContainer, long, lat, mapZoom, isStreetView ? STREET_VIEW : SATELLITE_VIEW, cbResult => {
      map.current = cbResult
      map?.current?.addControl(new mapboxgl.NavigationControl())
      if (map.current) {
        initiatemarker(long, lat)
      }
    })
  }

  const handleResetMap = () => {
    setLocalState({ isMapChanged: false })
    onReset()
  }

  useEffect(() => {
    mapboxgl.accessToken = MAPBOX_TOKEN
    isMapboxSupported(mapboxgl)
    setLocalState({ isMapInitialized: true })
  }, [])

  useEffect(() => {
    if (!isMapInitialized) {
      return
    }

    if (map?.current) {
      // initialize map only once
      map.current.setStyle(`${MAP_VIEW_URL}${isStreetView ? STREET_VIEW : SATELLITE_VIEW}`)
      return
    }

    if (isValidLatLong) {
      initiateMap(longitude, latitude)
    }
  }, [isMapInitialized, isStreetView])

  useEffect(() => {
    if (!isMapInitialized || !isValidLatLong) {
      return
    }

    if (longitude === longitudeForMapHistory && latitude === latitudeForMapHistory) {
      setLocalState({ isMapChanged: false })
    }

    if (map?.current) {
      initiatemarker(longitude, latitude)
      return
    }

    initiateMap(longitude, latitude)
  }, [longitude, latitude])

  return (
    <>
      {isValidLatLong && (
        <Box position="relative">
          <Box display="flex" alignItems="center" justifyContent="space-between" position="absolute" top={6} right={44} zIndex={1}>
            {isMapChanged && longitudeForMapHistory && latitudeForMapHistory && (
              <Button
                sx={{ mr: 1, backgroundColor: 'background.paper', '&:hover': { backgroundColor: 'background.paper' } }}
                size="small"
                color="error"
                onClick={handleResetMap}
              >
                Reset
              </Button>
            )}

            <img
              height={70}
              className="cursor-pointer"
              src={`${MEDIA_SERVER}${isStreetView ? 'SatelliteView.png' : 'StreetView.png'}`}
              onClick={() => setLocalState({ isStreetView: !isStreetView })}
            />
          </Box>
        </Box>
      )}

      <Box sx={{ '& .mapboxgl-ctrl-compass': { display: 'none' } }} ref={mapContainer} className="map-container" height="100%" />

      {!isValidLatLong && <MapPlaceholder sx={{ position: 'relative', zIndex: 10, transform: 'translate(0%, -100%)' }} />}
    </>
  )
}

MapView.propTypes = {
  defaultMapView: PropTypes.oneOf([SATELLITE_VIEW, STREET_VIEW]),
  longitude: PropTypes.string,
  longitudeForMapHistory: PropTypes.string,
  latitude: PropTypes.string,
  latitudeForMapHistory: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
}

export default MapView
