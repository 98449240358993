import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getToken } from '../providers/firebaseAuth'

const api = createApi({
  reducerPath: 'apiReducer',
  baseQuery: fetchBaseQuery({
    baseUrl: '/',
    prepareHeaders: async headers => {
      const token = await getToken()
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  endpoints: () => ({}),
})

export default api
