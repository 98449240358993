/* eslint-disable import/no-webpack-loader-syntax,import/no-unresolved */

import { MAP_DEFAULT_OPTIONS } from 'settings/constants/map'
import { toast } from 'react-toastify'
import { get } from 'utils/lodash'

const { MAP_VIEW_URL, STREET_VIEW, LNG_LAT_FIX, ZOOM_FIX } = MAP_DEFAULT_OPTIONS

// Add conditions to check if mapContainer, map, marker references exist then only call these methods
const CREATE_MAP_MAX_TRY = 3
let mapTryCount = 0

export const isMapboxSupported = mapboxgl => {
  try {
    if (!mapboxgl.supported()) {
      toast.error('Your browser does not support Mapbox GL | Please try to refresh/restart your browser')
    }
  } catch (err) {}
}

export const createMap = (mapboxgl, mapContainer, lng, lat, zoom, mapStyle = STREET_VIEW, callback = null) => {
  try {
    const map = new mapboxgl.Map({
      // renderWorldCopies: false,
      container: mapContainer.current,
      style: MAP_VIEW_URL + mapStyle,
      center: [lng, lat],
      zoom,
    })

    // reset try count
    mapTryCount = 0

    return callback ? callback(map) : map
  } catch (err) {
    if (mapTryCount < CREATE_MAP_MAX_TRY) {
      mapTryCount += 1
      setTimeout(() => {
        createMap(mapboxgl, mapContainer, lng, lat, zoom, mapStyle, callback)
      }, 15)
    } else {
      // reset try count
      mapTryCount = 0
      toast.error(`${get(err, 'message', err)} | Please try to refresh/restart your browser`)

      return callback ? callback(null) : null
    }
  }
}

export const createMarker = (mapboxgl, map, lng, lat) => {
  try {
    return new mapboxgl.Marker({
      draggable: true,
      color: '#da0808',
    })
      .setLngLat([lng, lat])
      .addTo(map.current)
  } catch (err) {
    toast.error(`${get(err, 'message', err)}`)
    return null
  }
}

export const dragEndMarkerResult = (map, marker) => ({
  isMapChanged: true,
  mapLng: marker.current.getLngLat().lng.toFixed(LNG_LAT_FIX),
  mapLat: marker.current.getLngLat().lat.toFixed(LNG_LAT_FIX),
  mapZoom: map.current.getZoom().toFixed(ZOOM_FIX),
})

export const flyToMap = (map, lng, lat, essential = true) => map.current.flyTo({ center: [lng, lat], essential })

export const easeToMap = (map, lng, lat) => map.current.easeTo({ center: [lng, lat] })
