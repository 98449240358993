import React from 'react'
import styled from 'styled-components'
import { Tooltip } from '@material-ui/core'

const MenuToolTip = styled(props => <Tooltip classes={{ popper: props.className }} {...props} />)`
  & .MuiTooltip-tooltip {
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    margin-top: 8px;
  }
`

export default MenuToolTip
