import React, { useState, useEffect } from 'react'
import { Box, Button, Card, Divider, Typography, useTheme } from '@mui/material'
import { common } from '@mui/material/colors'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { get } from 'utils/lodash'
import { setSelectedLoginDetails } from 'slices/login/LoginPersistSlice'
import { getAllAccounts, getFirstAccountDetails } from 'data/select-account/selectAccount'
import routes from 'router/routes'
import AccountSelectionDropdown from './AccountSelectionDropdown'

const SelectAccount = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const theme = useTheme()
  const [selectedAccountId, setSelectedAccountId] = useState('')
  const accountsPerBusiness = useSelector(state => get(state, 'AuthReducer.userInfo.accountsPerBusiness', []), shallowEqual)
  const existingAccountId = useSelector(state => get(state, 'LoginPersist.selectedLoginDetails.accountId'), shallowEqual)
  const redirectToInvoicePage = useSelector(state => get(state, 'LoginPersist.selectedLoginDetails.redirectToInvoicePage'), shallowEqual)
  const allAccounts = getAllAccounts({ accountsPerBusiness })

  const handleChange = event => setSelectedAccountId(event.target.value)

  const handleClick = () => {
    const currentAccount = allAccounts.find(account => account.id === selectedAccountId)
    dispatch(
      setSelectedLoginDetails({
        show: false,
        businessName: get(currentAccount, 'businessName'),
        businessId: get(currentAccount, 'businessId'),
        accountId: selectedAccountId,
        redirectToInvoicePage: false,
      })
    )
    history.push(redirectToInvoicePage ? routes.app.invoices : routes.app.home)
  }

  useEffect(() => {
    if (existingAccountId) {
      // Select newly registered account
      setSelectedAccountId(existingAccountId)
    } else {
      // Select first account
      const { accountId } = getFirstAccountDetails({ accountsPerBusiness })
      setSelectedAccountId(accountId)
    }
  }, [])

  return (
    <Box bgcolor="background.paper" display="flex" alignItems="center" justifyContent="center" flexDirection="column" height="100vh">
      <Card sx={{ mx: 'auto', p: 4, width: '375px', boxShadow: `0px 1px 8px 0px ${theme.palette.divider}` }}>
        <Typography variant="h4" fontWeight={600}>
          Select an account to sign in
        </Typography>
        <Box mt={3}>
          <AccountSelectionDropdown value={selectedAccountId} onChange={handleChange} />
        </Box>
        <Button
          onClick={handleClick}
          variant="contained"
          sx={{
            mt: 3,
            width: '100%',
            '&:hover': {
              color: common.white,
            },
          }}
        >
          Continue
        </Button>
        <Divider sx={{ mt: 3, mb: 2 }} />
        <Typography textAlign="center" color="textSecondary" variant="h6" fontWeight={500}>
          Powered by Hauler Hero ©
        </Typography>
      </Card>
    </Box>
  )
}

export default SelectAccount
