import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { Modal, Backdrop, Fade } from '@material-ui/core'

import CommonTextfield from 'components/common/CommonTextfield'

import FooterButton from 'components/buttons/FooterButton'

import { isNumberOnly } from 'utils/validations'

import Constants from 'Constants'
import T from 'T'

const { MEDIA_SERVER } = Constants

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    outline: 'none',
  },
}))

const CVVConfirmation = ({ cvv, confirmationModalOpen, closeCVVModal, onCVVChange, handleMakePayment, isProceedLoading = false }) => {
  const classes = useStyles()
  return (
    <>
      <Modal
        className="flex justify-center items-center"
        open={confirmationModalOpen}
        onClose={closeCVVModal}
        disableBackdropClick
        disableEscapeKeyDown
        BackdropComponent={Backdrop}
      >
        <Fade in={confirmationModalOpen}>
          <div className={`${classes.paper} cvv-confirmation-wrapper`}>
            <div className="cvv-upper-section">
              <div className="cvv-title">{T.CVV_TITLE}</div>

              <div className="cvv-section">
                <div className="flex justify-center ml-45">
                  <div className="label">{T.CARD_CVV}</div>

                  <CommonTextfield
                    className="ml2"
                    name="cvv"
                    type="password"
                    placeholder="123"
                    value={cvv}
                    // Add only string or number validation
                    onChange={event => {
                      if (event.target.value === '' || isNumberOnly(event.target.value)) {
                        onCVVChange(event.target.value)
                      }
                    }}
                  />
                </div>
                <img src={`${MEDIA_SERVER}CVV.svg`} alt="" />
              </div>
            </div>

            <div className="footer-section">
              <FooterButton
                leftButtonTitle={T.CANCEL}
                onClose={closeCVVModal}
                rightButtonTitle={T.APPLY}
                onProceed={() => {
                  handleMakePayment()
                  closeCVVModal()
                }}
                // later use
                disabledProceed={cvv === '' || isProceedLoading}
              />
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  )
}

CVVConfirmation.defaultProps = {
  cvv: '',
  confirmationModalOpen: '',
  closeCVVModal: null,
  onCVVChange: null,
  handleMakePayment: null,
}

CVVConfirmation.propTypes = {
  cvv: PropTypes.string,
  confirmationModalOpen: PropTypes.string,
  closeCVVModal: PropTypes.func,
  onCVVChange: PropTypes.func,
  handleMakePayment: PropTypes.func,
  isProceedLoading: PropTypes.bool,
}

export default CVVConfirmation
