import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'

import { Button } from '@material-ui/core'
import { X as Close } from '@styled-icons/heroicons-solid/X'

import Home from 'containers/home'

import T from 'T'
import routes from 'router/routes'

import 'containers/home/Home.scss'
import { get } from 'utils/lodash'

const HomePage = () => {
  const history = useHistory()

  const [showError, setShowError] = useState(true)

  const { paymentError } = useSelector(
    state => ({
      paymentError: state.AuthReducer.paymentError,
    }),
    shallowEqual
  )

  const handlePaymentClick = () => {
    history.push(routes.app.payments)
  }

  return (
    <>
      {paymentError && showError && (
        <div className="failed-payment-prompt flex">
          <div className="wrapper flex">
            <div className="text">
              {T.FAILED_AUTOPAY_MSG}${get(paymentError, 'amount', '0')}
            </div>

            <Button variant="contained" className="update-payment-btn" disableElevation onClick={handlePaymentClick}>
              {T.MAKE_PAYMENT}
            </Button>
          </div>
          <div className="close-icon cursor-pointer" onClick={() => setShowError(false)}>
            <Close />
          </div>
        </div>
      )}

      <section className="common-padding">
        <Home />
      </section>
    </>
  )
}

export default HomePage
