import React from 'react'
import PropTypes from 'prop-types'
import './common.scss'

const PageHeader = ({ title }) => {
  return <h3 className="page-title">{title}</h3>
}

export default PageHeader

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
}
