/* eslint-disable */
export default class T {
  static ROUTE = 'Route'
  static BILLING = 'Billing'
  static REPORTS = 'Reports'
  static PRICING = 'Pricing'
  static FOLLOW_UP = 'Follow up'
  static DASHBOARD = 'Dashboard'
  static CUSTOMERS = 'Customers'
  static MORE_MENU = 'More'
  static RECENT_SEARCHES = 'Recent Searches'
  static ADVANCED_SEARCH = 'Advanced Search'
  static SHOW_ALL = 'show all'
  static SEARCH = 'Search'
  static SHOW_ALL_RESULTS = 'Show all results for'
  static SEARCH_PLACEHOLDER = 'Search...'
  static PRESS_ENTER = 'press enter'
  static NO_SERVICES = 'No services found'

  static RECENTLY = 'Recently'
  static VIEWED = 'Viewed'
  static ADDED = 'Added'
  static UPDATED = 'Updated'
  static NO_CUSTOMERS = 'No customers found'
  static SEARCH_TRY_AGAIN = 'Please try another search'

  static ACCOUNT_NAME = 'Account Name'
  static LOCATIONS = 'Locations'
  static CONTACT_NAME = 'Contact Name'
  static BILLING_ADDRESS = 'Billing Address'
  static BUSINESS_UNIT = 'Business Unit'
  static BUSINESS_LINE = 'Business Line'

  static NO_CONTACTS = 'No contacts found'
  static NO_NOTES = 'No notes found'
  static SERVICE = 'Service'
  static MATERIAL = 'Material'
  static RECURRENCE = 'Recurrence'
  static ACTION = 'Action'
  static METHOD = 'Method'
  static MEASURE = 'Measure'
  static PRICING_ZONE = 'Pricing Zone'
  static PERIOD = 'Period'
  static STATUS = 'Status'
  static VALUE = 'Value'

  static LINK = 'Link'
  static HISTORY = 'History'
  static LOCK = 'Lock'
  static DUPLICATE = 'Duplicate'
  static DEACTIVATE = 'Deactivate'

  static BALANCE = 'Balance'
  static TAGS = 'Tags'

  static CHANGE_PASSWORD = 'Change password'
  static SETTINGS = 'Settings'
  static LOGOUT = 'Log out'
  static PRIVACY_POLICY = 'Privacy policy'

  static CANCEL = 'Cancel'
  static APPLY = 'Apply'

  static ALREADY_HAVE_ACCOUNT = 'Already have an account?'
  static SIGN_IN = 'Sign in'
  static SIGN_UP = 'Sign up'
  static BOTH_PASSWORDS_MATCH = 'Both passwords match'
  static HAULER_HERO = 'Hauler Hero'
  static CONTACT = 'Contact'
  static PRIVACY_TERMS = 'Privacy & terms'
  static EMAIL_ADDRESS = 'Email Address'
  static SIGNUP_FOR_ACCOUNT = 'Sign up for an account'
  static ACCOUNT_NUMBER = 'Account Number'
  static BILLING_ZIP_CODE = 'Billing Zip Code'
  static PASSWORD_LENGTH_MSG = 'Your password must be at least 8 characters long.'
  static THANK_YOU_FOR_SIGNING_UP = 'Thank you for signing up!'
  static NEW_PWD_DO_NOT_MATCH = 'The new passwords do not match. Please try again.'
  static CONFIRM_ACCOUNT = 'Confirm Account'
  static CREATE_ACCOUNT = 'Create Account'
  static RENTER_PASSWORD = 'Re-enter password'
  static CURRENT_PASSWORD = 'Current password'
  static NEW_PASSWORD = 'New password'
  static PASSWORD = 'Password'
  static SET_UP_YOUR_ACCOUNT = 'Set up your account'
  static PLEASE_CHECK_EMAIL_TO_VERIFY = 'Please check your email to verify your account.'
  static CURRENT_PWD_INCORRECT = 'The current password you entered is incorrect. Please try again.'
  static OLD_PWD = 'Old Password'
  static ALL_FIELDS_MANDATORY = 'All fields are mandatory'
  static PWD_CHANGED_SUCCESSFULLY = 'Your password has been successfully changed!'
  static ENTER_OLD_PWD = 'Enter old password'
  static ENTER_NEW_PWD = 'Enter new password'
  static RENTER_NEW_PWD = 'Re-enter new password'
  static SAVE_CHANGES = 'Save Changes'
  static RESET_PASSWORD = 'Reset Password'
  static RESET_MY_PASSWORD = 'Reset My Password'
  static ALREADY_HAVE_AN_ACCOUNT = 'Already have an account?'
  static STORED_PAYMENT_METHOD = 'Stored Payment Method'
  static ADD_PAYMENT_METHOD = 'Add payment method'
  static INVOICE_DATE = 'Invoice Date'
  static DUE_DATE = 'Due Date'
  static AUTOPAY_DATE = 'AutoPay Date'
  static SELECTED_METHOD = 'Selected Method'
  static INVOICE = 'Invoice'
  static PAYMENT_TERMS = 'Payment Terms'
  static AUTOPAY_TIMING = 'AutoPay Timing'
  static IMMEDIATE = 'Immediate'
  static PLEASE_AGREE_TO_TERMS = 'Please agree to the Terms & Conditions'
  static TERMS_N_CONDITIONS = 'Terms & Conditions'
  static AUTOPAY_ENROLMENT_MSG = 'By enrolling in AutoPay payments, I agree to the '

  static ROUTING_N_ACCOUNT_NUMBER = 'Routing Number / Account Number'
  static CARDHOLDER_NAME = 'Cardholder Name'
  static NAME_ON_ACCOUNT = 'Name on Account'
  static BANK_ACCOUNT_TYPE = 'Bank Account Type'
  static CHECKING = 'Checking'
  static SAVING = 'Saving'
  static METHOD_NICKNAME = 'Method Nickname'
  static CURRENT_AMOUNT_DUE = 'Current Amount Due'
  static NEXT_AMOUNT_DUE = 'Next Payment Due'
  static AUTOPAY = 'AutoPay'
  static SAVE = 'Save'
  static MAKE_A_PAYMENT = 'Make a Payment'
  static SAVE_METHOD = 'Save Method'
  static OOPS_PAYMENT_METHOD_ERR = 'Oops! Seems like there was an error in your payment method.'
  static PAYMENT_PROCESS_MSG = 'Your payment has been processed and completed successfully. We appreciate your business.'

  static PAYMENT_AMOUNT = 'Payment Amount'
  static LAST_INVOICE = 'Last Invoice'
  static OTHER_AMOUNT = 'Other Amount'
  static EDIT = 'Edit'
  static REMOVE = 'Remove'
  static OTHER_PAYMENT_METHOD = 'Other Payment Method'
  static PAYMENT_HISTORY = 'Payment History'
  static DATE = 'Date'
  static PAYMENT_SOURCE = 'Payment Source'
  static AMOUNT = 'Amount'
  static FIRSTNAME = 'First Name'
  static LASTNAME = 'Last Name'
  static CVV = 'CVV'

  static PAYMENT_METHOD = 'Payment Method'
  static SIGNUP_ERROR_MSG = 'Some of the information you provided is either incorrect or not in our system. Please check and try again'

  static PAYMENT_SUCCESS_TOP_MSG = 'Thank you so much for your'
  static PAYMENT_FAILED_TOP_MSG = 'Oops! Seems like there was an error in your payment method'
  static PAYMENT_PENDING_TOP_MSG = 'We are processing your'
  static PAYMENT_SUCCESS_BOTTOM_MSG = 'Your payment has been processed and completed successfully. We appreciate your business.'
  static PAYMENT_FAILED_BOTTOM_MSG = 'Please check for any errors and try again'
  static PAYMENT_PENDING_BOTTOM_MSG = 'This process may take a few seconds.'
  static THANK_YOU_FOR_PATIENCE = 'Thank you for your patience.'
  static ACCOUNTING_NUMBER_MSG = 'Enter routing number, slash, account number'
  static CVV_TITLE = 'Please type your CVV below to verify payment method'
  static CARD_CVV = 'Card CVV'
  static UPDATE_PAYMENT_METHOD = 'Update Payment Method'
  static FAILED_AUTOPAY_MSG = 'Last AutoPay Payment Failed. Outstanding Balance: '
  static NOTE = 'Note'

  static TRANSACTION_SURCHARGE_NOTE = 'Transactions will incur a surcharge where applicable.'
  static TRANSACTION_SURCHARGE_DESCRIPTION =
    'We impose a surcharge on the transaction amount on some credit card products, which is not greater than our cost of acceptance. We do not surcharge debit cards.'

  /* Home page sections */
  static PAYMENT = 'Payment'
  static NEXT_PAYMENT = 'Next Payment'
  static MAKE_PAYMENT = 'Make Payment'
  static AUTO = 'Auto'
  static NEXT_PAYMENT_MESSAGE_AUTO = 'Your next payment will process on'
  static NEXT_PAYMENT_MESSAGE = 'Your next payment is due on'
  static LAST_PAYMENT_MESSAGE = 'Your last payment was'
  static MAKE_PAYMENT_BUTTON = 'Make Payment'
  static MANAGE_AUTO_PAY_BUTTON = 'Manage AutoPay'
  static PAYMENT_THANK_YOU = 'Thank you for your payment!'
  static LAST_PAYMENT_WAS = 'Your last payments was '
  static NEXT_PAYMENT_DUE_MESSAGE = 'Payment is due by '
  static LATE_PAYMENT_MESSAGE = (dueDate, lateFee) => `Your payment was due ${dueDate}. Late fees of ${lateFee}
                                         have been applied to your account. Please make a payment to bring your account current.`
  static FAILED = 'Failed'
  static TRANSACTION_DATE = 'Transaction date'
  static POSTED = 'Posted'
  static TRANSFER = 'Transfer'
  static REVERSED = 'Reversed'
  static BILLING_LOCATION_NAME = 'Billing location name'
  static STREET_LINE_2 = 'Street line 2'
  static STREET = 'Street'
  static CITY = 'City'
  static STATE = 'State'
  static ZIP_CODE = 'Zip Code'
  static SEARCH_ADDRESS_PLACEHOLDER = 'Start typing an address to search'
  static STREET_ADDRESS = 'Street address'
  static EDIT_BILLING_LOCATION = 'Edit billing location'
  static ADDRESS = 'Address'
  static ADDRESS_NAME = 'Address name'
  static ATTENTION = 'Attention (ATTN:)'
  static USER_CANNOT_BE_FOUND = 'User cannot be found. Please reach out to your hauler for assistance.'
  static PASSWORD_RESET_ERROR = 'Password cannot be reset. Please reach out to your hauler for assistance.'
  static ACCOUNT_CREATE_ERROR = 'Account cannot be created. Please reach out to your hauler for assistance.'
  static CANNOT_BE_EMPTY = 'Cannot be empty'
  static PASSWORD_CHANGED_SUCCESSFULLY = 'Password changed successfully'
  static PASSWORD_CHANGE_ERROR = 'Password cannot be changed. Please reach out to your hauler for assistance.'
  static WEAK_PASSWORD_ERROR = 'The new password is weak, please create a strong password.'
  static UNEXPECTED_ERROR = 'There was an unexpected error. If the problem persists, please reach out to your hauler for assistance.'
  static EMAIL = 'Email'
  static RECOVER = 'Recover'
  static MUST_BE_A_VALID_EMAIL_ADDRESS = 'Must be a valid email address'
  static DONE = 'Done'
  static ACCOUNT_NUMBER_DOESNT_EXIST = "Account number doesn't exist"
}
