import { get } from 'lodash'
import { createSelector } from 'reselect'

export const isSingleAccount = createSelector([state => state?.accountsPerBusiness], (accountsPerBusiness = []) => {
  return accountsPerBusiness.length === 1 && get(accountsPerBusiness, '[0].accounts').length === 1
})

export const getFirstAccountDetails = createSelector([state => state?.accountsPerBusiness], (accountsPerBusiness = []) => {
  return {
    businessName: get(accountsPerBusiness, '[0].business.businessName'),
    businessId: get(accountsPerBusiness, '[0].business.id'),
    accountId: get(accountsPerBusiness, '[0].accounts.[0].id'),
  }
})

export const getAllAccounts = createSelector([state => state?.accountsPerBusiness], (accountsPerBusiness = []) => {
  return accountsPerBusiness
    .map(row =>
      get(row, 'accounts', []).map(account => ({
        businessName: row.business.businessName,
        businessId: row.business.id,
        businessTitle: row.business.title,
        ...account,
      }))
    )
    .flat()
})
