import React, { Children } from 'react'
import PropTypes from 'prop-types'

import Select from '@material-ui/core/Select'
import InputBase from '@material-ui/core/InputBase'
import FormControl from '@material-ui/core/FormControl'
import withStyles from '@material-ui/core/styles/withStyles'
import MenuItem from '@material-ui/core/MenuItem'

import { get } from 'utils/lodash'

import Constants from 'Constants'

const { MEDIA_SERVER } = Constants

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.08)',
    border: '1px solid #DFE1E6',
    fontSize: 13,
    fontWeight: 500,
    lineHeight: '24px',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      backgroundColor: '#fff',
    },
  },
}))(InputBase)

const CommonSelect = ({
  children,
  value,
  placeholder,
  placeholderHidden,
  placeholderDisabled,
  showEmpty,
  emptyText,
  showTick,
  options,
  disabled,
  className,
  onChange,
}) => (
  <FormControl className={`common select ${!value ? 'placeholder-selected' : ''} ${className}-container`}>
    <Select
      displayEmpty
      disabled={disabled}
      value={value}
      onChange={onChange}
      input={<BootstrapInput />}
      className="select-class"
      MenuProps={{ className: `${className}-dropdown common-select-dropdown` }}
    >
      {placeholder && (
        <MenuItem value="" className={`${className}-menu-item ${placeholderHidden ? 'hide' : ''}`} disabled={placeholderDisabled}>
          {placeholder}
        </MenuItem>
      )}
      {children ||
        Children.toArray(
          options.map(option => (
            <MenuItem value={get(option, 'key', option)} className={`${className}-menu-item`}>
              <span className="text">{get(option, 'value', option)}</span>
              <span className={`tick-icon dn ${showTick ? 'showtick' : 'hide-tick'}`}>
                <img src={`${MEDIA_SERVER}Tick.svg`} alt="" />
              </span>
            </MenuItem>
          ))
        )}

      {showEmpty && (
        <MenuItem value="-1" className={`${className}-menu-item`} disabled>
          {emptyText}
        </MenuItem>
      )}
    </Select>
  </FormControl>
)

CommonSelect.defaultProps = {
  value: null,
  placeholder: null,
  placeholderHidden: false,
  placeholderDisabled: false,
  showEmpty: false,
  emptyText: '',
  showTick: false,
  options: [],
  disabled: false,
  className: '',
  children: null,
}

CommonSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholderHidden: PropTypes.bool,
  placeholderDisabled: PropTypes.bool,
  showEmpty: PropTypes.bool,
  emptyText: PropTypes.string,
  showTick: PropTypes.bool,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default CommonSelect
