import { actions } from '../actions/user'

const {
  PUT: { APP_SETTINGS },
} = actions

const initialState = {
  appSettings: null,
}

const UserReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case APP_SETTINGS:
      return { ...state, appSettings: action.payload }

    default:
      return state
  }
}

export default UserReducer
