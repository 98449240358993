import React from 'react'
import PropTypes from 'prop-types'
import { ListItemAvatar, ListItemText } from '@mui/material'
import Constants from 'Constants'

const { PUBLIC_ASSETS_BUCKET_NAME, STORAGE_PATH } = Constants

const SelectItemContent = ({ businessId, accountName, accuntNumber, businessTitle }) => {
  return (
    <>
      <ListItemAvatar
        sx={{
          display: 'flex',
          alignItems: 'center',
          minWidth: 60,
          width: 60,
          height: 40,
        }}
      >
        <img src={`${STORAGE_PATH}${PUBLIC_ASSETS_BUCKET_NAME}/${businessId}/business_logo.png`} alt="" />
      </ListItemAvatar>
      <ListItemText
        sx={{ ml: 1 }}
        primaryTypographyProps={{ variant: 'h6', fontWeight: 400 }}
        primary={accuntNumber}
        // secondaryTypographyProps={{ className: 'business-name', noWrap: true, maxWidth: 200 }}
        secondaryTypographyProps={{ className: 'business-name' }}
        secondary={businessTitle}
      />
    </>
  )
}

SelectItemContent.propTypes = {
  businessId: PropTypes.string,
  accountName: PropTypes.string,
  accuntNumber: PropTypes.string,
  businessTitle: PropTypes.string,
}

export default SelectItemContent
