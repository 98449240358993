import createSagaMiddleware from 'redux-saga'
import storage from 'redux-persist/lib/storage'

import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'

import rootReducer from 'middleware/reducers/index'
import api from 'api'
import { rtkQueryErrorLogger } from 'api/errorLogger'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['AuthReducer', 'SettingsReducer', 'UserReducer', 'LoginPersist'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  reducer: persistedReducer,
  middleware: defaultMiddleware => [
    ...defaultMiddleware({
      serializableCheck: false,
    }),
    sagaMiddleware,
    rtkQueryErrorLogger,
    api.middleware,
  ],
  devTools: process.env.NODE_ENV !== 'production',
})
const persistor = persistStore(store)

export { store, persistor, sagaMiddleware }
