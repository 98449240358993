import React from 'react'
import PropTypes from 'prop-types'
import { ListItem, ListItemIcon, ListItemText, Grid, Typography, ListItemSecondaryAction, Divider, makeStyles } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import Box from '@mui/material/Box'
import capitalize from 'lodash/capitalize'
import PaymentStatusChip, { PAYMENT_STATUS_COLORS } from '../../components/chip/PaymentStatusChip'
import HHDisplayMoney from '../../components/HHDisplayMoney'
import T from '../../T'
import { TEXT } from '../../theme/colors'

const useStyles = makeStyles({
  paymentStatus: { minWidth: '130px' },
  methodIconContainer: { height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' },
  methodIcon: { marginRight: 16, height: '20px' },
  h3: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '28px',
  },
  caption: {
    fontSize: 11,
    fontWeight: 400,
    lineHeight: '14px',
    color: TEXT.secondary,
  },
  paymentItemContainer: {
    marginTop: '12px',
    marginBottom: '12px',
  },
  postedText: {
    color: ({ color }) => color,
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
})
const PaymentListItem = ({
  accountNumber = '',
  accountType = '',
  capitalizeTransactionStatus = '',
  date = '',
  isAccountCredit = false,
  isCheckOther = false,
  isNotLast = false,
  paymentMethodImage = '',
  paymentMethodName = '',
  transactionAmountCents = '',
  checkValue = '',
  paymentNumber,
  onClick,
}) => {
  const colorMap = capitalizeTransactionStatus in PAYMENT_STATUS_COLORS ? PAYMENT_STATUS_COLORS[capitalizeTransactionStatus] : red
  const classes = useStyles({ color: colorMap[600] })
  const secondaryText = (isCheckOther || isAccountCredit) && checkValue
  return (
    <>
      <Box className={classes.paymentItemContainer} onClick={onClick} sx={{ cursor: paymentNumber ? 'pointer' : 'default' }}>
        <ListItem classes={{ root: classes.listItem }} disablePadding>
          <ListItemIcon className={classes.paymentStatus}>
            {capitalizeTransactionStatus && <PaymentStatusChip status={capitalizeTransactionStatus} />}
          </ListItemIcon>
          <ListItemText
            primary={
              <Grid container>
                <Grid item>
                  <Box className={classes.methodIconContainer}>
                    <img className={classes.methodIcon} src={paymentMethodImage} alt="" />
                  </Box>
                </Grid>
                <Grid item xs>
                  <Box display="flex" flexDirection="column">
                    <Typography>{accountType && accountNumber ? `${accountType} •••• ${accountNumber}` : paymentMethodName}</Typography>
                    {secondaryText && <Typography className={classes.caption}>{secondaryText}</Typography>}
                  </Box>
                </Grid>
              </Grid>
            }
          />
          <ListItemSecondaryAction>
            <Typography className={classes.h3} display="flex" alignItems="center" justifyContent="flex-end" fontWeight={700}>
              <HHDisplayMoney value={transactionAmountCents} />
            </Typography>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem classes={{ root: classes.listItem }} disablePadding>
          <ListItemText
            primary={
              <Typography className={classes.postedText} variant="caption" fontWeight={700}>
                {`${T.POSTED}: ${date}`}
              </Typography>
            }
          />
          <ListItemSecondaryAction>
            <Typography className={classes.caption} display="flex" alignItems="center" justifyContent="flex-end" variant="caption">
              {`${capitalize(T.TRANSACTION_DATE)}: `}
              {date}
            </Typography>
          </ListItemSecondaryAction>
        </ListItem>
      </Box>
      {isNotLast && <Divider />}
    </>
  )
}

PaymentListItem.propTypes = {
  accountNumber: PropTypes.string,
  accountType: PropTypes.string,
  capitalizeTransactionStatus: PropTypes.string,
  date: PropTypes.string,
  isAccountCredit: PropTypes.bool,
  isCheckOther: PropTypes.bool,
  isNotLast: PropTypes.bool,
  paymentMethodImage: PropTypes.string,
  paymentMethodName: PropTypes.string,
  transactionAmountCents: PropTypes.string,
  checkValue: PropTypes.string,
  paymentNumber: PropTypes.number,
  onClick: PropTypes.func.isRequired,
}

export default PaymentListItem
