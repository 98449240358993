const APP_PREFIX = '/app'

const routes = {
  app: {
    error: `${APP_PREFIX}/error`,
    login: `${APP_PREFIX}/login`,
    selectAccount: `${APP_PREFIX}/select-account`,
    signup: `${APP_PREFIX}/signup`,
    setupAccount: `${APP_PREFIX}/setup-account`,
    forgotPassword: `${APP_PREFIX}/forgot-password`,
    changePassword: `${APP_PREFIX}/change-password`,
    home: `${APP_PREFIX}/home`,
    invoices: `${APP_PREFIX}/invoices`,
    payments: `${APP_PREFIX}/payments`,
    settings: `${APP_PREFIX}/settings`,
  },
}

export default routes

const { app } = routes
const { login } = app

export const isPublicURL = route => [login].includes(route)
