import api from 'api'
import Constants from 'Constants'

const { SERVER_PUBLIC_URL } = Constants

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    registerUser: builder.mutation({
      query: body => ({
        method: 'POST',
        url: `${SERVER_PUBLIC_URL}/api/v1/portal-user/register`,
        body,
      }),
    }),
  }),
})

export const { useRegisterUserMutation } = extendedApi
