import React from 'react'
import PropTypes from 'prop-types'
import Switch from 'react-switch'

import { useSelector, shallowEqual } from 'react-redux'

import './common.scss'

const CommonSwitch = ({ title, titlePosition, className, isChecked, offColor, onColor, onChange }) => {
  const { appSettings } = useSelector(
    store => ({
      appSettings: store.UserReducer.appSettings,
    }),
    shallowEqual
  )

  return (
    <label className={`common switch flex items-center form cursor-pointer ${className}`}>
      {titlePosition === 'left' && <span>{title}</span>}
      <Switch
        offColor={offColor}
        onColor={appSettings?.actionButtonColor || onColor}
        width={32}
        height={16}
        handleDiameter={10}
        className="inner-switch"
        checked={isChecked}
        onChange={onChange}
      />
      {titlePosition === 'right' && <span className="autopay-label">{title}</span>}
    </label>
  )
}

CommonSwitch.defaultProps = {
  title: null,
  titlePosition: 'right',
  className: '',
  isChecked: false,
  offColor: '#7A869A',
  onColor: '#00875A',
}

CommonSwitch.propTypes = {
  title: PropTypes.string,
  titlePosition: PropTypes.string,
  className: PropTypes.string,
  isChecked: PropTypes.bool,
  offColor: PropTypes.string,
  onColor: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default CommonSwitch
