export const actions = {
  GET: {
    HOME_PAGE_PAYMENTS: 'GET_HOME_PAGE_PAYMENTS',
    UPDATE_AUTOPAY: 'GET_UPDATE_AUTOPAY',
    IS_SURCHARGE_APPLIED: 'IS_SURCHARGE_APPLIED',
  },
  PUT: {
    HOME_PAGE_PAYMENTS: 'PUT_HOME_PAGE_PAYMENTS',
    UPDATE_AUTOPAY: 'PUT_UPDATE_AUTOPAY',
  },
}

export const putHomePagePayments = res => ({
  type: actions.PUT.HOME_PAGE_PAYMENTS,
  payload: res,
})

export const putUpdateAutopay = res => ({
  type: actions.PUT.UPDATE_AUTOPAY,
  payload: res,
})

export const getHomePagePayments = payload => ({
  type: actions.GET.HOME_PAGE_PAYMENTS,
  payload,
})

export const getUpdateAutopay = (businessId, accountId, body, onSuccess) => ({
  type: actions.GET.UPDATE_AUTOPAY,
  payload: { businessId, accountId, body, onSuccess },
})

export const getIsSurchargeApplied = (url, businessId, onSuccess) => ({
  type: actions.GET.IS_SURCHARGE_APPLIED,
  payload: { url, businessId, onSuccess },
})
