import React from 'react'
import PropTypes from 'prop-types'

import TextField from '@material-ui/core/TextField'

import './style.scss'

const CommonTextfield = ({
  id,
  type,
  name,
  rows,
  value,
  variant,
  onChange,
  onFocus,
  onBlur,
  tabIndex,
  disabled,
  required,
  autoFocus,
  fullWidth,
  className,
  multiline,
  onKeyPress,
  placeholder,
  defaultValue,
  refVar,
  startAdornment,
}) => {
  if (value && defaultValue) {
    // console will not crash the app, using throw error method will crash it
    console.error('Value & default value cannot present together')
  }

  return (
    <div className={`${className} common textfield`}>
      <TextField
        inputRef={refVar}
        autoComplete="nope"
        InputProps={{
          'aria-label': name,
          tabIndex,
          startAdornment,
        }}
        id={id}
        rows={rows}
        name={name}
        type={type}
        value={value}
        disabled={disabled}
        variant={variant}
        required={required}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        multiline={multiline}
        autoFocus={autoFocus}
        fullWidth={fullWidth}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        defaultValue={defaultValue}
      />
    </div>
  )
}

CommonTextfield.defaultProps = {
  rows: 2,
  id: null,
  name: null,
  value: null,
  type: 'text',
  tabIndex: '',
  className: '',
  required: null,
  autoFocus: null,
  disabled: false,
  onKeyPress: null,
  fullWidth: false,
  multiline: false,
  placeholder: null,
  defaultValue: null,
  variant: 'standard',
  onFocus: null,
  onBlur: null,
  refVar: null,
  startAdornment: null,
}

CommonTextfield.propTypes = {
  refVar: PropTypes.any,
  id: PropTypes.string,
  type: PropTypes.string,
  rows: PropTypes.number,
  name: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  autoFocus: PropTypes.bool,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  onKeyPress: PropTypes.func,
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  startAdornment: PropTypes.any,
}

export default CommonTextfield
