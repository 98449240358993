import React from 'react'

import PageHeader from 'components/common/PageHeader'
import Payments from 'containers/payments'

const PaymentsPage = () => (
  <section className="common-padding page-bg">
    <div className="invoice-heading">
      <PageHeader title="Payments" />
    </div>
    <Payments />
  </section>
)

export default PaymentsPage
