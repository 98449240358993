import React from 'react'
import PropTypes from 'prop-types'

import T from 'T'
import { Avatar, Chip, makeStyles } from '@material-ui/core'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import { PAYMENT_STATUS } from 'settings/constants/payment'
import { green, red, orange, purple } from '@material-ui/core/colors'

const { APPROVED, REVERSED, DECLINED, TRANSFERRED, FAILED } = PAYMENT_STATUS

export const PAYMENT_STATUS_COLORS = {
  [APPROVED]: green,
  [REVERSED]: orange,
  [TRANSFERRED]: purple,
  [DECLINED]: red,
  [FAILED]: red,
}

const useStyles = makeStyles(() => ({
  avatar: { backgroundColor: ({ color }) => color[300] },
  icon: { width: 12, height: 12 },
  chip: {
    fontWeight: 600,
    border: '1px solid',
    borderColor: ({ color }) => color[500],
    backgroundColor: ({ color }) => color[50],
    color: ({ color }) => color[700],
  },
}))

const PaymentStatusChip = ({ status = '' }) => {
  const getParams = () => {
    if (status === APPROVED) {
      return { label: 'Complete', color: PAYMENT_STATUS_COLORS[APPROVED] }
    }

    if (status === REVERSED) {
      return { label: T.REVERSED, color: PAYMENT_STATUS_COLORS[REVERSED] }
    }

    if (status === TRANSFERRED) {
      return { label: T.TRANSFER, color: PAYMENT_STATUS_COLORS[TRANSFERRED] }
    }

    if (status === DECLINED) {
      return { label: T.FAILED, color: PAYMENT_STATUS_COLORS[DECLINED] }
    }

    if (status === FAILED) {
      return { label: T.FAILED, color: PAYMENT_STATUS_COLORS[FAILED] }
    }

    return { label: status, color: PAYMENT_STATUS_COLORS[DECLINED] }
  }

  const { label, color } = getParams()
  const classes = useStyles({ color })

  return (
    <Chip
      className={classes.chip}
      size="small"
      avatar={
        <Avatar className={classes.avatar}>
          <AttachMoneyIcon className={classes.icon} />
        </Avatar>
      }
      label={label}
    />
  )
}

PaymentStatusChip.propTypes = {
  status: PropTypes.string,
}

export default PaymentStatusChip
