import { combineReducers } from 'redux'
import api from 'api'
import AuthReducer from './AuthReducer'
import ConfiguredServicesReducer from './ConfiguredServicesReducer'
import InvoiceReducer from './InvoiceReducer'
import PaymentsReducer from './PaymentsReducer'
import ResponseReducer from './ResponseReducer'
import SettingsReducer from './SettingsReducer'
import SignupReducer from './SignupReducer'
import UserReducer from './UserReducer'
import LoginPersist from '../../slices/login/LoginPersistSlice'
import Appbar from '../../slices/appbar/appbarSlice'

const appReducer = combineReducers({
  AuthReducer,
  ConfiguredServicesReducer,
  InvoiceReducer,
  PaymentsReducer,
  ResponseReducer,
  SettingsReducer,
  SignupReducer,
  UserReducer,
  LoginPersist,
  Appbar,
  [api.reducerPath]: api.reducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export default rootReducer
