import isEmpty from 'lodash/isEmpty'

import { get } from './lodash'
import { MAP_DEFAULT_OPTIONS } from '../settings/constants/map'

const { LNG_LAT_FIX } = MAP_DEFAULT_OPTIONS

export const combineAddressInfo = addressObj => {
  if (!addressObj || isEmpty(addressObj)) {
    // Check for empty conditions
    return ''
  }

  const line1 = get(addressObj, 'line1', '')
  const city = get(addressObj, 'city', '')
  const state = get(addressObj, 'state', '')
  const zipCode = get(addressObj, 'zipCode', '')
  const stateAndZipCode = `${state} ${zipCode}`.trim()
  const unitNumber = get(addressObj, 'unitNumber', '')

  // Join address
  return [line1, unitNumber, city, stateAndZipCode].filter(Boolean).join(', ')
}

export const handleReducer = (prevState, updatedState) => ({
  ...prevState,
  ...updatedState,
})

export const getAddressParams = address => {
  const streetNumber = get(address, 'streetNumber', '')
  const streetName = get(address, 'streetName', '')
  const line1 = `${streetNumber} ${streetName}`.trim()
  const city = get(address, 'city', '')
  const stateShortCode = get(address, 'stateShortCode', '').split('-')
  const state = get(stateShortCode, '[1]', '')
  const zipCode = get(address, 'zipCode', '')
  const country = get(address, 'country', '')
  const longitude = get(address, 'center.longitude', '')
  const latitude = get(address, 'center.latitude', '')
  const longitudeFixed = longitude ? longitude.toFixed(LNG_LAT_FIX) : ''
  const latitudeFixed = latitude ? latitude.toFixed(LNG_LAT_FIX) : ''
  return { line1, city, state, zipCode, country, longitudeFixed, latitudeFixed }
}

export const splitAddressLine1 = line1 => {
  const result = {
    number: null,
    streetName: null,
  }

  const parts = line1.split(' ')

  for (let i = 0; i < parts.length; i++) {
    const currentPart = parts[i]

    if (!Number.isNaN(currentPart)) {
      result.number = currentPart
      result.streetName = parts.slice(i + 1).join(' ')
      break
    }
  }

  return result
}
