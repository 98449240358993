import React from 'react'
import { Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import T from 'T'
import HHFormTextField from '../form-fields/v5/HHFormTextField'
import HHFormAddressAutocompleteField from '../form-fields/v5/HHFormAddressAutocompleteField'

const AddressFormFields = ({
  addressNameLabel = T.BILLING_LOCATION_NAME,
  showAddressNameField = false,
  onAddressChange = noop,
  onInputAddressChange = noop,
}) => {
  const { control, setValue } = useFormContext()
  const handleAddressChange = (e, addressObj, reason) => {
    if (reason === 'clear') {
      setValue('line1', '')
    } else {
      const { line1, city, state, zipCode, country, longitudeFixed, latitudeFixed } = addressObj
      setValue('line1', line1)
      setValue('city', city)
      setValue('state', state)
      setValue('zipCode', zipCode)
      setValue('country', country)
      setValue('latitude', longitudeFixed)
      setValue('longitude', latitudeFixed)
      onAddressChange(e, addressObj, reason)
    }
  }

  const handleInputAddressChange = (e, newInputValue, reason) => {
    if (reason === 'reset') return
    setValue('line1', newInputValue)
    onInputAddressChange(e, newInputValue, reason)
  }

  return (
    <Grid container direction="column" spacing={2}>
      {showAddressNameField && (
        <Grid item sm>
          <HHFormTextField deprecatedLabel={false} label={addressNameLabel} fullWidth control={control} name="addressName" />
        </Grid>
      )}
      <Grid item sm>
        <HHFormAddressAutocompleteField
          disableClearable={false}
          clearOnBlur={false}
          label={T.STREET}
          deprecatedLabel={false}
          includeInputInList
          name="address"
          control={control}
          onChange={handleAddressChange}
          onInputChange={handleInputAddressChange}
        />
      </Grid>
      <Grid item sm>
        <HHFormTextField deprecatedLabel={false} label={T.STREET_LINE_2} control={control} name="unitNumber" fullWidth />
      </Grid>
      <Grid item container sm spacing={2}>
        <Grid xs={12} item sm>
          <HHFormTextField deprecatedLabel={false} label={T.CITY} fullWidth control={control} name="city" />
        </Grid>
        <Grid xs={12} item sm={3}>
          <HHFormTextField deprecatedLabel={false} label={T.STATE} fullWidth control={control} name="state" />
        </Grid>
        <Grid item xs={12} sm={3}>
          <HHFormTextField deprecatedLabel={false} label={T.ZIP_CODE} fullWidth control={control} name="zipCode" />
        </Grid>
      </Grid>
    </Grid>
  )
}

AddressFormFields.propTypes = {
  onAddressChange: PropTypes.func,
  onInputAddressChange: PropTypes.func,
  showAddressNameField: PropTypes.bool,
  addressNameLabel: PropTypes.string,
}

export default AddressFormFields
