import React, { useState } from 'react'

import { useDispatch } from 'react-redux'
import { useForm, FormProvider } from 'react-hook-form'
import { Button } from '@material-ui/core'
import { Box, Card, CardContent, CardActions, Typography, Grid, Alert } from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'

import { get } from 'utils/lodash'
import { putIsLoading } from 'middleware/actions/response'
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, updatePassword } from 'firebase/auth'

import T from 'T'
import HHFormTextField from 'components/form-fields/v5/HHFormTextField'
import { firebaseErrorHandler } from 'utils/error'

const Settings = () => {
  const auth = getAuth()
  const currentUser = auth?.currentUser
  const dispatch = useDispatch()
  const [response, setResponse] = useState({ type: '', message: '' })
  const changePasswordForm = useForm({ defaultValues: { password: '', newPassword: '', confirmPassword: '' } })
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = changePasswordForm

  const passwordHelperText = get(errors, 'password.message')
  const newPasswordHelperText = get(errors, 'newPassword.message')
  const confirmPasswordHelperText = get(errors, 'confirmPassword.message')

  const onSubmitHandler = async data => {
    dispatch(putIsLoading(true))
    setResponse({ type: '', message: '' })
    auth.tenantId = currentUser.tenantId
    const credentials = EmailAuthProvider.credential(currentUser.email, data.password)
    reauthenticateWithCredential(currentUser, credentials)
      .then(() => {
        updatePassword(currentUser, data.newPassword)
          .then(() => {
            setResponse({ type: 'success', message: T.PASSWORD_CHANGED_SUCCESSFULLY })
            reset()
          })
          .catch(error => setResponse({ type: 'error', message: firebaseErrorHandler(error) }))
          .finally(() => dispatch(putIsLoading(false)))
      })
      .catch(error => {
        setResponse({ type: 'error', message: firebaseErrorHandler(error) })
        dispatch(putIsLoading(false))
      })
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={4} md={2}>
        <Box display="flex" alignItems="center" gap={1}>
          <LockOutlinedIcon />
          <Typography variant="h5" fontWeight={500}>
            {T.PASSWORD}
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12} sm={8} md={10}>
        <Card sx={{ maxWidth: 640 }}>
          <CardContent>
            {response.message && (
              <Alert sx={{ mb: 2, display: 'flex', alignItems: 'center' }} severity={response.type}>
                {response.message}
              </Alert>
            )}
            <FormProvider {...changePasswordForm}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography width="45%" variant="h6" fontWeight={400}>
                  {T.CURRENT_PASSWORD}
                </Typography>
                <HHFormTextField
                  type="password"
                  error={passwordHelperText}
                  helperText={passwordHelperText}
                  control={control}
                  rules={{ required: T.CANNOT_BE_EMPTY }}
                  name="password"
                  placeholder={T.ENTER_OLD_PWD}
                  fullWidth
                />
              </Box>
              <Box mt={2} display="flex" alignItems="center" justifyContent="space-between">
                <Typography width="45%" variant="h6" fontWeight={400}>
                  {T.NEW_PASSWORD}
                </Typography>
                <HHFormTextField
                  type="password"
                  error={newPasswordHelperText}
                  helperText={newPasswordHelperText}
                  control={control}
                  rules={{ required: T.CANNOT_BE_EMPTY, minLength: { value: 8, message: T.PASSWORD_LENGTH_MSG } }}
                  name="newPassword"
                  placeholder={T.ENTER_NEW_PWD}
                  fullWidth
                />
              </Box>
              <Box mt={2} display="flex" alignItems="center" justifyContent="space-between">
                <Typography width="45%" variant="h6" fontWeight={400}>
                  {T.RENTER_NEW_PWD}
                </Typography>
                <HHFormTextField
                  type="password"
                  error={confirmPasswordHelperText}
                  helperText={confirmPasswordHelperText}
                  control={control}
                  rules={{
                    required: T.CANNOT_BE_EMPTY,
                    validate: value => watch('newPassword') === value || T.NEW_PWD_DO_NOT_MATCH,
                  }}
                  name="confirmPassword"
                  placeholder={T.RENTER_NEW_PWD}
                  fullWidth
                />
              </Box>
            </FormProvider>
          </CardContent>
          <CardActions sx={{ p: 2, justifyContent: 'flex-end' }}>
            <Button variant="contained" onClick={handleSubmit(onSubmitHandler)}>
              {T.CHANGE_PASSWORD}
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  )
}

export default Settings
