import React, { useEffect } from 'react'

import { useDispatch, shallowEqual, useSelector } from 'react-redux'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import User from 'components/user'

import { get } from 'utils/lodash'
import { colors } from 'utils/constants'
import { getAppSettings } from 'middleware/actions/user'

import BusinessLogo from 'components/common/BusinessLogo'

import NavigationMenu from './NavigationMenu'

import './Style.scss'

const Header = () => {
  const dispatch = useDispatch()
  const { userInfo, appSettings } = useSelector(
    state => ({
      userInfo: state.AuthReducer.userInfo,
      appSettings: state.UserReducer.appSettings,
    }),
    shallowEqual
  )
  const businessId = useSelector(state => get(state, 'LoginPersist.selectedLoginDetails.businessId'), shallowEqual)

  useEffect(() => {
    if (!appSettings) return

    const root = document.documentElement
    root.style.setProperty('--nav-bg-color', appSettings?.mainColor)
    root.style.setProperty('--nav-text-color', appSettings?.navigationFontColor)
    root.style.setProperty('--primary-text-color', appSettings?.secondaryColor)
    root.style.setProperty('--button-color', appSettings?.actionButtonColor)
    root.style.setProperty('--button-font-color', appSettings?.actionButtonFontColor)
  }, [appSettings])

  useEffect(() => {
    if (businessId) {
      dispatch(getAppSettings({ businessId }))
    }
  }, [businessId])

  useEffect(() => {
    const pgScriptId = 'payment-gateway-script'
    const pgScript = document.getElementById(pgScriptId)

    if (pgScript) return

    if (userInfo && userInfo.paymentGatewayExt) {
      const scriptURL = userInfo.paymentGatewayExt + '/js/hh-pg.js'
      const script = document.createElement('script')

      script.src = scriptURL
      script.async = false
      script.id = pgScriptId
      document.body.appendChild(script)
    }
  }, [userInfo])

  const firstName = get(userInfo, 'firstName', '')
  const lastName = get(userInfo, 'lastName', '')
  const profilePicture = get(userInfo, 'profilePicUri', '')

  return (
    <div className="Header">
      <AppBar position="fixed" className="Bar" style={{ backgroundColor: appSettings?.primaryColor ?? colors.navbarBackColor }}>
        <Toolbar variant="dense">
          <BusinessLogo imageSX={{ height: 40 }} businessId={businessId} />
          <NavigationMenu />
          <User firstName={firstName} lastName={lastName} profilePicture={profilePicture} />
        </Toolbar>
      </AppBar>
      <Toolbar id="back-to-top-anchor" />
    </div>
  )
}

export default Header
