import React, { useEffect } from 'react'

import { Grid } from '@material-ui/core'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import { getConfiguredServices } from 'middleware/actions/configuredServices'
import { get } from 'utils/lodash'
import { combineAddressInfo } from 'utils/helper'

import Constants from 'Constants'
import HHDisplayMoney from 'components/HHDisplayMoney'

const days = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY']

const { MEDIA_SERVER } = Constants

const ConfiguredServices = () => {
  const dispatch = useDispatch()
  const businessId = useSelector(state => get(state, 'LoginPersist.selectedLoginDetails.businessId'), shallowEqual)
  const accountId = useSelector(state => get(state, 'LoginPersist.selectedLoginDetails.accountId'), shallowEqual)

  const { configuredServices } = useSelector(
    state => ({
      configuredServices: state.ConfiguredServicesReducer.configuredServices,
    }),
    shallowEqual
  )

  const getRecurrenceText = (frequency, per, interval) =>
    frequency && per && interval ? `${frequency}x per ${per} ${interval}${per > 1 ? 's' : ''}` : ''

  useEffect(() => {
    if (businessId && accountId) {
      dispatch(getConfiguredServices({ businessId, accountId }))
    }
  }, [businessId, accountId])

  return (
    <>
      {configuredServices?.services?.map(data => (
        <Grid container className="configured-service-tile flex justify-between" key={data.id}>
          <Grid item xs={12} md={3} className="flex flex-column">
            <div className="flex items-center">
              <span className="configured-service-status" style={{ backgroundColor: data.materialColor ?? '#d9d9d9' }} />
              <h3 className="configured-service-title">{get(data, 'serviceName', '')}</h3>
            </div>
            <div className="flex items-center">
              <img src={MEDIA_SERVER + 'RepitionCircle.svg'} className="mr2" />
              <span className="configured-service-duration">
                {data?.serviceType === 'Recurring'
                  ? getRecurrenceText(data?.recurrenceFrequency, data?.recurrencePer, data?.recurrenceInterval)
                  : get(data, 'serviceType', '')}
              </span>
            </div>
          </Grid>

          <Grid item xs={12} md={4}>
            <div className="flex items-center">
              <img src={MEDIA_SERVER + 'MapLocation.svg'} className="mr2" />
              {data?.address && <address className="configured-service-address">{combineAddressInfo(data.address)}</address>}
            </div>
            <div className="flex justify-between mt2">
              {days.map((day, index) => {
                const isActive = data.days?.findIndex(dataDay => dataDay.toUpperCase() === day.toUpperCase()) > -1
                const truckIcon = isActive ? 'Truck.svg' : 'TruckDisabled.svg'
                return (
                  <div className="flex items-center" key={index}>
                    <span
                      className={`configured-service-days ${
                        isActive ? 'configured-service-day-enabled' : 'configured-service-day-disabled'
                      }`}
                    >
                      {day.substring(0, 1)}
                    </span>
                    <img src={MEDIA_SERVER + truckIcon} className="ml1" />
                  </div>
                )
              })}
            </div>
          </Grid>

          <Grid item xs={12} md={5} className="flex flex-column items-end">
            <h3 className="configured-service-price">
              <HHDisplayMoney value={data?.price} formatToDollars={false} />
            </h3>
            <span className="configured-service-type">{data.measure?.replace('Yard', 'yd.').replace('Gallon', 'gal.')}</span>
          </Grid>
        </Grid>
      ))}
    </>
  )
}

export default ConfiguredServices
