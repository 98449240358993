import React, { Children } from 'react'
import PropTypes from 'prop-types'

import { useSelector, shallowEqual } from 'react-redux'

import Popover from '@material-ui/core/Popover'
import MenuItem from '@material-ui/core/MenuItem'
import InputAdornment from '@material-ui/core/InputAdornment'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { Check } from '@styled-icons/heroicons-outline/Check'
import { Calendar } from '@styled-icons/heroicons-outline/Calendar'

import { memo } from 'utils/react'
import { get } from 'utils/lodash'
import { isAlphNumericAndSpaceOnly, isNumberOnly, isValidPrice } from 'utils/validations'
import { FRONTEND_DATE_FORMAT } from 'settings/constants/date'
import { TEXT_FILTERS, NUMBER_FILTERS, DATE_FILTERS } from 'settings/constants/filters'

import T from 'T'
import Constants from 'Constants'
import FooterButton from 'components/buttons/FooterButton'

import CommonTextfield from './CommonTextfield'
import CommonSelect from './CommonSelect'

const { MEDIA_SERVER } = Constants

const PAYMENT_STATUS = ['Paid', 'Unpaid', 'Partial']

const CommonTableFilters = ({ isOpen, filterType, subType, operation, columnName, columnValue, onFilterChange, onApply, onClose }) => {
  const { invoiceMeta } = useSelector(
    state => ({
      invoiceMeta: get(state, 'BillingReducer.invoiceMeta', null),
    }),
    shallowEqual
  )

  const dropdownOptions = (() => {
    if (['string', 'meta'].includes(filterType)) {
      return TEXT_FILTERS
    }

    if (filterType === 'date') {
      return DATE_FILTERS
    }

    return NUMBER_FILTERS
  })()

  const getMetaData = (() => {
    if (columnName === 'cycle') {
      return get(invoiceMeta, 'billingCycles', []).map(cycle => ({ key: cycle.id, value: cycle.name }))
    }

    if (columnName === 'paymentTerms') {
      return get(invoiceMeta, 'paymentTerms', []).map(term => ({ key: term.id, value: term.name }))
    }

    return []
  })()

  return (
    <Popover
      open={Boolean(isOpen)}
      anchorEl={isOpen}
      onClose={onClose}
      className={`common table-filters-popover ${filterType}`}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      {['string', 'number', 'meta', 'date'].includes(filterType) && (
        <CommonSelect
          className="filter-select"
          value={operation}
          onChange={event => onFilterChange('operation', event.target.value, columnName)}
        >
          <MenuItem value="">
            <span>Select Filter</span>
            <span className="tick-icon dn">
              <Check className="icon-w-16" />
            </span>
          </MenuItem>
          {Children.toArray(
            dropdownOptions.map(filter => (
              <MenuItem value={filter.key}>
                <span>{filter.value}</span>
                <span className="tick-icon dn">
                  <Check className="icon-w-16" />
                </span>
              </MenuItem>
            ))
          )}
        </CommonSelect>
      )}

      {['string', 'number'].includes(filterType) && (
        <CommonTextfield
          className="mt2"
          placeholder="Enter Value"
          value={columnValue}
          // Add only string or number validation
          onChange={event => {
            const { value } = event.target
            if (value === '') {
              onFilterChange('columnValue', event.target.value, columnName)
            }

            if (filterType === 'number' && subType === 'price' && isValidPrice(value)) {
              onFilterChange('columnValue', event.target.value, columnName)
              return
            }

            if (filterType === 'number' && subType !== 'price' && isNumberOnly(value)) {
              onFilterChange('columnValue', event.target.value, columnName)
              return
            }

            if (filterType === 'string' && isAlphNumericAndSpaceOnly(value)) {
              onFilterChange('columnValue', event.target.value, columnName)
            }
          }}
        />
      )}

      {['meta'].includes(filterType) && (
        <CommonSelect
          className="mt2 filter-select"
          value={columnValue}
          onChange={event => onFilterChange('columnValue', event.target.value, columnName)}
        >
          <MenuItem value="">
            <span>Select Value</span>
            <span className="tick-icon dn">
              <Check className="icon-w-16" />
            </span>
          </MenuItem>
          {Children.toArray(
            getMetaData.map(filter => (
              <MenuItem value={filter.key}>
                <span>{filter.value}</span>
                <span className="tick-icon dn">
                  <Check className="icon-w-16" />
                </span>
              </MenuItem>
            ))
          )}
        </CommonSelect>
      )}

      {filterType === 'date' && ['LESS_THEN_EQUAL_BEFORE', 'GREATER_THEN_EQUAL_AFTER'].includes(operation) && (
        <CommonTextfield
          className="mt2"
          placeholder={FRONTEND_DATE_FORMAT}
          value={columnValue}
          onChange={event => onFilterChange('columnValue', event.target.value, columnName)}
          startAdornment={
            <InputAdornment position="start">
              <Calendar className="cal-icon icon-w-16" />
            </InputAdornment>
          }
        />
      )}

      {/* Change this to handle dynamic columns, currently its just status */}
      {filterType === 'checkbox' && (
        <div className="flex flex-column common checkbox">
          {Children.toArray(
            PAYMENT_STATUS.map(status => {
              return (
                <div className="flex ml1">
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<img src={`${MEDIA_SERVER}CheckboxOutline.svg`} />}
                        checkedIcon={<img src={`${MEDIA_SERVER}CheckboxCheckedFull.svg`} />}
                        color="primary"
                        name={status}
                        checked={columnValue.includes(status)}
                        onChange={e => {
                          // We can also split columnValue to make it array and push/pop element
                          const newMethods = PAYMENT_STATUS.filter(pStatus => {
                            if (pStatus !== e.target.name) {
                              return columnValue.includes(pStatus) ? pStatus : ''
                            }
                            return e.target.checked ? pStatus : ''
                          })
                          onFilterChange('columnValue', newMethods.join(','), columnName)
                        }}
                      />
                    }
                    label={status}
                  />
                </div>
              )
            })
          )}
        </div>
      )}

      {/* Footer buttons */}
      <div className="mt3-5 flex items-center justify-between">
        <FooterButton
          leftButtonTitle={T.CANCEL}
          onClose={onClose}
          rightButtonTitle={T.APPLY}
          onProceed={() => {
            onApply()
            onClose()
          }}
          // later use
          // disabledProceed={!columnValue}
        />
      </div>
    </Popover>
  )
}

CommonTableFilters.defaultProps = {
  isOpen: false,
  filterType: 'number',
  subType: '',
  operation: '',
  columnName: '',
  columnValue: '',
}

CommonTableFilters.propTypes = {
  isOpen: PropTypes.bool,
  filterType: PropTypes.string,
  subType: PropTypes.string,
  operation: PropTypes.string,
  columnName: PropTypes.string,
  columnValue: PropTypes.any,
  onFilterChange: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default memo(CommonTableFilters)
