import React from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'

import GreetingTime from 'utils/fetchGreeting'

import './common.scss'

const Greeting = ({ name = '' }) => {
  const byLineText = "Here's what's going on with your account"
  return (
    <>
      <Typography variant="h3" className="home-user-greeting">
        {`Good ${GreetingTime()}`}
        {name ? `, ${name}` : '!'}
      </Typography>
      <span className="byline-text">{byLineText}</span>
    </>
  )
}

export default Greeting

Greeting.propTypes = {
  name: PropTypes.string,
}
