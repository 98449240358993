import axios from 'axios'

import { SERVICE_END_POINT } from 'Backend'

const APIInstance = axios.create({
  baseURL: SERVICE_END_POINT,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const APIInstancePublic = axios.create({
  baseURL: window.PUBLIC_API_EXT,
  headers: {
    'Content-Type': 'application/json',
  },
})

export default APIInstance
