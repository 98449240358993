import React from 'react'
import PropTypes from 'prop-types'
import LoadingButton from '@mui/lab/LoadingButton'
import { noop } from 'lodash'
import T from 'T'

const SaveButton = ({ onClick = noop, size = 'small', label = T.SAVE, ...rest }) => {
  return (
    <LoadingButton onClick={onClick} variant="contained" color="primary" size={size} {...rest}>
      {label}
    </LoadingButton>
  )
}

SaveButton.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  label: PropTypes.node,
}

export default SaveButton
