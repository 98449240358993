import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import LoadingButton from '@mui/lab/LoadingButton'
import { Alert, Dialog, DialogContent, Typography, Box, Button } from '@mui/material'
import { get } from 'utils/lodash'
import { PAYMENT_METHODS, PAYMENT_METHODS_LABELS, PAYMENT_STATUS } from 'settings/constants/payment'

import HHDialogTitle from 'components/common/HHDialogTitle'
import HHDialogActions from 'components/common/HHDialogActions'
import HHDisplayMoney from 'components/HHDisplayMoney'
import { downloadInvoiceBlobListInChunks, downloadPaymentReceiptBlob, checkPaymentReceiptPDFExists } from 'utils/file'

const { ACCOUNT_CREDIT } = PAYMENT_METHODS
const { APPROVED } = PAYMENT_STATUS

const FILE_RETRY_TIMEOUT = 5000
const MAX_RETRY = 5

const PaymentResponseDialog = ({ isOpen = false, businessId, transaction = {}, onClose }) => {
  const [isPaymentReceiptGenerated, setIsPaymentReceiptGenerated] = useState(false)
  const [retryCount, setRetryCount] = useState(0)

  const {
    accountId,
    accountTransactionHistoryId,
    paymentMethod,
    paymentNumber,
    transactionAmountCents,
    accountType,
    accountNumber,
    transactionStatus,
  } = transaction

  const invoiceNumberList = get(transaction, 'paymentTarget', []).filter(target => target !== ACCOUNT_CREDIT)

  const handleDownloadInvoices = () => downloadInvoiceBlobListInChunks(invoiceNumberList, businessId, true)

  const handleDownloadPaymentReceipt = () =>
    downloadPaymentReceiptBlob(businessId, accountId, paymentNumber, accountTransactionHistoryId, true)

  const isApproved = transactionStatus === APPROVED

  const handleClose = () => {
    setIsPaymentReceiptGenerated(false)
    setRetryCount(0)
    onClose()
  }

  const checkPaymentReceiptStatus = async () => {
    const isExist = await checkPaymentReceiptPDFExists(businessId, accountId, accountTransactionHistoryId)

    if (isExist) {
      setIsPaymentReceiptGenerated(true)
      setRetryCount(0)
      return
    }

    setRetryCount(prev => prev + 1)
  }

  useEffect(() => {
    if (!isPaymentReceiptGenerated && retryCount > 0 && retryCount < MAX_RETRY) {
      setTimeout(checkPaymentReceiptStatus, FILE_RETRY_TIMEOUT)
    }

    if (retryCount >= MAX_RETRY) {
      setIsPaymentReceiptGenerated(true)
    }
  }, [retryCount])

  useEffect(() => {
    if (isOpen) {
      checkPaymentReceiptStatus()
    }
  }, [isOpen])

  return (
    <Dialog open={isOpen} maxWidth="xs" fullWidth>
      <HHDialogTitle
        sx={{ px: 3, pt: 3 }}
        closeIconSx={{ top: 16 }}
        onClose={handleClose}
        title={`Payment ${isApproved ? 'successful' : 'failed'}`}
      />
      <DialogContent sx={{ px: 3 }}>
        <Alert
          sx={{
            display: 'flex',
            alignItems: 'center',
            borderColor: isApproved ? 'success.main' : 'error.main',
            borderWidth: 1.5,
            borderStyle: 'solid',
          }}
          severity={isApproved ? 'success' : 'error'}
        >
          <Typography variant="h6" fontWeight={400}>
            {isApproved ? 'Payment processed successfully' : 'Failed to process payment'}
          </Typography>
        </Alert>

        <Box mt={3} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" fontWeight={400} color="textSecondary" fontStyle="italic">
            Payment method
          </Typography>
          <Typography variant="h6" fontWeight={400} fontStyle="italic">
            {accountType && accountNumber ? `${accountType} •••• ${accountNumber}` : get(PAYMENT_METHODS_LABELS, paymentMethod, '')}
          </Typography>
        </Box>
        <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" fontWeight={400} color="textSecondary" fontStyle="italic">
            Transaction ID
          </Typography>
          <Typography variant="h6" fontWeight={400} fontStyle="italic">
            {paymentNumber}
          </Typography>
        </Box>
        <Box mt={3} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" fontWeight={700} fontStyle="italic">
            Total
          </Typography>
          <Typography variant="h6" fontWeight={400} fontStyle="italic">
            <HHDisplayMoney value={transactionAmountCents} />
          </Typography>
        </Box>
      </DialogContent>
      <HHDialogActions>
        {invoiceNumberList.length > 0 && (
          <Button sx={{ '&:hover': { color: 'primary.main' } }} variant="outlined" onClick={handleDownloadInvoices}>
            Download invoice
          </Button>
        )}
        <Box sx={{ flex: '1 1 auto' }} />
        <LoadingButton loading={!isPaymentReceiptGenerated} variant="contained" onClick={handleDownloadPaymentReceipt}>
          Download receipt
        </LoadingButton>
      </HHDialogActions>
    </Dialog>
  )
}

PaymentResponseDialog.propTypes = {
  isOpen: PropTypes.bool,
  businessId: PropTypes.string.isRequired,
  transaction: PropTypes.object,
  onClose: PropTypes.func.isRequired,
}

export default PaymentResponseDialog
