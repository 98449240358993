import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    updateGCPUser: builder.mutation({
      query: body => ({
        method: 'PUT',
        url: `/api/v1/portal-user`,
        body,
      }),
    }),
  }),
})

export const { useUpdateGCPUserMutation } = extendedApi
