import React from 'react'
import PropTypes from 'prop-types'
import { DialogTitle, IconButton, useTheme } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { noop } from 'lodash'

const HHDialogTitle = ({ title, onClose = noop, showCloseIcon = true, sx, closeIconSx }) => {
  const theme = useTheme()
  return (
    <DialogTitle sx={{ ...theme.typography.h4, px: 3, py: 2, ...sx }}>
      {title}
      {showCloseIcon && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 12,
            top: 8,
            ...closeIconSx,
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  )
}

HHDialogTitle.propTypes = {
  onClose: PropTypes.func,
  showCloseIcon: PropTypes.bool,
  title: PropTypes.node,
  sx: PropTypes.object,
  closeIconSx: PropTypes.object,
}

export default HHDialogTitle
