import { GoogleAuthProvider } from 'firebase/auth'

export const getFirstNameAndLastName = (displayName = '', providerId, firstName, lastName) => {
  if (providerId === GoogleAuthProvider.PROVIDER_ID && firstName) {
    return { firstName, lastName }
  }

  const [first, ...last] = displayName.split(' ')
  return { firstName: first, lastName: last.join(' ') }
}
