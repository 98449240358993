import { actions } from '../actions/configuredServices'

const {
  PUT: { CONFIGURED_SERVICES },
} = actions

const initialState = {
  configuredServices: [],
}

const ConfiguredServicesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case CONFIGURED_SERVICES:
      return { ...state, configuredServices: action.payload }

    default:
      return state
  }
}

export default ConfiguredServicesReducer
