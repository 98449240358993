import { makeStyles, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import MenuToolTip from 'components/common/Tooltip'
import React from 'react'
import { PropTypes } from 'prop-types'
import T from 'T'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingRight: 14,
    paddingLeft: 14,
  },
  message: {
    padding: 0,
  },
  icon: {
    width: 18,
    height: 18,
    padding: '1.8px 0px 0px 0px',
    marginRight: 8,
  },
  alertText: {
    color: theme.palette.main,
    fontWeight: 500,
    fontSize: 11,
    lineHeight: '24px',
  },
}))

const TransactionSurchargeAlert = ({ className }) => {
  const classes = useStyles()
  return (
    <MenuToolTip title={T.TRANSACTION_SURCHARGE_DESCRIPTION}>
      <Alert
        className={className}
        classes={{ root: classes.root, icon: classes.icon, message: classes.message }}
        icon={<InfoOutlinedIcon className={classes.icon} />}
        severity="info"
      >
        <Typography className={classes.alertText}>{`${T.NOTE}: ${T.TRANSACTION_SURCHARGE_NOTE}`}</Typography>
      </Alert>
    </MenuToolTip>
  )
}

TransactionSurchargeAlert.propTypes = {
  className: PropTypes.string,
}

export default TransactionSurchargeAlert
