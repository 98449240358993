import React from 'react'
import PropTypes from 'prop-types'

import { Reply } from '@styled-icons/heroicons-outline/Reply'

import T from 'T'

import './style.scss'

const FooterButton = ({
  onProceed,
  onBack,
  onClose,
  leftButtonTitle,
  backButtonTitle,
  rightButtonTitle,
  disabledProceed,
  hasBackButton,
  hasProceedButton,
  disabledCancel,
  disabledBack,
}) => {
  // hasProceedButton: an option to enable/hide the proceed button

  const buttonProceedClass = disabledProceed ? 'btn-inactive' : 'btn-active'
  const buttonCancelClass = disabledCancel ? 'btn-inactive' : 'btn-active'
  const buttonBackClass = disabledBack ? 'btn-inactive cursor-not-allowed' : 'btn-active'

  return (
    <>
      <input
        type="button"
        value={leftButtonTitle}
        onClick={onClose}
        className={`footer-btns footer-cancel btn-active mr3 ${buttonCancelClass}`}
        disabled={disabledCancel}
      />

      {(hasBackButton || hasProceedButton) && (
        <div className="flex align-center">
          {hasBackButton && (
            // Replace this with input & InputAdornment
            <div
              className={`flex align-center footer-btns footer-back back mr3 ${buttonBackClass}`}
              onClick={() => !disabledBack && onBack()}
            >
              <Reply className="back-btn" />
              <div className="back-txt flex items-center">{backButtonTitle}</div>
            </div>
          )}

          {hasProceedButton && (
            <input
              type="button"
              value={rightButtonTitle}
              onClick={onProceed}
              disabled={disabledProceed}
              className={`footer-btns footer-proceed ${buttonProceedClass}`}
            />
          )}
        </div>
      )}
    </>
  )
}

FooterButton.defaultProps = {
  disabledProceed: false,
  disabledCancel: false,
  disabledBack: false,
  hasBackButton: false,
  hasProceedButton: true,
  rightButtonTitle: T.SAVE,
  backButtonTitle: T.BACK,
  leftButtonTitle: T.CANCEL,
  onBack: null,
}

FooterButton.propTypes = {
  disabledProceed: PropTypes.bool,
  disabledCancel: PropTypes.bool,
  disabledBack: PropTypes.bool,
  hasBackButton: PropTypes.bool,
  hasProceedButton: PropTypes.bool,
  leftButtonTitle: PropTypes.string,
  backButtonTitle: PropTypes.string,
  rightButtonTitle: PropTypes.string,
  onBack: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
}

export default FooterButton
