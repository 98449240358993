import { takeEvery, all, call, put } from 'redux-saga/effects'

import { doPost } from 'providers/api'

import { actions, putSignup, putEmailVerification, putAddPassword } from '../actions/signup'

import endpoint from './endpoint'

export const getSignupAPI = ({ body }) => doPost({ url: endpoint.public.signup, body, showLoader: true, isPublic: true })
export const getEmailVerificaitonAPI = ({ body }) => doPost({ url: endpoint.public.initiateSignup, body, showLoader: true, isPublic: true })
export const getAddPasswordAPI = ({ body }) => doPost({ url: endpoint.public.completeSignup, body, showLoader: true, isPublic: true })

export function* getSignupSaga({ payload }) {
  const { body } = payload

  try {
    const { data: res } = yield call(getSignupAPI, { body })
    yield put(putSignup(res))
  } catch (err) {
    yield put(putSignup(null))
  }
}

export function* getEmailVerificationSaga({ payload }) {
  const { body, onSuccess } = payload

  try {
    const { data: res } = yield call(getEmailVerificaitonAPI, { body })
    yield put(putEmailVerification(res))
    onSuccess(res)
  } catch (err) {
    yield put(putEmailVerification(null))
    onSuccess({})
  }
}

export function* getAddPasswordSaga({ payload }) {
  const { body, onSuccess } = payload

  try {
    const { data: res } = yield call(getAddPasswordAPI, { body })
    yield put(putAddPassword(res))
    onSuccess(res)
  } catch (err) {
    yield put(putAddPassword(null))
    onSuccess({})
  }
}

export default function* Signup() {
  yield all([
    takeEvery(actions.GET.SIGNUP, getSignupSaga),
    takeEvery(actions.GET.EMAIL_VERIFICATION, getEmailVerificationSaga),
    takeEvery(actions.GET.ADD_PASSWORD, getAddPasswordSaga),
  ])
}
