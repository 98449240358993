import { actions } from '../actions/invoice'

const {
  PUT: { INVOICES_LIST, INVOICE_INITIATE_DOWNLOAD, INVOICE_DOWNLOAD_STATUS, INVOICE_DOWNLOAD, INVOICE_RESET },
} = actions

const initialState = {
  invoices: {
    currentPage: 0,
    invoices: [],
    pageSize: 10,
    totalItems: 0,
    totalPages: 1,
  },
  requestId: null,
  downloadStatus: false,
}

const InvoiceReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case INVOICES_LIST:
      return { ...state, invoices: action.payload }

    case INVOICE_INITIATE_DOWNLOAD:
      return { ...state, requestId: action.payload }

    case INVOICE_DOWNLOAD_STATUS:
      return { ...state, downloadStatus: action.payload }

    case INVOICE_DOWNLOAD:
      return { ...state }

    case INVOICE_RESET:
      return {
        ...state,
        requestId: null,
        downloadStatus: false,
      }

    default:
      return state
  }
}

export default InvoiceReducer
