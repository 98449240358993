import React from 'react'
import PropTypes from 'prop-types'

import { get } from 'utils/lodash'
import { getPaymentMethodImage, getPaymentMethodName } from 'utils/payment'

const PaymentMethod = ({ nickNamePreference = false, method }) => {
  const creditCardType = get(method, 'creditCardType', '')
  const accountType = get(method, 'accountType', '')
  const methodName = getPaymentMethodName(creditCardType, accountType)
  const methodImage = getPaymentMethodImage(creditCardType || methodName)
  const methodText =
    nickNamePreference && method?.cardNickName ? method.cardNickName : `${creditCardType || accountType} ends in ${method?.accountNumber}`

  return (
    <div className="flex items-center">
      <img src={methodImage} alt="" />
      <span className="card-details">{methodText}</span>
    </div>
  )
}

export default PaymentMethod

PaymentMethod.propTypes = {
  nickNamePreference: PropTypes.bool,
  method: PropTypes.object.isRequired,
}
