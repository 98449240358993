import { actions } from '../actions/response'

const { IS_LOADING, ERROR_RESPONSE } = actions.PUT

const initialState = {
  isLoading: false,
  errorResponse: '',
}

const ResponseReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case IS_LOADING:
      return { ...state, isLoading: action.payload }

    case ERROR_RESPONSE:
      return { ...state, errorResponse: action.payload }

    default:
      return state
  }
}

export default ResponseReducer
