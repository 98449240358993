import { all } from 'redux-saga/effects'

import ConfiguredServices from './configuresServices'
import Login from './login'
import Invoice from './invoice'
import Payments from './payments'
import Settings from './settings'
import Signup from './signup'
import User from './user'

export default function* rootSaga() {
  yield all([Login(), ConfiguredServices(), Invoice(), Payments(), Settings(), Signup(), User()])
}
