import { TextField, styled } from '@mui/material'

const StyledTextField = styled(TextField)(({ theme, size, disabled, InputProps }) => ({
  '& .MuiFormLabel-root.MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
    // Since we are not using MUI default sizes, so adjusting the label manually according to the height
    transform: size === 'small' ? 'translate(14px, 7px) scale(1)' : 'translate(14px, 11px) scale(1)',
  },
  // custom height only applied when textfield is not a multiline textarea
  '& .MuiOutlinedInput-root:not(.MuiInputBase-multiline)': {
    height: size === 'small' ? 32 : 40,
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: disabled || InputProps?.readOnly ? theme.palette.background.disabled : theme.palette.background.paper,
    '&.Mui-disabled fieldset': {
      borderColor: theme.palette.grey['300'],
    },
    '& fieldset': {
      borderColor: theme.palette.grey['300'],
    },
    '&:hover fieldset': {
      borderColor: theme.palette.grey['300'],
    },
    '&.Mui-focused fieldset': {
      borderColor: InputProps?.readOnly ? theme.palette.grey['300'] : theme.palette.primary.main,
      borderWidth: InputProps?.readOnly ? 1 : 2,
    },
    '&.Mui-focused.Mui-error fieldset': {
      borderColor: theme.palette.error.main,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  input: {
    height: '100%',
    '&::placeholder': {
      ...theme.typography.body1,
      color: theme.palette.text.secondary,
      verticalAlign: 'middle',
    },
    ...theme.typography.body1,
    color: theme.palette.text.primary,
  },
}))

export default StyledTextField
