import api from 'api'
import Constants from 'Constants'

const { SERVER_PUBLIC_URL } = Constants

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    resetPassword: builder.mutation({
      query: body => ({
        method: 'POST',
        url: `${SERVER_PUBLIC_URL}/api/v1/portal-user/reset-password`,
        body,
      }),
    }),
  }),
})

export const { useResetPasswordMutation } = extendedApi
