import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  selectedLoginDetails: { show: false, businessName: '', businessId: '', accountId: '', redirectToInvoicePage: false },
}

const loginPersistSlice = createSlice({
  name: 'LoginPersist',
  initialState,
  reducers: {
    setSelectedLoginDetails: (state, action) => {
      state.selectedLoginDetails = { ...state.selectedLoginDetails, ...action.payload }
    },
  },
})

export const { setURLBusinessName, setSelectedLoginDetails } = loginPersistSlice.actions
export default loginPersistSlice.reducer
