import React from 'react'

import Invoices from 'containers/invoices'
import PageTitle from 'components/common/PageHeader'

const InvoicesPage = () => (
  <section className="common-padding page-bg">
    <div className="invoice-heading">
      <PageTitle title="Invoices" />
    </div>
    <Invoices />
  </section>
)

export default InvoicesPage
