import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import './style.scss'

const Loader = ({ left = 0, position = 'fixed' }) => {
  // we don't need to show scroll bar when this loader is used
  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'visible'
    }
  })

  return (
    <div className="internal-loader" style={{ position, top: 0, left }}>
      <div className="boxes">
        <div className="box">
          <div />
          <div />
          <div />
          <div />
        </div>
        <div className="box">
          <div />
          <div />
          <div />
          <div />
        </div>
        <div className="box">
          <div />
          <div />
          <div />
          <div />
        </div>
        <div className="box">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    </div>
  )
}

Loader.propTypes = {
  left: PropTypes.number,
  position: PropTypes.string,
}

export default Loader
