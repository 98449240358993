import { takeEvery, all, call, put } from 'redux-saga/effects'

import { doPost } from 'providers/api'

import { actions, putChangePassword, putPasswordComplexity } from '../actions/settings'

import endpoint from './endpoint'

export const getChangePasswordAPI = ({ body }) => doPost({ url: endpoint.user.changePassword, body, showLoader: true })
export const getPasswordInitiateAPI = () => doPost({ url: endpoint.user.initiatePassword, body: {}, showLoader: true })

export function* getChangePasswordSaga({ payload }) {
  const { body } = payload

  try {
    const { data: res } = yield call(getChangePasswordAPI, { body })
    yield put(putChangePassword(res))
  } catch (err) {
    yield put(putChangePassword(null))
  }
}

export function* getPasswordInitiateSaga() {
  try {
    const { data: res } = yield call(getPasswordInitiateAPI)
    yield put(putPasswordComplexity(res))
  } catch (err) {
    yield put(putPasswordComplexity(null))
  }
}

export default function* Settings() {
  yield all([
    takeEvery(actions.GET.CHANGE_PASSWORD, getChangePasswordSaga),
    takeEvery(actions.GET.PASSWORD_COMPLEXITY, getPasswordInitiateSaga),
  ])
}
