import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { Modal, Backdrop, Fade } from '@material-ui/core'
import { X as Close } from '@styled-icons/heroicons-solid/X'

import Constants from 'Constants'

import T from 'T'

const { MEDIA_SERVER } = Constants

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    outline: 'none',
  },
}))

const PaymentResponse = ({ paymentStatus, paymentModalOpen, closePaymentModal, isACHMethodSelected = false }) => {
  const classes = useStyles()

  const getTopMsg = () => {
    return (
      <div className="payment-top-section">
        {paymentStatus === 'success' && (
          <>
            {T.PAYMENT_SUCCESS_TOP_MSG}
            <div className="payment">{T.PAYMENT}!</div>
          </>
        )}

        {paymentStatus === 'failed' && T.PAYMENT_FAILED_TOP_MSG}
        {paymentStatus === 'pending' && `${T.PAYMENT_PENDING_TOP_MSG} ${isACHMethodSelected ? 'ACH' : 'credit card'}`}
      </div>
    )
  }

  const getIcon = () => {
    return (
      <div className="payment-mid-section">
        {paymentStatus === 'success' && <img src={`${MEDIA_SERVER}PaymentSuccess.svg`} alt="" />}
        {paymentStatus === 'failed' && <img src={`${MEDIA_SERVER}PaymentFailed.svg`} alt="" />}
        {paymentStatus === 'pending' && <img src={`${MEDIA_SERVER}PaymentLoading.svg`} alt="" />}
      </div>
    )
  }

  const getBottomMsg = () => {
    return (
      <div className="payment-bottom-section">
        {paymentStatus === 'success' && T.PAYMENT_SUCCESS_BOTTOM_MSG}
        {paymentStatus === 'failed' && T.PAYMENT_FAILED_BOTTOM_MSG}
        {paymentStatus === 'pending' && (
          <>
            <div className="payment-bottom-section">{T.PAYMENT_PENDING_BOTTOM_MSG}</div>
            <div className="payment-bottom-section">{T.THANK_YOU_FOR_PATIENCE}</div>
          </>
        )}
      </div>
    )
  }

  return (
    <>
      <Modal
        className="flex justify-center items-center"
        open={paymentModalOpen}
        onClose={closePaymentModal}
        disableBackdropClick
        disableEscapeKeyDown
        BackdropComponent={Backdrop}
      >
        <Fade in={paymentModalOpen}>
          <div className={`${classes.paper} payment-msg-wrapper`}>
            {paymentStatus !== 'pending' && (
              <div className="close-icon cursor-pointer" onClick={() => closePaymentModal()}>
                <Close />
              </div>
            )}

            <div className="top-section">{getTopMsg()}</div>
            <div className="mid-section">{getIcon()}</div>
            <div className="bottom-section">{getBottomMsg()}</div>
          </div>
        </Fade>
      </Modal>
    </>
  )
}

PaymentResponse.defaultProps = {
  paymentStatus: '',
  paymentModalOpen: '',
  closePaymentModal: null,
}

PaymentResponse.propTypes = {
  paymentStatus: PropTypes.string,
  paymentModalOpen: PropTypes.string,
  closePaymentModal: PropTypes.func,
  isACHMethodSelected: PropTypes.bool,
}

export default PaymentResponse
