import routes from 'router/routes'

const { app } = routes
const menuItems = {
  home: {
    title: 'Home',
    route: app.home,
  },
  invoices: {
    title: 'Invoices',
    route: app.invoices,
  },
  payments: {
    title: 'Payments',
    route: app.payments,
  },
}

export const MENU_ITEMS = [menuItems.home, menuItems.invoices, menuItems.payments]
