export const actions = {
  GET: {
    SIGNUP: 'GET_SIGNUP',
    EMAIL_VERIFICATION: 'GET_EMAIL_VERIFICATION',
    ADD_PASSWORD: 'GET_ADD_PASSWORD',
  },
  PUT: {
    SIGNUP: 'PUT_SIGNUP',
    EMAIL_VERIFICATION: 'PUT_EMAIL_VERIFICATION',
    ADD_PASSWORD: 'PUT_ADD_PASSWORD',
  },
}

export const putSignup = res => ({
  type: actions.PUT.SIGNUP,
  payload: res,
})

export const putEmailVerification = res => ({
  type: actions.PUT.EMAIL_VERIFICATION,
  payload: res,
})

export const putAddPassword = res => ({
  type: actions.PUT.ADD_PASSWORD,
  payload: res,
})

export const getSignup = body => ({
  type: actions.GET.SIGNUP,
  payload: { body },
})

export const getEmailVerification = (body, onSuccess) => ({
  type: actions.GET.EMAIL_VERIFICATION,
  payload: { body, onSuccess },
})

export const getAddPassword = (body, onSuccess) => ({
  type: actions.GET.ADD_PASSWORD,
  payload: { body, onSuccess },
})
