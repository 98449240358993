import { toast } from 'react-toastify'
import T from 'T'
import { get } from './lodash'

export const handleError = error => {
  const errorMsg = get(error, 'data.message') || get(error, 'data.error')
  if (errorMsg) {
    toast.error(errorMsg)
  }
}
export const cloudStorageErrorHandler = error => {
  // A full list of error codes is available at
  // https://firebase.google.com/docs/storage/web/handle-errors
  if (error?.code) {
    switch (error.code) {
      case 'storage/object-not-found':
      case 'storage/unauthorized':
        return toast.error('File not found')
      case 'storage/canceled':
        return undefined
      case 'storage/unknown':
      default:
        return toast.error('There was an unexpected error. If the problem persists, contact support.')
    }
  }
  return handleError(error)
}

export const firebaseErrorHandler = error => {
  if (error?.code) {
    switch (error.code) {
      case 'auth/wrong-password':
        return T.CURRENT_PWD_INCORRECT
      case 'auth/weak-password':
        return T.WEAK_PASSWORD_ERROR
      default:
        return error?.message || T.UNEXPECTED_ERROR
    }
  }
  return T.UNEXPECTED_ERROR
}
