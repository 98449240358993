import firebase from 'firebase/compat/app'
import { BACKGROUND } from 'theme/colors'
import { firebaseConfig } from 'settings/constants/firebase'
import Constants from 'Constants'
import { common, indigo } from '@mui/material/colors'

const { MEDIA_SERVER } = Constants
const WHITE_COLOR = BACKGROUND.paper

const commonProps = { buttonColor: WHITE_COLOR }
const { clientId } = firebaseConfig

export const signInOptions = [
  {
    provider: 'apple.com',
    iconUrl: `${MEDIA_SERVER}social-icons/auth/Apple.svg`,
    ...commonProps,
  },
  {
    provider: 'microsoft.com',
    iconUrl: `${MEDIA_SERVER}social-icons/auth/Microsoft.svg`,
    ...commonProps,
  },
  {
    clientId,
    provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    iconUrl: `${MEDIA_SERVER}social-icons/auth/Google.svg`,
    ...commonProps,
  },
  {
    provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    iconUrl: `${MEDIA_SERVER}social-icons/auth/Facebook.svg`,
    ...commonProps,
  },
  {
    provider: firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    iconUrl: `${MEDIA_SERVER}social-icons/auth/Twitter.svg`,
    ...commonProps,
  },
  {
    provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
    fullLabel: 'Sign in with email',
    iconUrl: `${MEDIA_SERVER}social-icons/auth/Email.svg`,
    ...commonProps,
  },
].filter(({ provider }) => [firebase.auth.GoogleAuthProvider.PROVIDER_ID, firebase.auth.EmailAuthProvider.PROVIDER_ID].includes(provider))

export const PRIMARY_BUTTON_SX = {
  fontWeight: 500,
  backgroundColor: indigo[500],
  color: common.white,
  '&:hover': {
    backgroundColor: indigo[500],
    color: common.white,
  },
}
