import { get } from 'utils/lodash'
import { toast } from 'react-toastify'

import APIInstance, { APIInstancePublic } from 'providers/api-instance'
import { store } from 'providers/store'

import { ERROR_EXCEPTIONS, ERROR_MESSAGES } from 'settings/error'
import { STATUS_CODE } from 'settings/status'

import { putIsLoading, putErrorResponse } from 'middleware/actions/response'

import { getToken, handleFirebaseLogout } from './firebaseAuth'

const { UNAUTHORIZED, BAD_REQUEST } = STATUS_CODE
const { INVALID_USER_EXCEPTION, INVALID_ACCOUNT_EXCEPTION } = ERROR_EXCEPTIONS
const { INVALID_ACCOUNT_MESSAGE } = ERROR_MESSAGES

const handleCatch = errorInfo => {
  console.log('*** There was a server error!')
  console.log(errorInfo)
  console.log('***')

  const exceptionType = get(errorInfo, 'response.data.exceptionType')
  const errorMsg = get(errorInfo, 'response.data.message') || get(errorInfo, 'response.data.error')
  const statusCode = errorInfo?.response?.status

  if (statusCode === UNAUTHORIZED) {
    handleFirebaseLogout()
    return
  }

  // Initiate logout if API returns Invalid User or Invalid Account
  if (
    [INVALID_USER_EXCEPTION, INVALID_ACCOUNT_EXCEPTION].includes(exceptionType) ||
    (statusCode === BAD_REQUEST && errorMsg === INVALID_ACCOUNT_MESSAGE)
  ) {
    handleFirebaseLogout()
    return
  }

  if (errorMsg) {
    // Show toast
    toast.error(errorMsg)
    store.dispatch(putErrorResponse(errorInfo.response.status))
  }
}

const handleFinally = (showLoader, isLoaderAutoOff) => {
  if (showLoader && isLoaderAutoOff) {
    // hide loader
    store.dispatch(putIsLoading(false))
  }
}

// Common method for all post calls
export const doPost = async ({
  url,
  body = {},
  showLoader = false,
  // loaderTimeout = 0,
  isLoaderAutoOff = true,
  arraybuffer = '',
  isPublic = false,
}) => {
  let headers = {}
  const token = await getToken()
  if (token) {
    headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  }

  if (arraybuffer) {
    headers.responseType = arraybuffer
  }

  if (showLoader) {
    store.dispatch(putIsLoading(true))
  }

  if (isPublic) {
    return APIInstancePublic.post(url, body, headers)
      .catch(errorInfo => handleCatch(errorInfo))
      .finally(() => handleFinally(showLoader, isLoaderAutoOff))
  }

  return APIInstance.post(url, body, headers)
    .catch(errorInfo => handleCatch(errorInfo))
    .finally(() => handleFinally(showLoader, isLoaderAutoOff))
}

export const doGet = async ({ url, body = {}, showLoader = false, isLoaderAutoOff = true, isPublic = false }) => {
  let headers = {}
  const token = await getToken()
  if (token) {
    headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  }

  if (showLoader) {
    store.dispatch(putIsLoading(true))
  }

  if (isPublic) {
    return APIInstancePublic.get(url, headers)
      .catch(errorInfo => handleCatch(errorInfo))
      .finally(() => handleFinally(showLoader, isLoaderAutoOff))
  }

  return APIInstance.get(url, headers)
    .catch(errorInfo => handleCatch(errorInfo))
    .finally(() => handleFinally(showLoader, isLoaderAutoOff))
}
