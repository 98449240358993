import React, { useEffect, Children } from 'react'
import PropTypes from 'prop-types'

import NumberFormat from 'react-number-format'

import { shallowEqual, useSelector } from 'react-redux'

import { Button, Checkbox, Grid, Radio, makeStyles, InputLabel } from '@material-ui/core'
import { ListItem, ListItemText } from '@mui/material'

import { AMOUNT_TYPES } from 'settings/constants/payment'

import HHTextField from 'components/form-fields/v5/HHTextField'
import SelectItem from 'components/form-fields/v5/SelectItem'

import T from 'T'
import Constants from 'Constants'
import PaymentMethod from 'components/common/PaymentMethod'
import HHDisplayMoney from 'components/HHDisplayMoney'

import { get } from 'utils/lodash'
import { createDateFromString, formatDateToFEFormatDateFns } from 'utils/date'

import TransactionSurchargeAlert from './TransactionSurchargeAlert'

const { MEDIA_SERVER } = Constants

const { CURRENT_AMOUNT_DUE, OTHER_AMOUNT } = AMOUNT_TYPES

const MIN_AMOUNT = 0.01

const useStyles = makeStyles({
  surchargeAlert: {
    width: 410,
    marginBottom: '10px',
  },
})

const MakePayment = ({ unPaidInvoices = [], paymentAmountSelect = CURRENT_AMOUNT_DUE, otherAmount = '', ...props }) => {
  const classes = useStyles()
  const { homePagePayments } = useSelector(
    state => ({
      homePagePayments: state.PaymentsReducer.homePagePayments,
    }),
    shallowEqual
  )

  const handleOthePaymentsClick = () => {
    const isVisible = props.showOtherPayments
    if (!isVisible) {
      props.setState({ selectedCard: null })
    }
    props.setShowOtherPayments(!isVisible)
  }

  const isOtherPaymentAmount = paymentAmountSelect === OTHER_AMOUNT
  const isOtherAmountInValid = otherAmount < MIN_AMOUNT
  const { paymentMethodsList, setPaymentMethod, setShowOtherPayments } = props

  useEffect(() => {
    if (Array.isArray(paymentMethodsList) && paymentMethodsList.length >= 1) {
      const methodItem = paymentMethodsList[0]
      setPaymentMethod(methodItem.id)
      setShowOtherPayments(false)
    }
  }, [paymentMethodsList])

  return (
    <Grid container className="mt-4">
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <div className="input-wrapper flex flex-column">
              <InputLabel>{T.PAYMENT_AMOUNT}</InputLabel>
              <HHTextField
                deprecatedLabel={false}
                label={T.ACCOUNT_REP}
                fullWidth
                select
                value={[paymentAmountSelect]}
                onChange={evt => props.setState({ paymentAmountSelect: evt.target.value, otherAmount: '' })}
                sx={{ '& .MuiSelect-select': { display: 'flex' } }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      sx: { '& .Mui-selected': { 'p, span': { color: 'primary.main' } } },
                    },
                  },
                }}
              >
                <SelectItem value={CURRENT_AMOUNT_DUE}>
                  <ListItem sx={{ px: 0, py: 0.5 }}>
                    <ListItemText primary="Total balance due" />
                  </ListItem>
                  <ListItem sx={{ textAlign: 'right', px: 0, py: 0.5 }}>
                    <ListItemText
                      primaryTypographyProps={{ color: 'textSecondary' }}
                      primary={<HHDisplayMoney value={props.amountToPay} formatToDollars={false} />}
                    />
                  </ListItem>
                </SelectItem>

                {Children.toArray(
                  unPaidInvoices.map(option => (
                    <SelectItem value={option.invoiceNumber}>
                      <ListItem sx={{ px: 0, py: 0.5 }}>
                        <ListItemText
                          primary={`Invoice#: ${option.invoiceNumber}`}
                          secondary={`Due date: ${formatDateToFEFormatDateFns(createDateFromString(option.dueDate))}`}
                        />
                      </ListItem>
                      <ListItem sx={{ textAlign: 'right', px: 0, py: 0.5 }}>
                        <ListItemText
                          primaryTypographyProps={{ color: 'textSecondary' }}
                          primary={<HHDisplayMoney value={option.totalCents - option.amountPaidCents} />}
                        />
                      </ListItem>
                    </SelectItem>
                  ))
                )}

                <SelectItem value={OTHER_AMOUNT}>
                  <ListItem sx={{ px: 0, py: 0.5 }}>
                    <ListItemText primary={T.OTHER_AMOUNT} />
                  </ListItem>
                </SelectItem>
              </HHTextField>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <div className="input-wrapper make-payment-wrapper flex flex-column">
              <InputLabel>
                {T.OTHER_AMOUNT}
                {otherAmount !== '' && isOtherAmountInValid && (
                  <InputLabel className="Mui-error dib ml1">{`Min $${MIN_AMOUNT}`}</InputLabel>
                )}
              </InputLabel>

              <NumberFormat
                disabled={!isOtherPaymentAmount}
                prefix="$"
                placeholder="$0.00"
                name="otherAmount"
                value={otherAmount}
                decimalSeparator="."
                decimalScale={2}
                thousandSeparator
                onValueChange={values => {
                  const { value } = values
                  props.setState({ otherAmount: value })
                }}
              />
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={4} className="backend-data">
          {props.paymentMethodsList.map((methodItem, index) => {
            const onPaymentMethodChange = () => {
              props.setPaymentMethod(methodItem.id)
              props.setShowOtherPayments(false)
            }
            return (
              <Grid item xs={12} sm={6} md={4} key={methodItem.accountToken}>
                <div className="input-wrapper flex flex-column">
                  <div className="flex justify-between">
                    <label className={`payment-method-label payment-method-label${index}`}>{T.PAYMENT_METHOD}</label>
                    <div className="flex payment-card-action">
                      {/* <span className="edit">{T.EDIT}</span> */}
                      <span className="remove" onClick={() => props.handleRemovePaymentMethod(methodItem)}>
                        {T.REMOVE}
                      </span>
                    </div>
                  </div>
                  <div className="flex payment-input justify-between items-center">
                    <div className="flex items-center">
                      <Radio
                        checked={props.selectedPaymentMethod === methodItem.id}
                        name="paymentMethod"
                        onChange={onPaymentMethodChange}
                      />
                      <PaymentMethod nickNamePreference method={methodItem} />
                    </div>
                    {get(methodItem, 'accountType', '') !== 'ECHK' && (
                      <span className="card-expiry">
                        Expires {methodItem?.expiry?.substr(0, 2)}/{methodItem?.expiry?.substr(2)}
                      </span>
                    )}
                  </div>
                </div>
              </Grid>
            )
          })}

          <Grid item xs={12} sm={6} md={4} className="flex align-center items-end payment-btn">
            <Button variant="outlined" className="payment-button" onClick={handleOthePaymentsClick}>
              {T.OTHER_PAYMENT_METHOD}
            </Button>
          </Grid>

          {get(props, 'showOtherPayments', false) && (
            <Grid item xs={12} className="flex flex-column other-payments-save-wrapper">
              {props.addPayment}
              {get(props, 'pState.addAccountTabToShow', 0) === 0 && get(props, 'pState.surcharge', false) && (
                <TransactionSurchargeAlert className={`cursor-pointer ${classes.surchargeAlert}`} />
              )}
              <div className={`make-payments-save ${get(props, 'pState.addAccountTabToShow', 0) === 0 ? 'mt1' : 'mt3'}`}>
                <Checkbox
                  checked={props.savePaymentMethod}
                  onChange={() => props.setSavePaymentMethod(!props.savePaymentMethod)}
                  icon={<img src={`${MEDIA_SERVER}CheckboxOutline.svg`} />}
                  checkedIcon={<img src={`${MEDIA_SERVER}CheckboxCheckedDisabled.svg`} />}
                />
                <span>{T.SAVE_METHOD}</span>
              </div>
            </Grid>
          )}

          <Grid item xs={12} className="payment-btn">
            {!get(props, 'showOtherPayments', false) &&
              get(props, 'pState.surcharge', false) &&
              get(props, 'pState.addAccountTabToShow', 0) === 0 && (
                <TransactionSurchargeAlert className={`cursor-pointer ${classes.surchargeAlert}`} />
              )}
            <Grid item xs={12} sm={6} md={4}>
              {(isOtherPaymentAmount || get(homePagePayments, 'unpaidInvoices', []).length > 0) && (
                <Button
                  // Disabled case only for other payment amount option
                  // Rest cases are handled by the handlePaymentClick function - Later refactor the function
                  disabled={isOtherPaymentAmount && isOtherAmountInValid}
                  variant="contained"
                  className="payment-button"
                  onClick={props.handlePaymentClick}
                >
                  {T.MAKE_PAYMENT_BUTTON}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

MakePayment.propTypes = {
  unPaidInvoices: PropTypes.array,
  paymentAmountSelect: PropTypes.string,
  otherAmount: PropTypes.string,
}

export default MakePayment
