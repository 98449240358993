import { actions } from '../actions/payments'

const {
  PUT: { HOME_PAGE_PAYMENTS, UPDATE_AUTOPAY },
} = actions

const initialState = {
  homePagePayments: null,
  autopayUpdate: null,
}

const PaymentsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case HOME_PAGE_PAYMENTS:
      return { ...state, homePagePayments: action.payload }

    case UPDATE_AUTOPAY:
      return { ...state, autopayUpdate: action.payload }

    default:
      return state
  }
}

export default PaymentsReducer
