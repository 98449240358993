import React from 'react'

import PageHeader from 'components/common/PageHeader'
import Settings from 'containers/settings'

const SettingsPage = () => (
  <section className="common-padding page-bg">
    <div className="invoice-heading">
      <PageHeader title="Settings" />
    </div>
    <Settings />
  </section>
)

export default SettingsPage
