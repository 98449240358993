export const actions = {
  PUT: {
    IS_LOADING: 'RESPONSE_PUT_IS_LOADING',
    ERROR_RESPONSE: 'RESPONSE_PUT_ERROR',
  },
}

export const putIsLoading = bool => ({
  type: actions.PUT.IS_LOADING,
  payload: bool,
})

export const putErrorResponse = msg => ({
  type: actions.PUT.ERROR_RESPONSE,
  payload: msg,
})
