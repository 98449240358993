import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  closeButton: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(2),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography className="f4 fw6">{children}</Typography>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  )
})

const DialogActions = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    '& .cancelBtn': {
      color: '#7a869a',
    },
    '& .confirmBtn, & .confirmBtn:hover': {
      background: '#dc2626',
    },
  },
}))(MuiDialogActions)

const HHConfirmDialog = ({
  isOpen = false,
  showCancelButton = true,
  confirmTitle = 'Are you sure?',
  confirmDescription,
  confirmButtonTitle = 'Delete',
  onConfirm = noop,
  onClose = noop,
}) => {
  return (
    <div>
      <Dialog onClose={onClose} open={isOpen}>
        <DialogTitle onClose={onClose}>{confirmTitle}</DialogTitle>
        {confirmDescription && (
          <DialogContent className="pt0">
            <Typography className="f6">{confirmDescription}</Typography>
          </DialogContent>
        )}
        <DialogActions>
          {showCancelButton && (
            <Button onClick={onClose} className="cancelBtn">
              Cancel
            </Button>
          )}

          <Button autoFocus onClick={onConfirm} variant="contained" className="confirmBtn">
            {confirmButtonTitle}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

HHConfirmDialog.propTypes = {
  isOpen: PropTypes.bool,
  showCancelButton: PropTypes.bool,
  confirmTitle: PropTypes.string,
  confirmDescription: PropTypes.node,
  confirmButtonTitle: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
}

export default HHConfirmDialog
