import { actions } from '../actions/settings'

const {
  PUT: { CHANGE_PASSWORD, PASSWORD_COMPLEXITY },
} = actions

const initialState = {
  passwordComplexity: null,
  changePassword: null,
}

const SettingsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case PASSWORD_COMPLEXITY:
      return { ...state, passwordComplexity: action.payload }

    case CHANGE_PASSWORD:
      return { ...state, changePassword: action.payload }

    default:
      return state
  }
}

export default SettingsReducer
