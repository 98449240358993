import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'

import { memo } from 'utils/react'

import Constants from 'Constants'
import BasicModal from 'components/modal/BasicModal'

const { MEDIA_SERVER } = Constants

const useStyles = makeStyles(() => ({
  summaryModal: {
    width: '496px',
    height: 'auto',
    boxShadow: 'none',
    position: 'relative',
    maxHeight: '80vh',
    overflowY: 'auto',
  },
  summaryCloseIcon: {
    position: 'absolute',
    top: '18px',
    right: '21px',
  },

  content: {
    padding: '16px',
    paddingRight: '48px',
    fontSize: '15px',
    lineHeight: '24px',
    color: '#042040',
    wordWrap: 'break-word',
  },
}))

const SummaryModal = ({ isOpen = false, summaryContent = '', onClose }) => {
  const classes = useStyles()
  return (
    <BasicModal isOpen={isOpen} onClose={onClose}>
      <div className={classes.summaryModal}>
        <span className={`cursor-pointer ${classes.summaryCloseIcon}`} onClick={onClose}>
          <img src={`${MEDIA_SERVER}Close.svg`} alt="" />
        </span>
        <div className={classes.content}>{summaryContent}</div>
      </div>
    </BasicModal>
  )
}

SummaryModal.propTypes = {
  isOpen: PropTypes.bool,
  summaryContent: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

export default memo(SummaryModal)
