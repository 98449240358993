import React from 'react'
import moment from 'moment'

import { useHistory } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { Button, Paper, Typography } from '@material-ui/core'

import T from 'T'
import routes from 'router/routes'

import { HomeInformationIcon } from 'components/common/Icons'
import { addDecimal } from 'utils/handleAmount'

import { get } from 'utils/lodash'

const PaymentSection = () => {
  const history = useHistory()
  const { homePagePayments } = useSelector(
    state => ({
      homePagePayments: state.PaymentsReducer.homePagePayments,
    }),
    shallowEqual
  )

  const lastPaymentAmount = get(homePagePayments, 'lastPaymentInfo.amount', 0)
  const lastPaymentDate = get(homePagePayments, 'lastPaymentInfo.paymentDate')
  const dueDate = get(homePagePayments, 'dueDate')

  const latePayment = dueDate
    ? T.LATE_PAYMENT_MESSAGE(moment(dueDate).format('MMM DD, YYYY'), addDecimal(homePagePayments?.lateFee ?? '0.00'))
    : ''

  const handlePaymentClick = () => {
    history.push(routes.app.payments)
  }

  const getDueAmount = () => {
    if (get(homePagePayments, 'lateFee', 0) < 1) {
      return lastPaymentAmount && lastPaymentDate
        ? `${T.LAST_PAYMENT_MESSAGE} $${addDecimal(lastPaymentAmount)} on ${moment(lastPaymentDate).format('MMM DD, YYYY')}`
        : ''
    }

    return latePayment
  }

  return (
    <Paper elevation={2} className="card-section">
      <div className="flex flex-section">
        <Typography variant="h6">{T.PAYMENT}</Typography>
        <HomeInformationIcon />
      </div>

      {lastPaymentAmount > 0 && lastPaymentDate && (
        <div className="flex flex-section payment-section-message">
          <span>{T.PAYMENT_THANK_YOU}</span>
        </div>
      )}

      <div className="flex flex-section">
        <Typography variant="subtitle1">{getDueAmount()}</Typography>
      </div>

      <div className="flex flex-section justify-between">
        <Button
          size="small"
          variant="outlined"
          className="button-outline-danger payment-button-text"
          disableElevation
          onClick={handlePaymentClick}
        >
          {T.MAKE_PAYMENT_BUTTON}
        </Button>
        <Button
          size="small"
          variant="contained"
          className="button-contained-danger payment-button-text"
          disableElevation
          onClick={handlePaymentClick}
        >
          {T.MANAGE_AUTO_PAY_BUTTON}
        </Button>
      </div>
    </Paper>
  )
}

export default PaymentSection
