import React, { Children } from 'react'
import PropTypes from 'prop-types'

import { shallowEqual, useSelector } from 'react-redux'

import { get } from 'utils/lodash'
import { getAllAccounts } from 'data/select-account/selectAccount'

import HHTextField from 'components/form-fields/v5/HHTextField'
import SelectItem from 'components/form-fields/v5/SelectItem'
import SelectItemContent from './SelectItemContent'

const AccountSelectionDropdown = ({ value, readOnly = false, onChange, sx = {}, ...rest }) => {
  const accountsPerBusiness = useSelector(state => get(state, 'AuthReducer.userInfo.accountsPerBusiness', []), shallowEqual)
  const allAccounts = getAllAccounts({ accountsPerBusiness })

  return (
    <HHTextField
      readOnly={readOnly}
      fullWidth
      select
      value={[value]}
      onChange={onChange}
      sx={{
        '& .MuiInputBase-root': { height: '52px!important' },
        '& .MuiSelect-select': {
          boxShadow: 'none',
          display: 'flex',
          alignItems: 'center',
          '& .business-name': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        },
        ...sx,
      }}
      SelectProps={{
        MenuProps: {
          PaperProps: {
            sx: { '& .Mui-selected': { '& .MuiTypography-root': { color: 'primary.main' } } },
          },
        },
      }}
      {...rest}
    >
      {Children.toArray(
        allAccounts.map(account => (
          <SelectItem value={account.id}>
            <SelectItemContent
              businessId={account.businessId}
              accountName={account.accountName}
              accuntNumber={account.accountNumber}
              businessTitle={account.businessTitle}
            />
          </SelectItem>
        ))
      )}
    </HHTextField>
  )
}

AccountSelectionDropdown.propTypes = {
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  sx: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

export default AccountSelectionDropdown
