import { Alert } from '@mui/material'
import React from 'react'

const ErrorPage = () => (
  <Alert sx={{ m: 2 }} severity="error">
    Error
  </Alert>
)

export default ErrorPage
