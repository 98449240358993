import { takeEvery, all, call, put } from 'redux-saga/effects'

import { doGet } from 'providers/api'

import { actions, putAppSettings } from '../actions/user'

export const getAppSettings = businessId => doGet({ url: `/api/v1/tenants/${businessId}/app-settings`, showLoader: true })

export function* getAppSettingsSaga({ payload }) {
  try {
    const { data: res } = yield call(getAppSettings, payload.businessId)
    yield put(putAppSettings(res))
  } catch (err) {
    yield put(putAppSettings(null))
  }
}

export default function* User() {
  yield all([takeEvery(actions.GET.APP_SETTINGS, getAppSettingsSaga)])
}
