import get from 'lodash/get'
import format from 'date-fns/format'

import { FRONTEND_DATE_FORMAT_DATE_FNS } from 'settings/constants/date'

// Format should be YYYY-MM-DD, output date
export const createDateFromString = date => {
  if (!date) {
    return ''
  }

  const dateSplit = date.split('-')
  // Month start from 0, so -1
  return new Date(get(dateSplit, '[0]', undefined), get(dateSplit, '[1]', 1) - 1, get(dateSplit, '[2]', undefined))
}

export const formatDateToFEFormatDateFns = date => (date ? format(date, FRONTEND_DATE_FORMAT_DATE_FNS) : date)
