import { takeEvery, all, call, put, delay } from 'redux-saga/effects'

import { toast } from 'react-toastify'

import { doPost } from 'providers/api'

import { putIsLoading } from 'middleware/actions/response'

import { get } from 'utils/lodash'
import { downloadFile } from 'utils/file'

import {
  actions,
  putInvoicesList,
  putInvoiceInitiateDownload,
  putInvoiceDownloadStatus,
  putInvoiceDownload,
  putInvoicesReset,
} from '../actions/invoice'

import endpoint from './endpoint'

export const getInvoiceList = ({ businessId, accountId, body }) =>
  doPost({ url: `/api/v1/tenants/${businessId}/accounts/${accountId}/invoice`, body, showLoader: true })

export const getInvoiceInitDownload = ({ token, body }) => doPost({ token, url: endpoint.user.invoiceInitiate, body, showLoader: false })

export const getInvoiceStatusDownload = ({ token, body }) =>
  doPost({ token, url: endpoint.user.invoiceDownloadStatus, body, showLoader: false })

export const getInvoiceToDownload = ({ token, body }) =>
  doPost({ token, url: endpoint.user.invoiceDownload, body, showLoader: false, arraybuffer: 'arraybuffer' })

export function* getInvoiceListSaga({ payload }) {
  const { businessId, accountId, body } = payload

  try {
    const { data: res } = yield call(getInvoiceList, { businessId, accountId, body })
    yield put(putInvoicesList(null))
    yield put(putInvoicesList(res))
  } catch (err) {
    yield put(putInvoicesList(null))
  }
}

function* changeLoader(isLoading) {
  yield put(putIsLoading(isLoading))
}

let timesFlag = 0

function* checkDownloadStatusSaga({ requestId, token, fileAvailable }) {
  if (!fileAvailable) {
    yield delay(3000)

    const body = {
      id: requestId,
    }

    const { data: statusResponse } = yield call(getInvoiceStatusDownload, { token, body })

    const { available } = statusResponse
    if (!available) {
      timesFlag += 1

      if (timesFlag >= 5) {
        toast.error('Download Timeout')
        yield* changeLoader(false)
        return false
      }

      return yield call(checkDownloadStatusSaga, { requestId, token, fileAvailable: available })
    }
    return true
  }
  return true
}

export function* getInvoiceInitiateDownloadSaga({ payload }) {
  const { token, body } = payload

  timesFlag = 0

  try {
    yield* changeLoader(true)
    const { data: res } = yield call(getInvoiceInitDownload, { token, body })
    const { isDownload = false } = body

    const requestId = get(res, 'requestId', get(res, 'id'))

    yield put(putInvoiceInitiateDownload(requestId))

    if (!requestId) {
      yield* changeLoader(false)
      return
    }

    // call status API
    const isAvailable = yield* checkDownloadStatusSaga({ requestId, token, fileAvailable: false })

    if (!isAvailable) {
      return
    }

    // call download API
    const resVal = yield call(getInvoiceToDownload, { token, body: { id: requestId } })
    // for download, we are getting response as res.data

    // for download, we are getting response as res.data
    // for base64, we are getting res.data.data
    const fileData = isDownload ? get(resVal, 'data', res) : get(resVal, 'data.data', res)

    if (!isDownload) {
      // Pass result to component
      yield* changeLoader(false)
      return
    }

    let { fileName } = body

    if (get(resVal, 'headers')) {
      fileName = decodeURI(resVal.headers['content-disposition'].split(';')[1].split('filename=')[1])
    }

    downloadFile(fileData, fileName, 'pdf')
    yield* changeLoader(false)
  } catch (err) {
    yield put(putInvoiceInitiateDownload(null))
    yield* changeLoader(false)
  }
}

export function* getInvoiceDownloadStatusSaga({ payload }) {
  const { token, body } = payload

  try {
    const { data: res } = yield call(getInvoiceStatusDownload, { token, body })
    yield put(putInvoiceDownloadStatus(res))
  } catch (err) {
    yield put(putInvoiceDownloadStatus(null))
  }
}

export function* getInvoiceDownloadSaga({ payload }) {
  if (!payload) return

  const { token, body } = payload
  try {
    const res = yield call(getInvoiceToDownload, { token, body })
    let url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }))
    // Create A label
    let link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    // Set the download file file name
    const fileName = decodeURI(res.headers['content-disposition'].split(';')[1].split('filename=')[1])
    link.setAttribute('download', fileName) //or any other extension
    document.body.appendChild(link)
    link.click()

    yield put(putInvoiceDownload(res))
  } catch (err) {
    yield put(putInvoiceDownload(null))
  }
}

export function* getInvoicesResetDownloadSaga() {
  yield put(putInvoicesReset())
}

export default function* Invoices() {
  yield all([
    takeEvery(actions.GET.INVOICES_LIST, getInvoiceListSaga),
    takeEvery(actions.GET.INVOICE_INITIATE_DOWNLOAD, getInvoiceInitiateDownloadSaga),
    takeEvery(actions.GET.INVOICE_DOWNLOAD_STATUS, getInvoiceDownloadStatusSaga),
    takeEvery(actions.GET.INVOICE_DOWNLOAD, getInvoiceDownloadSaga),
    takeEvery(actions.GET.INVOICE_RESET, getInvoicesResetDownloadSaga),
  ])
}
