import api from '../index'

const extendedApi = api.injectEndpoints({
  endpoints: builder => ({
    updateBillingAddress: builder.mutation({
      query: ({ businessId, accountId, body }) => ({
        url: `/api/v1/tenants/${businessId}/accounts/${accountId}/billing-address`,
        method: 'PUT',
        body,
      }),
    }),
    getBillingAddress: builder.query({
      query: ({ businessId, accountId }) => ({
        url: `/api/v1/tenants/${businessId}/accounts/${accountId}/billing-address`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useUpdateBillingAddressMutation, useLazyGetBillingAddressQuery } = extendedApi
