import { createTheme } from '@material-ui/core/styles'

const haulerHeroTheme = createTheme({
  typography: {
    fontFamily: `"Inter", sans-serif`,
  },
  palette: {
    main: '#0073E6',
  },
})

export default haulerHeroTheme
