import api from 'api'

const extendedApi = api.injectEndpoints({
  endpoints: build => ({
    getAddressSuggestList: build.query({
      query: ({ search }) => ({
        url: `/api/v1/map/suggest?search=${encodeURIComponent(search)}`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useLazyGetAddressSuggestListQuery } = extendedApi
