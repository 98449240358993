import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { formatCentsToDollars } from 'utils/price';

const HHDisplayMoney = ({ value = '', formatToDollars = true, ...rest }) => (
  <NumberFormat
    value={formatToDollars ? formatCentsToDollars(value) : value}
    displayType="text"
    thousandSeparator
    decimalSeparator="."
    decimalScale={2}
    fixedDecimalScale
    prefix="$"
    {...rest}
  />
);

HHDisplayMoney.propTypes = {
  value: PropTypes.string,
  formatToDollars: PropTypes.bool,
};

export default HHDisplayMoney;
