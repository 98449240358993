import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import upperCase from 'lodash/upperCase'

import { useForm, FormProvider } from 'react-hook-form'
import { Box, Button, Typography, useTheme } from '@mui/material'
import { indigo } from '@mui/material/colors'
import LoadingButton from '@mui/lab/LoadingButton'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { REGEX } from 'utils/validations'
import { useResetPasswordMutation } from 'api/account/resetPassword'

import T from 'T'
import HHFormTextField from 'components/form-fields/v5/HHFormTextField'
import RecoverPasswordSuccess from './RecoverPasswordSuccess'
import { PRIMARY_BUTTON_SX } from './settings'

const RecoverPassword = ({ isOpen = false, existingEmail = '', businessId, accountId, invoiceId, onClose }) => {
  const theme = useTheme()
  const [resetPassword, { isLoading: isLoadingRegisterUser }] = useResetPasswordMutation()
  const [isSuccess, setIsSuccess] = useState(false)

  const recoverPasswordForm = useForm({ defaultValues: { email: '' } })
  const {
    watch,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = recoverPasswordForm

  const watchEmail = watch('email')
  const emailHelperText = get(errors, 'email.message')

  const onSubmitHandler = data => {
    const payload = { email: data.email, businessId, ...(accountId && { accountId }), ...(invoiceId && { invoiceId }) }
    resetPassword(payload)
      .unwrap()
      .then(() => setIsSuccess(true))
      .catch(handleError)
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter' || event.key === 'NumpadEnter') {
      handleSubmit(onSubmitHandler)()
    }
  }

  useEffect(() => {
    if (isOpen) {
      setIsSuccess(false)
      reset({ email: existingEmail })
    }
  }, [isOpen])

  return (
    <Box position="relative">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        bgcolor="background.paper"
        position="absolute"
        height={275}
        zIndex={theme.zIndex.tooltip}
        py={2}
        px={3}
        sx={{ bottom: 0 }}
      >
        <FormProvider {...recoverPasswordForm}>
          {isSuccess && <RecoverPasswordSuccess email={watchEmail} onClose={onClose} />}
          {!isSuccess && (
            <>
              <Typography variant="h4">Recover password</Typography>
              <Typography my={2} variant="h5" fontWeight={400}>
                Get instructions sent to this email that explain how to reset your password
              </Typography>
              <HHFormTextField
                sx={{
                  my: 3,
                  '& fieldset': {
                    borderTop: 'none',
                    borderRight: 'none',
                    borderLeft: 'none',
                  },
                }}
                autoFocus
                error={emailHelperText}
                helperText={emailHelperText}
                control={control}
                rules={{ required: T.CANNOT_BE_EMPTY, pattern: { value: REGEX.IS_EMAIL, message: T.MUST_BE_A_VALID_EMAIL_ADDRESS } }}
                name="email"
                label={T.EMAIL}
                placeholder={T.EMAIL}
                fullWidth
                deprecatedLabel={false}
                onKeyDown={handleKeyDown}
              />
              <Box my={2} display="flex" justifyContent="flex-end">
                <Button sx={{ color: indigo[500], fontWeight: 500 }} variant="text" onClick={onClose}>
                  {upperCase(T.CANCEL)}
                </Button>
                <LoadingButton
                  sx={{ ml: 1, ...PRIMARY_BUTTON_SX }}
                  loading={isLoadingRegisterUser}
                  size="small"
                  variant="contained"
                  onClick={handleSubmit(onSubmitHandler)}
                >
                  {upperCase(T.RECOVER)}
                </LoadingButton>
              </Box>
            </>
          )}
        </FormProvider>
      </Box>
    </Box>
  )
}

RecoverPassword.propTypes = {
  isOpen: PropTypes.bool,
  existingEmail: PropTypes.object,
  businessId: PropTypes.string.isRequired,
  accountId: PropTypes.string,
  invoiceId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

export default RecoverPassword
