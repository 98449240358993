import React, { useEffect, useState, useRef, useCallback } from 'react'

import jwtDecode from 'jwt-decode'
import * as firebaseui from 'firebaseui'
import { GoogleAuthProvider } from 'firebase/auth'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Alert, Box, Button, Card, Divider, useTheme } from '@mui/material'
import { auth, handleFirebaseLogout } from 'providers/firebaseAuth'
import { useSearchParams } from 'react-router-dom-v5-compat'

import { get } from 'utils/lodash'
import { handleError } from 'utils/error'
import { setSelectedLoginDetails } from 'slices/login/LoginPersistSlice'
import { useLazyGetBusinessInfoQuery } from 'api/business/getBusinessInfo'
import { useLazyGetAccountStatusQuery } from 'api/account/getAccountStatus'
import { useRegisterUserMutation } from 'api/account/registerUser'
import { useUpdateGCPUserMutation } from 'api/account/updateGCPUser'
import { getFirstNameAndLastName } from 'utils/parseName'

import T from 'T'
import Constants from 'Constants'
import routes from 'router/routes'
import Loader from 'components/common/loader'
import BusinessLogo from 'components/common/BusinessLogo'
import { signInOptions } from './settings'
import { useMutationObserver } from './useMutationObserver'
import RecoverPassword from './RecoverPassword'
import 'firebaseui/dist/firebaseui.css'
import './style.scss'
import ContactDetails from './contact-details'

const { PORTAL_AUTH_TENANT_ID } = Constants

const Login = () => {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const [getBusinessInfo, { isFetching: isLoadingGetBusinessInfo, data: businessInfo, isSuccess: isGetBusinessInfoSuccess }] =
    useLazyGetBusinessInfoQuery()
  const [getAccountStatus, { data: isActiveAccount, isSuccess: isGetAccountStatusSuccess }] = useLazyGetAccountStatusQuery()
  const [registerUser, { isLoading: isLoadingRegisterUser }] = useRegisterUserMutation()
  const [updateGCPUser, { isLoading: isLoadingUpdateUser }] = useUpdateGCPUserMutation()
  const [showRecoverPasswordBtn, setShowRecoverPasswordBtn] = useState(false)
  const [recoverPasswordScreen, setRecoverPasswordScreen] = useState({ isOpen: false, email: '' })
  const [hasAccountIdError, setHasAccountIdError] = useState(false)
  const [hasAccountDeactivatedError, setHasAccountDeactivatedError] = useState(false)
  const urlBusinessName = searchParams.get('business_name') || searchParams.get('bid')
  const oldBusinessName = useSelector(state => get(state, 'LoginPersist.selectedLoginDetails.businessName', ''), shallowEqual)
  const currentBusinessName = urlBusinessName || oldBusinessName
  const businessId = get(businessInfo, 'id', '')
  const accountId = searchParams.get('account_id')
  const invoiceId = searchParams.get('invoice_id')
  const uiRef = useRef(null)
  const mutationRef = useRef()
  const canRegister = urlBusinessName && accountId

  const resetUI = () => {
    setShowRecoverPasswordBtn(false)
    setRecoverPasswordScreen({ isOpen: false, email: '' })
    uiRef.current.reset()
    initializeUi()
  }

  const goToHomePage = () => history.push(invoiceId ? routes.app.invoices : routes.app.home)

  const handleAccountDeactivatedError = (logout = false) => {
    setHasAccountDeactivatedError(true)
    if (logout) handleFirebaseLogout()
    resetUI()
  }

  const handleAccountIdError = (logout = false) => {
    setHasAccountIdError(true)
    if (logout) handleFirebaseLogout()
    resetUI()
  }

  const handleRegisterError = error => {
    handleError(error)
    handleFirebaseLogout()
    resetUI()
  }

  const handleRegisterUser = authResult => {
    const user = get(authResult, 'user', {})
    const displayName = get(user, 'displayName', '')
    const additionalUserInfo = get(authResult, 'additionalUserInfo', {})
    const providerId = get(additionalUserInfo, 'providerId', '')
    const { firstName, lastName } = getFirstNameAndLastName(
      displayName,
      providerId,
      get(additionalUserInfo, 'profile.given_name', ''),
      get(additionalUserInfo, 'profile.family_name', '')
    )
    const userIdpId = get(user, 'uid', '')
    const email = get(user, 'email', '')

    if (!canRegister) {
      const accessToken = get(authResult, 'user.accessToken', '')
      const tokenInfo = jwtDecode(accessToken)
      const tenants = get(tokenInfo, 'tenants', [])
      if (tenants.length === 0) {
        handleAccountIdError(true)
        return
      }

      if (providerId === GoogleAuthProvider.PROVIDER_ID) {
        updateGCPUser({ idpId: userIdpId, email, firstName, lastName }).unwrap().then(goToHomePage).catch(handleRegisterError)
        return
      }

      goToHomePage()
      return
    }

    const payload = { providerId, userIdpId, businessId, accountId, email, firstName, lastName }
    registerUser(payload)
      .unwrap()
      .then(() => {
        // Select newly created account
        dispatch(setSelectedLoginDetails({ accountId, businessName: urlBusinessName, businessId }))

        // After successful registration, refetch token to get new scope
        auth.currentUser
          .getIdToken(true)
          .then(goToHomePage)
          .catch(error => console.log('Token error --', error))
      })
      .catch(error => {
        const errorMessage = get(error, 'data.message', '').toLowerCase()
        if (errorMessage.includes(`User with email: ${email} is already registered for accountId: ${accountId}`.toLowerCase())) {
          dispatch(setSelectedLoginDetails({ accountId, businessName: urlBusinessName, businessId }))
          goToHomePage()
          return
        }

        if (errorMessage.includes(`no active record exists for accountid: ${accountId}, businessid: ${businessId}`.toLowerCase())) {
          handleAccountDeactivatedError(true)
          return
        }

        handleRegisterError(error)
      })
  }

  const initializeUi = () => {
    const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth)
    auth.tenantId = PORTAL_AUTH_TENANT_ID
    ui.disableAutoSignIn()
    ui.start('#auth-container', {
      signInFlow: 'popup',
      signInSuccessUrl: routes.app.home,
      callbacks: {
        signInSuccessWithAuthResult: authResult => {
          dispatch(
            setSelectedLoginDetails({
              accountId: '',
              businessName: '',
              businessId: '',
              show: !accountId,
              redirectToInvoicePage: !!invoiceId,
            })
          )
          handleRegisterUser(authResult)
          return false
        },
        signInFailure: error => console.log('Failure error --', error),
      },
      signInOptions,
      credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
    })
    uiRef.current = ui
  }

  useEffect(() => {
    initializeUi()
  }, [])

  useEffect(() => {
    if (currentBusinessName) {
      getBusinessInfo(currentBusinessName)
    }
  }, [currentBusinessName])

  useEffect(() => {
    if (canRegister && businessId) {
      getAccountStatus({ businessId, accountId })
    }
  }, [canRegister, businessId])

  useEffect(() => {
    if (isGetAccountStatusSuccess) {
      // update callback to handle account status
      resetUI()
      return
    }

    if (!canRegister && isGetBusinessInfoSuccess) {
      // update callback to handle new business id
      resetUI()
    }
  }, [canRegister, isGetBusinessInfoSuccess, isGetAccountStatusSuccess])

  const handleRecoverPassword = () => {
    const emailEl = document.getElementById('ui-sign-in-email-input')
    setShowRecoverPasswordBtn(false)
    setRecoverPasswordScreen({ isOpen: true, email: get(emailEl, 'value', '') })
  }

  const mutationCallback = useCallback(() => {
    const emailEl = get(document.getElementsByClassName('firebaseui-id-email'), [0])
    if (emailEl) {
      setHasAccountIdError(false)
      setHasAccountDeactivatedError(false)
    }

    const firstAndLastNameEl = get(document.getElementsByClassName('firebaseui-id-name'), [0])
    if (firstAndLastNameEl && !canRegister) {
      handleAccountIdError()
      return
    }

    if (firstAndLastNameEl && !isActiveAccount) {
      handleAccountDeactivatedError()
      return
    }

    const trobleSignInEl = get(document.getElementsByClassName('firebaseui-form-links'), [0])
    if (!trobleSignInEl) {
      setShowRecoverPasswordBtn(false)
      return
    }

    setShowRecoverPasswordBtn(true)
  }, [isActiveAccount])

  useMutationObserver(mutationRef, mutationCallback)

  return (
    <>
      {(isLoadingGetBusinessInfo || isLoadingRegisterUser || isLoadingUpdateUser) && <Loader />}
      <Box bgcolor="background.paper" display="flex" alignItems="center" justifyContent="center" flexDirection="column" height="100vh">
        <Card sx={{ m: 'auto', width: '352px', boxShadow: `0px 1px 8px 0px ${theme.palette.divider}` }}>
          <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" px={3}>
            <Box my={2}>{!isLoadingGetBusinessInfo && <BusinessLogo imageSX={{ height: 75 }} businessId={businessId} />}</Box>
            <Divider flexItem />
          </Box>
          <Box ref={mutationRef}>
            <Box id="auth-container" />

            {showRecoverPasswordBtn && (
              <Box position="relative">
                <Button
                  onClick={handleRecoverPassword}
                  sx={{ position: 'absolute', ml: 2, zIndex: theme.zIndex.tooltip, bottom: 24 }}
                  color="primary"
                  variant="text"
                >
                  Trouble signing in?
                </Button>
              </Box>
            )}

            {recoverPasswordScreen.isOpen && (
              <RecoverPassword
                isOpen={recoverPasswordScreen.isOpen}
                existingEmail={recoverPasswordScreen.email}
                businessId={businessId}
                accountId={accountId}
                invoiceId={invoiceId}
                onClose={resetUI}
              />
            )}
          </Box>
          {hasAccountIdError && (
            <Alert severity="error" sx={{ mx: 3, mb: 2, alignItems: 'center' }}>
              {T.ACCOUNT_NUMBER_DOESNT_EXIST}
            </Alert>
          )}
          {hasAccountDeactivatedError && (
            <Alert severity="error" sx={{ mx: 3, mb: 2, alignItems: 'center' }}>
              Cannot connect to a deactivated account.
              <br />
              Please contact your hauler for assistance.
            </Alert>
          )}
        </Card>
        <ContactDetails
          address={get(businessInfo, 'address', '')}
          email={get(businessInfo, 'businessEmail', '')}
          phone={get(businessInfo, 'phoneNumber', '')}
          website={get(businessInfo, 'website', '')}
        />
      </Box>
    </>
  )
}

export default Login
