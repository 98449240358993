/* eslint-disable */
import axios from 'axios'
import { toast } from 'react-toastify'

import { store } from 'providers/store'
import { login } from 'middleware/actions/login'

import Constants from './Constants'
import Util from './Util'
import LocalStorage from './LocalStorage'

export const SERVICE_END_POINT = (function () {
  // get end point details
  var scripts = document.getElementsByTagName('script')
  var ret = ''

  for (var i = 0; i < scripts.length; i++) {
    var s = scripts[i]
    var src = s.src

    var searchFor = '/static/js/main.js'
    if (Constants.DEV_MODE) {
      searchFor = '/static/js/bundle.js'
    }

    if (src && src.indexOf(searchFor) !== -1) {
      ret = src.substring(0, src.indexOf(searchFor))
      console.log('EP => ' + ret)
      break
    }
  }
  return ret
})()

const API = SERVICE_END_POINT + '/api'
const USER = API + '/user'
const USER_INFO = USER + '/info'

export default class Backend {
  static async getUserContext() {
    // check if user was already logged in
    var accessToken = LocalStorage.getAccessToken()

    // user just logged in
    if (!accessToken) {
      accessToken = Util.getFramentVar('access_token')

      if (accessToken) {
        // Todo: Set this from Component to avoid timeout
        setTimeout(() => store.dispatch(login(accessToken)))

        // store access token
        LocalStorage.setAccessToken(accessToken)

        // clear url framents
        window.location.hash = ''
      }
    }

    // not authenticated
    if (!accessToken) {
      Util.initiateAuthentication()

      return new Promise(function (resolve, reject) {
        setTimeout(function () {
          reject(null)
        }, 0)
      })
    } else {
      return new Promise(function (resolve, reject) {
        setTimeout(() => resolve(true))
      })
    }
  }

  // Below part note in use
  static async getLoggedInUserInfo(accessToken) {
    try {
      const response = this.doPost(USER_INFO, {}, {}, accessToken)
      return response
    } catch (error) {
      toast(error)
      console.log(error)
    }
  }

  static async doPost(url, reqHeaders = {}, data = {}, accessToken) {
    if (accessToken) {
      reqHeaders['Authorization'] = 'Bearer ' + accessToken
    }

    return await axios({ method: 'POST', url: url, data: data, headers: reqHeaders }).catch(errorInfo => {
      console.log('*** There was a server error!')
      console.log(errorInfo)
      console.log('***')
      toast(errorInfo)

      if (errorInfo.response.status === 401) {
        //session may have timeout, redo authentication
        LocalStorage.removeAccessToken()
        Util.initiateAuthentication()
      }
    })
  }
}
